
<!--/ News single /-->
<section class="intro-single section-t8 section-b4 background-mural-01">
    <div class="container">
        <div *ngIf="!loaded">
            <div align="center">Loading...</div>
        </div>
        <div class="row" *ngIf="loaded">
            <div class="col-md-6 offset-md-3">
                <div class="card-box-ico pb-4">
                    <h2 class="mb-4">We're sorry to see you go! Are you sure you want to cancel your account?</h2>
                    <hr>
                    <h5 class="mb-4">Please choose the subscriptions you would like to cancel</h5>

                    <div class="row">

                        <div class="col-md-12 mb-3" *ngFor="let subscription of customersubscriptions; let i = index">
                            <div class="form-group">
                                <input type="checkbox"(change)="changed($event)" [value]="subscription.id">
                                <label style="margin-left: 15px"> <b> {{subscription.product_name}}</b></label>
                            </div>
                        </div>

                    </div>

                    <h5 class="mb-4">Please enter your login-details</h5>
                    <form class="form-a" action="" method="post" role="form">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <div class="form-group">
                                    <label for="inputEmail"><b>Email *</b></label>
                                    <input name="email" type="email" [(ngModel)]="username"  class="form-control form-control-lg form-control-a" placeholder="Enter your email" data-rule="email" data-msg="Please enter your email">
                                    <div class="validation"></div>
                                </div>
                            </div>

                            <div class="col-md-12 mb-3">
                                <div class="form-group">
                                    <label for="inputPassword"><b>Password *</b></label>
                                    <input type="password" name="password" [(ngModel)]="password"  class="form-control form-control-lg form-control-a"
                                           data-rule="minlen:8" placeholder="Enter your password" data-msg="Please enter your current password">
                                    <div class="validation"></div>
                                </div>
                            </div>
                        </div>

                        <div style="float: right">
                            <a href="https://customer.sparav.com/forgot-password" class="pull-right">Forgot password</a>
                        </div>
                        <br><br>


                        <h5>If you cancel your account, you will lose</h5>
                        <ul>
                            <li>Real-time antivirus protection</li>
                            <li>Protection against harmful threats and viruses</li>
                            <li>Protection on mobile devices</li>
                        </ul>
                        <hr>
                        <a href="/" class="version-b mt-5">Keep me protected <i class="fa fa-angle-right"></i></a>
                        <a *ngIf="!cancelLoading" href="javascript:void(0)" (click)="cancel()" class="float-right mt-1">Confirm and cancel</a>
                        <span *ngIf="cancelLoading"  class="float-right mt-1">Please wait...</span>
                        <hr>
                        <a href="/" class="small">Leave cancellation</a>

                    </form>

                </div>
            </div>
        </div>
    </div>
</section>


<app-footer *ngIf="loaded"></app-footer>
