import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { DatePipe } from '@angular/common';

export class OrderModel {

    // tslint:disable:variable-name
    private order_id: string;
    private cc_number: string;
    private cc_expires: string;
    private date: string;
    private recurring_date: string;
    private total_price: string;
    private product_id: string;
    private product_name: string;
    private vat: string;
    private vat_percent: string;
    private order_status: string;
    private active: string;

    // tslint:disable-next-line: max-line-length
    constructor($order_id: string, $cc_number: string, $cc_expires: string, $date: string, $recurring_date: string, $total_price: string, $product_id: string, $product_name: string, $vat: string, $vat_percent: string, $order_status: string, $active: string) {
        this.order_id = $order_id;
        this.cc_number = $cc_number;
        this.cc_expires = $cc_expires;
        this.date = $date;
        this.recurring_date = $recurring_date;
        this.total_price = $total_price;
        this.product_id = $product_id;
        this.product_name = $product_name;
        this.vat = $vat;
        this.vat_percent = $vat_percent;
        this.order_status = $order_status;
        this.active = $active;
    }

    /**
     * Getter $order_id
     * @return {string}
     */
    public get $order_id(): string {
        return this.order_id;
    }

    /**
     * Getter $cc_number
     * @return {string}
     */
    public get $cc_number(): string {
        return this.cc_number;
    }

    /**
     * Getter $cc_expires
     * @return {string}
     */
    public get $cc_expires(): string {
        return this.cc_expires;
    }

    /**
     * Getter $date
     * @return {string}
     */
    public get $date(): string {
        return this.date;
    }

    /**
     * Getter $recurring_date
     * @return {string}
     */
    public get $recurring_date(): string {
        return this.recurring_date;
    }

    /**
     * Getter $total_price
     * @return {string}
     */
    public get $total_price(): string {
        return this.total_price;
    }

    /**
     * Getter $product_id
     * @return {string}
     */
    public get $product_id(): string {
        return this.product_id;
    }

    /**
     * Getter $product_name
     * @return {string}
     */
    public get $product_name(): string {
        return this.product_name;
    }

    /**
     * Getter $vat
     * @return {string}
     */
    public get $vat(): string {
        return this.vat;
    }

    /**
     * Getter $vat
     * @return {string}
     */
    public get $vat_percent(): string {
        return this.vat_percent;
    }

    /**
     * Getter $order_status
     * @return {string}
     */
    public get $order_status(): string {
        return this.order_status;
    }

    /**
     * Getter $order_status
     * @return {string}
     */
    public get $active(): string {
        return this.active;
    }
}

// Converts: api json to model || stringify json from storage to model
@Injectable({
    providedIn: 'root'
})
export class OrderAdapter implements Adapter<OrderModel> {
    // nextPayment: Date = new Date(new Date().setFullYear(new Date().getFullYear() + 2));
    nextPayment: Date = null;

    constructor(private datePipe: DatePipe) { }
    adapt(response: any): OrderModel {

        // add space between 4 numbers
        let cc_number = '';
        if (!response.cc_number.includes(' ')) {
            if (response.cc_number) {
                cc_number = response.cc_number.replace(/(.{4})/g, '$& ');
            } else {
                cc_number = response.$cc_number.replace(/(.{4})/g, '$& ');
            }
        } else {
            cc_number = response.cc_number;
        }

        // add / between mm/yy
        let cc_expires = '';
        if (!response.cc_expires.includes('/')) {
            if (response.cc_expires) {
                cc_expires = [response.cc_expires.slice(0, 2), '/', response.cc_expires.slice(2)].join('');
            } else {
                cc_expires = [response.$cc_expires.slice(0, 2), '/', response.$cc_expires.slice(2)].join('');
            }
        } else {
            cc_expires = response.cc_expires;
        }

        // todo: use subs api to compare only active subs
        // if (response.recurring_date || response.$recurring_date) {
        //     // set nextPayment first time
        //     if (this.nextPayment === null) {
        //         if (response.recurring_date) { this.nextPayment = response.recurring_date; }
        //         else { this.nextPayment = response.$recurring_date; }
        //     }
        //     // if less than current nextPayment
        //     // console.log((new Date(response.recurring_date).getTime() < new Date(this.nextPayment).getTime()));
        //     if ((new Date(response.recurring_date).getTime() < new Date(this.nextPayment).getTime())) {
        //         if (response.recurring_date) { this.nextPayment = response.recurring_date; }
        //         else { this.nextPayment = response.$recurring_date; }
        //     }
        // }

        return new OrderModel(
            response.order_id.toString() || response.$order_id.toString(),
            // response.cc_number || response.$cc_number,
            cc_number,
            // response.cc_expires || response.$cc_expires,
            cc_expires,
            response.$date || response.date,
            response.recurring_date || response.$recurring_date,
            response.total_price || response.$total_price,
            response.product_id || response.$product_id,
            response.product_name || response.$product_name,
            response.vat || response.$vat,
            response.vat_percent || response.$vat_percent,
            response.order_status || response.$order_status,
            response.active || response.$active
        );
    }
}
