<div class="row">

    <!-- devices -->
    <div class="col-md-5">
        <div class="card-box-ico pb-2">
            <div class="d-flex justify-content-between">
                <div class="title-box">
                    <h2 class="title-a">Devices</h2>
                </div>
                <div class="title-link">
                    <a routerLink="/devices">More
                        <i class="fa fa-angle-right"></i>
                    </a>
                </div>
            </div>
            <hr>

            <!-- On active plan -->
            <ng-container *ngIf="isActivePlan">
                <div class="d-flex bd-highlight">
                    <div class="p-2 bd-highlight">
                        <img src="assets/img/svg/icon-windows.svg" width="50px">
                    </div>
                    <div class="p-2 flex-grow-1 bd-highlight">
                        <b>Windows PC</b>
                        <br>
                        <span class="small color-c">SPARAV version V1.1.0</span>
                    </div>
                    <div class="p-2 bd-highlight my-auto">
                        <a href="" class="small color-f" data-toggle="tooltip" data-placement="top"
                            title="Remove device">
                            <i class="fa fa-close"></i>
                            Remove
                        </a>
                    </div>
                </div>
                <hr>
                <div class="d-flex bd-highlight">
                    <div class="p-2 bd-highlight">
                        <img src="assets/img/svg/icon-mac.svg" width="50px">
                    </div>
                    <div class="p-2 flex-grow-1 bd-highlight">
                        <b>Apple / Mac OSX</b>
                        <br>
                        <span class="small color-c">SPARAV version V1.1.0</span>
                    </div>
                    <div class="p-2 bd-highlight my-auto">
                        <a href="" class="small color-f" data-toggle="tooltip" data-placement="top"
                            title="Remove device"><i class="fa fa-close"></i>
                            Remove
                        </a>
                    </div>
                </div>
                <hr>
                <div class="d-flex bd-highlight">
                    <div class="p-2 bd-highlight">
                        <img src="assets/img/svg/icon-android.svg" width="50px">
                    </div>
                    <div class="p-2 flex-grow-1 bd-highlight">
                        <b>Android</b>
                        <br>
                        <span class="small color-c">SPARAV version V1.1.0</span>
                    </div>
                    <div class="p-2 bd-highlight my-auto">
                        <a href="" class="small color-f" data-toggle="tooltip" data-placement="top"
                            title="Remove device">
                            <i class="fa fa-close"></i>
                            Remove
                        </a>
                    </div>
                </div>
                <hr>
                <div class="d-flex bd-highlight">
                    <div class="p-2 bd-highlight">
                        <img src="assets/img/svg/icon-ios.svg" width="50px">
                    </div>
                    <div class="p-2 flex-grow-1 bd-highlight">
                        <b>iOS</b>
                        <br>
                        <span class="small color-c">SPARAV version V1.1.0</span>
                    </div>
                    <div class="p-2 bd-highlight my-auto">
                        <a href="" class="small color-f" data-toggle="tooltip" data-placement="top"
                            title="Remove device">
                            <i class="fa fa-close"></i>
                            Remove
                        </a>
                    </div>
                </div>
                <hr>

                <ng-container *ngFor="let device of devices">
                    <div class="d-flex bd-highlight">
                        <div class="p-2 bd-highlight">
                            <img src="assets/img/svg/icon-android.svg" width="50px">
                        </div>
                        <div class="p-2 flex-grow-1 bd-highlight">
                            <b>{{ device.name }}</b>
                            <br>
                            <span class="small color-c">SPARAV version V1.1.0</span>
                        </div>
                        <div class="p-2 bd-highlight my-auto">
                            <a (click)="removeDevice(device)" class="small color-f btn-a" data-toggle="tooltip"
                                data-placement="top" title="Remove device">
                                <i class="fa fa-close"></i>
                                Remove
                            </a>
                        </div>
                    </div>
                    <hr>
                </ng-container>
            </ng-container>

            <!-- On inactive plan -->
            <ng-container *ngIf="!isActivePlan">
                <img src="assets/img/svg/add-device.svg" width="" class="img-fluid">
                <hr>
                <p class="text-center">You do not have protection on any devices.</p>
                <hr>
            </ng-container>

            <div class="text-center mt-5">

                <ng-container *ngIf="isActivePlan">
                    <a class=" btn-a version-b" (click)="openAddDevice(content)">
                        Add another device
                        <i class="fa fa-angle-right"></i>
                    </a>
                    <p class="small mt-4"><b>{{ devices.length + 4 }}/10</b> licenses used</p>
                </ng-container>

                <ng-container *ngIf="!isActivePlan">
                    <a routerLink="/billing" class="version-b">
                        Activate My Protection Plan
                        <i class="fa fa-angle-right"></i>
                    </a>
                    <p class="small mt-4"><b></b></p>
                </ng-container>

            </div>
        </div>
    </div>

    <!-- news -->
    <div class="col-md-4">
        <div class="card-box-ico pb-2">
            <div class="d-flex justify-content-between">
                <div class="title-box">
                    <h2 class="title-a">News</h2>
                </div>
                <div class="title-link">
                    <a routerLink="/news">More
                        <i class="fa fa-angle-right"></i>
                    </a>
                </div>
            </div>
            <hr>
            <div class="d-flex bd-highlight">
                <div class="p-2 bd-highlight">
                    <img src="https://via.placeholder.com/150/e7eaeb/ffffff" width="80px">
                </div>
                <div class="p-2 flex-grow-1 bd-highlight">
                    <h5><a routerLink="/news/article-one">
                            <b>
                                More than two thirds of all Android antivirus apps are ineffective and many don't even
                                do anything at all
                            </b>
                        </a>
                    </h5>
                    <p class="small color-f">&#8212; 17-03-2020</p>
                    <p class="small">
                        Facepalm: In a survey of 250 Android so-called antivirus apps, only 80 were found to block more
                        than 30% of malware samples. Some of the apps tested didn't even have any antivirus
                        functionality at all.
                    </p>
                </div>
            </div>
            <hr>
            <div class="d-flex bd-highlight">
                <div class="p-2 bd-highlight">
                    <img src="https://via.placeholder.com/150/e7eaeb/ffffff" width="80px">
                </div>
                <div class="p-2 flex-grow-1 bd-highlight">
                    <h5><a routerLink="/news/article-two">
                            <b>
                                A fourth of all PCs are unprotected from virus and malware threats
                            </b>
                        </a>
                    </h5>
                    <p class="small color-f">&#8212; 10-04-2019</p>
                    <p class="small">
                        Nearly a quarter of all PCs in the world are unprotected from virus, malware and other malicious
                        threats according to Microsoft’s latest Security Intelligence Report. In it, Redmond said
                        computers without any protection are 5.5 times more likely to become infected than systems
                        running some sort of up to date anti-virus software.
                    </p>
                </div>
            </div>
            <hr>
            <div class="d-flex bd-highlight">
                <div class="p-2 bd-highlight">
                    <img src="https://via.placeholder.com/150/e7eaeb/ffffff" width="80px">
                </div>
                <div class="p-2 flex-grow-1 bd-highlight">
                    <h5><a routerLink="/news/article-three"><b>Is Antivirus Software Necessary in 2020?</b></a>
                    </h5>
                    <p class="small color-f">&#8212; 13-01-2020</p>
                    <p class="small">
                        Windows, Android, iOS, and Mac operating systems all have decent security protections, so is an
                        antivirus still necessary in 2020?
                        Considering the fact that users on every operating system get hacked every day, the answer is a
                        resounding YES!
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- facts -->
    <div class="col-md-3">
        <div class="card-box-ico pb-2">
            <h5>Facts and statistics</h5>
            <hr>
            <p class="small card-box-small-color">Over <b>70%</b> of US Americans are worried about having their
                personal data stolen from their computers.</p>
            <hr>
            <p class="small card-box-small-color">January 2019 saw the release of over <b>1 billion</b> email adressess
                and passwords from hacked records.</p>
            <hr>
            <p class="small card-box-small-color">Global cost of malware (virus infection) will cost the global economy
                above <b>$6 trillion</b> USD by 2021.</p>
            <hr>
            <p class="small card-box-small-color">Hackers are increasingly <b>targeting smartphones</b> with mobile
                malware and malicious apps.</p>
            <hr>
            <p class="small card-box-small-color">Only <b>25%</b> of consumers feel safe that their data is not
                vulnerable to hacks, leaks, and security breaches.</p>
            <hr>
            <p class="small card-box-small-color">Forget reasons such as petty revenge, industrial espionage or simple
                activism/vandalism – most cybercriminals only <b>want your money</b>.</p>
        </div>
    </div>

</div>


<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Add device</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label><b>Device name *</b></label>
        <form class="form-a" action="" method="post" role="form" [formGroup]="deviceForm">

            <input name="device" type="text" class="form-control form-control-lg form-control-a"
                placeholder="Samsung Galaxy S8" data-msg="Please enter your device name" formControlName='device'>
            <div class="validation"></div>

        </form>
    </div>

    <a (click)="addDevice()" class="btn btn-green mt-3 mb-sm-0" style="margin: auto;">
        Add device
        <!-- <i class="fa fa-angle-right"></i> -->
    </a>
    <div class="modal-footer">
        <!-- <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button> -->
    </div>
</ng-template>