import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  public emailError;
  public loading;

  public emailForm = new FormGroup({
    email: new FormControl(null),
  });

  constructor(private router: Router, private loginService: LoginService) { }

  ngOnInit(): void {
    // scroll to top
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) { return; }
      window.scrollTo(0, 0);
    });


  }

  onForgotPassword(): void {
    this.loading = true;
    const email = this.emailForm.value.email;

    if (this.loginService.inputfieldIsEmpty(email)) {
      this.emailError = true;
      this.loading = false;
      return;
    }

    this.loginService.sendPassword(email).then(res => {
      switch (res.response_code) {
        case 200:
          console.log('email sent');
          alert('Reset password link has been sent to your email.');

          // todo: navigate to email sent page
          this.router.navigate(['']);
          break;
        case 400:
          alert('Email does not exist.');
          this.emailError = true;
          this.loading = false;
          break;
        default:
          console.error('Error, did not send email');
          alert('Error occured. Contact customer service.');
          this.router.navigate(['']);
          break;
      }
    });
  }

}
