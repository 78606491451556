<app-header></app-header>

<section class="intro-single section-t8 section-b4 background-mural-01">
    <!-- container class not full hd on angular -->
    <div class="container-fluid">

        <!-- welcome -->
        <div class="row">
            <div class="col-md-8">
                <p class="mt-1">
                    <b>Welcome {{ firstname || 'back' }}.</b>
                    Your Dashboard gives you a quick overview of your recent
                    account movements, important facts, statistics and latest news and updates on personal security,
                    antivirus software and malware.
                </p>
            </div>
            <div class="col-md-4">
                <div class="text-md-right text-left">
                    <div class="card-box-ico">

                        <ng-container *ngIf="isActivePlan">
                            <div class="d-inline-flex float-left mr-3 color-green">
                                <b>
                                    <i class="fa fa-shield"></i>
                                    Status:
                                </b>
                            </div>
                            <!-- <span class="color-green">You are protected on 4 device(s)</span> -->
                            <span class="color-green">You are protected</span>
                        </ng-container>

                        <ng-container *ngIf="!isActivePlan">
                            <div class="d-inline-flex float-left mr-3 color-red">
                                <b>
                                    <i class="fa fa-shield"></i>
                                    Status:
                                </b>
                            </div>
                            <span class="color-red">You are not protected</span>
                        </ng-container>

                    </div>
                </div>
            </div>
        </div>

        <!-- content -->
        <app-dashboard-content [isActivePlan]="isActivePlan" ></app-dashboard-content>

    </div>
</section>

<app-videos></app-videos>

<app-footer></app-footer>