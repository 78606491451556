<app-header></app-header>

<!--/ Billing /-->
<section class="intro-single section-t8 section-b4 background-mural-01">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-between pb-4">
                    <div class="title-box">
                        <h2 class="title-a">Billing</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <p class="mt-1">
                    Your billing holds your personal payment information and gives you an overview of all invoices. Your
                    payment information is only visible from your account and otherwise encrypted by our system.
                </p>
            </div>
            <div class="col-md-4">
                <div class="text-md-right text-left">
                    <div class="card-box-ico">
                        <div class="d-inline-flex float-left mr-3"><b>Next billing date:</b></div>
                        {{ nextBilling || 'N/A' }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> Antivirus Protection Plan</h5>
                    <br>
                    <img src="assets/img/svg/icon-protection-plan-active.svg" width="" height="" class="img-fluid">
                    <br><br>

                    <ng-container *ngIf="isActivePlan">
                        <p class="color-green"><i class="fa fa-check-circle"></i> Your plan is currently active.</p>
                    </ng-container>

                    <ng-container *ngIf="!isActivePlan">
                        <p class="color-red"><i class="fa fa-minus-circle"></i> Your plan is currently not active.</p>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-8">
                <div class="card-box-ico">
                    <div class="text-md-right text-left">
                        <div class="d-inline-flex float-left mr-3">
                            <h5>Payment information</h5>
                        </div>
                        <div class="cards-logos">
                            <span class="mr-3">(VISA / Mastercard / AMEX)</span>
                            <img src="assets/img/svg/visa.svg" width="40px" class="">
                            <img src="assets/img/svg/mastercard.svg" width="40px" class="">
                            <img src="assets/img/svg/american-express.svg" width="40px" class="">
                        </div>
                    </div>
                    <hr>
                    <br>
                    <form class="form-a" action="" method="post" role="form" [formGroup]="paymentForm">
                        <div class="row">
                            <div class="col-md-5 mb-3">
                                <div class="form-group">
                                    <label><b>Address</b></label>
                                    <input type="text" class="form-control form-control-lg form-control-a"
                                        name="billingAddress1" value="" id="exampleInputEmail1" aria-describedby=""
                                        placeholder="Enter your full address" formControlName="address"
                                        [ngClass]="{'errorBorder': addressError}" (focus)="addressError = false;">
                                </div>
                            </div>
                            <div class="col-md-3 mb-3">
                                <div class="form-group">
                                    <label><b>Zip code</b></label>
                                    <input type="text" class="form-control form-control-lg form-control-a"
                                        name="postcode" value="" id="exampleInputPassword1"
                                        placeholder="Enter your zip code" formControlName="zipcode"
                                        [ngClass]="{'errorBorder': zipcodeError}" (focus)="zipcodeError = false;">
                                </div>
                            </div>
                            <div class="col-md-4 mb-3">
                                <div class="form-group">
                                    <label><b>Country</b></label>
                                    <select id="country" name="billingCountry"
                                        class="form-control form-control-lg form-control-a" formControlName="country">
                                        <option value="AF">Afghanistan</option>
                                        <option value="AX">Aland Islands</option>
                                        <option value="AL">Albania</option>
                                        <option value="DZ">Algeria</option>
                                        <option value="AS">American Samoa</option>
                                        <option value="AD">Andorra</option>
                                        <option value="AO">Angola</option>
                                        <option value="AI">Anguilla</option>
                                        <option value="AQ">Antarctica</option>
                                        <option value="AG">Antigua And Barbuda</option>
                                        <option value="AR">Argentina</option>
                                        <option value="AM">Armenia</option>
                                        <option value="AW">Aruba</option>
                                        <option value="AU">Australia</option>
                                        <option value="AT">Austria</option>
                                        <option value="AZ">Azerbaijan</option>
                                        <option value="BS">Bahamas</option>
                                        <option value="BH">Bahrain</option>
                                        <option value="BD">Bangladesh</option>
                                        <option value="BB">Barbados</option>
                                        <option value="BY">Belarus</option>
                                        <option value="BE">Belgium</option>
                                        <option value="BZ">Belize</option>
                                        <option value="BJ">Benin</option>
                                        <option value="BM">Bermuda</option>
                                        <option value="BT">Bhutan</option>
                                        <option value="BO">Bolivia</option>
                                        <option value="BA">Bosnia And Herzegovina</option>
                                        <option value="BW">Botswana</option>
                                        <option value="BV">Bouvet Island</option>
                                        <option value="BR">Brazil</option>
                                        <option value="IO">British Indian Ocean Territory</option>
                                        <option value="BN">Brunei Darussalam</option>
                                        <option value="BG">Bulgaria</option>
                                        <option value="BF">Burkina Faso</option>
                                        <option value="BI">Burundi</option>
                                        <option value="KH">Cambodia</option>
                                        <option value="CM">Cameroon</option>
                                        <option value="CA">Canada</option>
                                        <option value="CV">Cape Verde</option>
                                        <option value="KY">Cayman Islands</option>
                                        <option value="CF">Central African Republic</option>
                                        <option value="TD">Chad</option>
                                        <option value="CL">Chile</option>
                                        <option value="CN">China</option>
                                        <option value="CX">Christmas Island</option>
                                        <option value="CC">Cocos (Keeling) Islands</option>
                                        <option value="CO">Colombia</option>
                                        <option value="KM">Comoros</option>
                                        <option value="CG">Congo</option>
                                        <option value="CD">Congo, Democratic Republic</option>
                                        <option value="CK">Cook Islands</option>
                                        <option value="CR">Costa Rica</option>
                                        <option value="CI">Cote D'Ivoire</option>
                                        <option value="HR">Croatia</option>
                                        <option value="CU">Cuba</option>
                                        <option value="CY">Cyprus</option>
                                        <option value="CZ">Czech Republic</option>
                                        <option value="DK" selected="">Denmark</option>
                                        <option value="DJ">Djibouti</option>
                                        <option value="DM">Dominica</option>
                                        <option value="DO">Dominican Republic</option>
                                        <option value="EC">Ecuador</option>
                                        <option value="EG">Egypt</option>
                                        <option value="SV">El Salvador</option>
                                        <option value="GQ">Equatorial Guinea</option>
                                        <option value="ER">Eritrea</option>
                                        <option value="EE">Estonia</option>
                                        <option value="ET">Ethiopia</option>
                                        <option value="FK">Falkland Islands (Malvinas)</option>
                                        <option value="FO">Faroe Islands</option>
                                        <option value="FJ">Fiji</option>
                                        <option value="FI">Finland</option>
                                        <option value="FR">France</option>
                                        <option value="GF">French Guiana</option>
                                        <option value="PF">French Polynesia</option>
                                        <option value="TF">French Southern Territories</option>
                                        <option value="GA">Gabon</option>
                                        <option value="GM">Gambia</option>
                                        <option value="GE">Georgia</option>
                                        <option value="DE">Germany</option>
                                        <option value="GH">Ghana</option>
                                        <option value="GI">Gibraltar</option>
                                        <option value="GR">Greece</option>
                                        <option value="GL">Greenland</option>
                                        <option value="GD">Grenada</option>
                                        <option value="GP">Guadeloupe</option>
                                        <option value="GU">Guam</option>
                                        <option value="GT">Guatemala</option>
                                        <option value="GG">Guernsey</option>
                                        <option value="GN">Guinea</option>
                                        <option value="GW">Guinea-Bissau</option>
                                        <option value="GY">Guyana</option>
                                        <option value="HT">Haiti</option>
                                        <option value="HM">Heard Island &amp; Mcdonald Islands</option>
                                        <option value="VA">Holy See (Vatican City State)</option>
                                        <option value="HN">Honduras</option>
                                        <option value="HK">Hong Kong</option>
                                        <option value="HU">Hungary</option>
                                        <option value="IS">Iceland</option>
                                        <option value="IN">India</option>
                                        <option value="ID">Indonesia</option>
                                        <option value="IR">Iran, Islamic Republic Of</option>
                                        <option value="IQ">Iraq</option>
                                        <option value="IE">Ireland</option>
                                        <option value="IM">Isle Of Man</option>
                                        <option value="IL">Israel</option>
                                        <option value="IT">Italy</option>
                                        <option value="JM">Jamaica</option>
                                        <option value="JP">Japan</option>
                                        <option value="JE">Jersey</option>
                                        <option value="JO">Jordan</option>
                                        <option value="KZ">Kazakhstan</option>
                                        <option value="KE">Kenya</option>
                                        <option value="KI">Kiribati</option>
                                        <option value="KR">Korea</option>
                                        <option value="KW">Kuwait</option>
                                        <option value="KG">Kyrgyzstan</option>
                                        <option value="LA">Lao People's Democratic Republic</option>
                                        <option value="LV">Latvia</option>
                                        <option value="LB">Lebanon</option>
                                        <option value="LS">Lesotho</option>
                                        <option value="LR">Liberia</option>
                                        <option value="LY">Libyan Arab Jamahiriya</option>
                                        <option value="LI">Liechtenstein</option>
                                        <option value="LT">Lithuania</option>
                                        <option value="LU">Luxembourg</option>
                                        <option value="MO">Macao</option>
                                        <option value="MK">Macedonia</option>
                                        <option value="MG">Madagascar</option>
                                        <option value="MW">Malawi</option>
                                        <option value="MY">Malaysia</option>
                                        <option value="MV">Maldives</option>
                                        <option value="ML">Mali</option>
                                        <option value="MT">Malta</option>
                                        <option value="MH">Marshall Islands</option>
                                        <option value="MQ">Martinique</option>
                                        <option value="MR">Mauritania</option>
                                        <option value="MU">Mauritius</option>
                                        <option value="YT">Mayotte</option>
                                        <option value="MX">Mexico</option>
                                        <option value="FM">Micronesia, Federated States Of</option>
                                        <option value="MD">Moldova</option>
                                        <option value="MC">Monaco</option>
                                        <option value="MN">Mongolia</option>
                                        <option value="ME">Montenegro</option>
                                        <option value="MS">Montserrat</option>
                                        <option value="MA">Morocco</option>
                                        <option value="MZ">Mozambique</option>
                                        <option value="MM">Myanmar</option>
                                        <option value="NA">Namibia</option>
                                        <option value="NR">Nauru</option>
                                        <option value="NP">Nepal</option>
                                        <option value="NL">Netherlands</option>
                                        <option value="AN">Netherlands Antilles</option>
                                        <option value="NC">New Caledonia</option>
                                        <option value="NZ">New Zealand</option>
                                        <option value="NI">Nicaragua</option>
                                        <option value="NE">Niger</option>
                                        <option value="NG">Nigeria</option>
                                        <option value="NU">Niue</option>
                                        <option value="NF">Norfolk Island</option>
                                        <option value="MP">Northern Mariana Islands</option>
                                        <option value="NO" selected>Norway</option>
                                        <option value="OM">Oman</option>
                                        <option value="PK">Pakistan</option>
                                        <option value="PW">Palau</option>
                                        <option value="PS">Palestinian Territory, Occupied</option>
                                        <option value="PA">Panama</option>
                                        <option value="PG">Papua New Guinea</option>
                                        <option value="PY">Paraguay</option>
                                        <option value="PE">Peru</option>
                                        <option value="PH">Philippines</option>
                                        <option value="PN">Pitcairn</option>
                                        <option value="PL">Poland</option>
                                        <option value="PT">Portugal</option>
                                        <option value="PR">Puerto Rico</option>
                                        <option value="QA">Qatar</option>
                                        <option value="RE">Reunion</option>
                                        <option value="RO">Romania</option>
                                        <option value="RU">Russian Federation</option>
                                        <option value="RW">Rwanda</option>
                                        <option value="BL">Saint Barthelemy</option>
                                        <option value="SH">Saint Helena</option>
                                        <option value="KN">Saint Kitts And Nevis</option>
                                        <option value="LC">Saint Lucia</option>
                                        <option value="MF">Saint Martin</option>
                                        <option value="PM">Saint Pierre And Miquelon</option>
                                        <option value="VC">Saint Vincent And Grenadines</option>
                                        <option value="WS">Samoa</option>
                                        <option value="SM">San Marino</option>
                                        <option value="ST">Sao Tome And Principe</option>
                                        <option value="SA">Saudi Arabia</option>
                                        <option value="SN">Senegal</option>
                                        <option value="RS">Serbia</option>
                                        <option value="SC">Seychelles</option>
                                        <option value="SL">Sierra Leone</option>
                                        <option value="SG">Singapore</option>
                                        <option value="SK">Slovakia</option>
                                        <option value="SI">Slovenia</option>
                                        <option value="SB">Solomon Islands</option>
                                        <option value="SO">Somalia</option>
                                        <option value="ZA">South Africa</option>
                                        <option value="GS">South Georgia And Sandwich Isl.</option>
                                        <option value="ES">Spain</option>
                                        <option value="LK">Sri Lanka</option>
                                        <option value="SD">Sudan</option>
                                        <option value="SR">Suriname</option>
                                        <option value="SJ">Svalbard And Jan Mayen</option>
                                        <option value="SZ">Swaziland</option>
                                        <option value="SE">Sweden</option>
                                        <option value="CH">Switzerland</option>
                                        <option value="SY">Syrian Arab Republic</option>
                                        <option value="TW">Taiwan</option>
                                        <option value="TJ">Tajikistan</option>
                                        <option value="TZ">Tanzania</option>
                                        <option value="TH">Thailand</option>
                                        <option value="TL">Timor-Leste</option>
                                        <option value="TG">Togo</option>
                                        <option value="TK">Tokelau</option>
                                        <option value="TO">Tonga</option>
                                        <option value="TT">Trinidad And Tobago</option>
                                        <option value="TN">Tunisia</option>
                                        <option value="TR">Turkey</option>
                                        <option value="TM">Turkmenistan</option>
                                        <option value="TC">Turks And Caicos Islands</option>
                                        <option value="TV">Tuvalu</option>
                                        <option value="UG">Uganda</option>
                                        <option value="UA">Ukraine</option>
                                        <option value="AE">United Arab Emirates</option>
                                        <option value="GB">United Kingdom</option>
                                        <option value="US">United States</option>
                                        <option value="UM">United States Outlying Islands</option>
                                        <option value="UY">Uruguay</option>
                                        <option value="UZ">Uzbekistan</option>
                                        <option value="VU">Vanuatu</option>
                                        <option value="VE">Venezuela</option>
                                        <option value="VN">Viet Nam</option>
                                        <option value="VG">Virgin Islands, British</option>
                                        <option value="VI">Virgin Islands, U.S.</option>
                                        <option value="WF">Wallis And Futuna</option>
                                        <option value="EH">Western Sahara</option>
                                        <option value="YE">Yemen</option>
                                        <option value="ZM">Zambia</option>
                                        <option value="ZW">Zimbabwe</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <div class="form-group">
                                    <label><b>Card Number</b></label>

                                    <div id="inputContainer">
                                        <input #cardInput formControlName="cardNumber" inputmode="numeric"
                                            pattern="[0-9]*" name="cardno" id="cr_no"
                                            class="form-control form-control-lg form-control-a"
                                            placeholder="{{ cardPlaceholder }}" minlength="15"
                                            maxlength="{{ cardLength }}" required
                                            (keyup)="onKeyupCardNumber(cardInput.value)"
                                            (blur)="onBlurCardNumber(cardInput.value)"
                                            (focus)="onFocusCardNumber(cardInput.value); cardNumberError = false;"
                                            [ngClass]="{'successCardNumber': getCardLogoFileName()!=null && isFocused,
                                            'errorCardNumber': getValidCardLength(), 'errorBorder': cardNumberError }" />
                                        <div class=" validation"></div>

                                        <img *ngIf="getCardLogoFileName()!=null"
                                            src="assets/img/svg/{{getCardLogoFileName()}}" id="inputImg">
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <div class="form-group">

                                    <label><b>Expiration date (MM/YY)</b></label>
                                    <div class="row">
                                        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-11">

                                            <input #expInput formControlName="cardExpiry" type="numeric"
                                                pattern="[0-9]*" class="form-control form-control-lg form-control-a"
                                                name="exp" id="exp" placeholder="{{ expiryPlaceholder }}" minlength="5"
                                                maxlength="5" required (keyup)="onKeyupCardExpDate(expInput.value)"
                                                (focus)="onFocusCardExpDate(expInput.value); expiryError = false;"
                                                (blur)="onBlurCardExpDate(expInput.value)"
                                                [ngClass]="{'successCardNumber': getValidExpiryDate() && activateExpiryBorder, 
                                                'errorCardNumber': getInvalidExpiryMonth() , 'errorBorder': expiryError}" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div class="form-group">
                                    <label><b>CVC / CVV</b></label>
                                    <input #cvvInput (keyup)="setCardCvv(cvvInput.value)" formControlName="cardCvv"
                                        (focus)="cvvError = false;" [ngClass]="{'errorBorder': cvvError}" type="numeric"
                                        pattern="[0-9]*" name="cvcpwd" id="cvv" placeholder="123(4)"
                                        class="form-control form-control-lg form-control-a" minlength="3"
                                        maxlength="{{ cvvLength }}" (keyup)="onKeyupCvv()" required>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8 mb-4 mb-md-0">
                                <button *ngIf="!updateLoading" type="submit" class="btn btn-a"
                                    (click)="updatePaymentInfo()">
                                    Update
                                </button>

                                <button *ngIf="updateLoading" class="btn" type="button" disabled style="margin: auto;">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    Updating...
                                </button>
                            </div>

                            <ng-container *ngIf="isActivePlan">
                                <div class="col-md-4 text-center">
                                    <div class="card-box-small-color pt-3">
                                        <h5 class="color-green">Card is approved</h5>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="!isActivePlan">
                                <div class="col-md-4 text-center">
                                    <div class="card-box-small-color pt-3">
                                        <h5 class="color-red">Card is not approved</h5>
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Invoices -->
        <app-invoice [orders]="orders"></app-invoice>

        <!-- Subscriptions -->
        <app-subscription [subscriptions]="subscriptions"></app-subscription>

    </div>
</section>
<!--/ Billing End /-->

<app-footer></app-footer>