<app-header></app-header>

<!--/ News /-->
<section class="intro-single section-t8 section-b4 background-mural-01">
    <div class="container-fluid">

        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-between pb-4">
                    <div class="title-box">
                        <h2 class="title-a">News</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <p class="mt-1">
                    Our news sections gives you the latest on the world of spyware, malware, hacking,
                    personal data, antivirus protection and much more. We will keep you updated, secure and protected.
                </p>
            </div>
            <div class="col-md-4">
                <div class="text-md-right text-left">
                    <div class="card-box-ico">
                        <div class="d-inline-flex float-left mr-3"><b>News:</b></div> <b>3</b> in total
                    </div>
                </div>
            </div>
        </div>

        <!-- TODO: ng for each news card (component) -->
        <div class="row">
            <div class="col-md-4">
                <div class="card-box-ico">
                    <img src="https://via.placeholder.com/1600x900.png/e5eaef/ffffff?text=SPARAV News"
                        class="img-fluid">
                    <h5 class="mt-4">
                        <a routerLink="/news/{{ articles.one }}">
                            <b>
                                More than two thirds of all Android antivirus apps are ineffective and many don't even
                                do anything at all
                            </b>
                        </a>
                    </h5>
                    <p class="color-f mb-2">&#8212; 17-03-2020</p>
                    <p class="">
                        Facepalm: In a survey of 250 Android so-called antivirus apps, only 80 were found to block more
                        than 30% of malware samples. Some of the apps tested didn't even have any antivirus
                        functionality at all.
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card-box-ico">
                    <img src="https://via.placeholder.com/1600x900.png/e5eaef/ffffff?text=SPARAV News"
                        class="img-fluid">
                    <h5 class="mt-4">
                        <a routerLink="/news/{{ articles.two }}">
                            <b>A fourth of all PCs are unprotected from virus and malware threats</b>
                        </a>
                    </h5>
                    <p class="color-f mb-2">&#8212; 10-04-2019</p>
                    <p class="">
                        Nearly a quarter of all PCs in the world are unprotected from virus, malware and other malicious
                        threats according to Microsoft’s latest Security Intelligence Report. In it, Redmond said
                        computers without any protection are 5.5 times more likely to become infected than systems
                        running some sort of up to date anti-virus software.
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card-box-ico">
                    <img src="https://via.placeholder.com/1600x900.png/e5eaef/ffffff?text=SPARAV News"
                        class="img-fluid">
                    <h5 class="mt-4">
                        <a routerLink="/news/{{ articles.three }}">
                            <b>Is Antivirus Software Necessary in 2020?</b>
                        </a>
                    </h5>
                    <p class="color-f mb-2">&#8212; 13-01-2020</p>
                    <p class="">
                        Windows, Android, iOS, and Mac operating systems all have decent security protections, so is an
                        antivirus still necessary in 2020?
                        Considering the fact that users on every operating system get hacked every day, the answer is a
                        resounding YES!
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--/ News End /-->

<app-footer></app-footer>