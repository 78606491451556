<div class="row">
    <div class="col-md-12">
        <div class="card-box-ico">

            <div class="text-md-right text-left">
                <div class="d-inline-flex float-left mr-3 mb-3">
                    <h5>Order Receipts</h5>
                </div>
            </div>

            <div class="table-responsive">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>
                                <h6 class=""></h6>
                            </th>
                            <th>
                                <h6 class="">ORDER</h6>
                            </th>
                            <th>
                                <h6 class="">DATE</h6>
                            </th>
                            <th>
                                <h6 class="">PRICE</h6>
                            </th>
                            <th class="text-right">
                                <h6 class="">PDF</h6>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- orders data -->
                        <ng-container *ngFor="let order of orders">
                            <tr>
                                <td>
                                    <img src="assets/img/svg/icon-incvoice.svg" width="30px">
                                </td>
                                <td>
                                    <p class="small">
                                        Order - {{ order.order_id }}
                                        <br>

                                        <!-- unpaid order -->
                                        <ng-container *ngIf="order.order_status !== '2'">
                                            <span class="color-red">
                                                <i class="fa fa-minus-circle"></i>
                                                Not paid yet
                                            </span>
                                        </ng-container>

                                        <!-- paid order -->
                                        <ng-container *ngIf="order.order_status === '2'">
                                            <span class="color-green">
                                                <i class="fa fa-check-circle"></i>
                                                Paid
                                            </span>
                                        </ng-container>

                                    </p>
                                </td>
                                <td>
                                    <p class="small">{{ order.date | date: 'dd/MM/yyyy' }}</p>
                                </td>
                                <td>
                                    <p class="small">
                                        €{{ order.total_price || '*.**' }} (EUR)
                                        <br>
                                        <span class="color-f">
                                            (VAT {{ order.vat_percent || '*' }}% €{{ order.vat || '*' }})
                                        </span>
                                    </p>
                                </td>
                                <td class="text-right">
                                    <a (click)="goToReceipt(order.order_id)" class="btn-a small color-f"
                                        data-toggle="tooltip" data-placement="top" title="Download PDF">
                                        <i class="fa fa-download"></i>
                                        Download PDF
                                    </a>
                                </td>
                            </tr>
                        </ng-container>

                        <!-- <tr>
                            <td>
                                <img src="assets/img/svg/icon-incvoice.svg" width="30px">
                            </td>
                            <td>
                                <p class="small">
                                    Invoice-8594216
                                    <br>
                                    <span class="color-red">
                                        <i class="fa fa-minus-circle"></i>
                                        Not paid yet
                                    </span>
                                </p>
                            </td>
                            <td>
                                <p class="small">02/02/2020</p>
                            </td>
                            <td>
                                <p class="small">
                                    €9.99 (EUR)
                                    <br>
                                    <span class="color-f">(VAT 25% €2)</span>
                                </p>
                            </td>
                            <td class="text-right">
                                <a href="" class="small color-f" data-toggle="tooltip" data-placement="top"
                                    title="Download PDF"><i class="fa fa-download"></i> Download PDF</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="assets/img/svg/icon-incvoice.svg" width="30px">
                            </td>
                            <td>
                                <p class="small">
                                    Invoice-8594215
                                    <br>
                                    <span class="color-green"><i class="fa fa-check-circle"></i> Paid</span>
                                </p>
                            </td>
                            <td>
                                <p class="small">02/02/2020</p>
                            </td>
                            <td>
                                <p class="small">
                                    €9.99 (EUR)
                                    <br>
                                    <span class="color-f">(VAT 25% €2)</span>
                                </p>
                            </td>
                            <td class="text-right">
                                <a href="" class="small color-f" data-toggle="tooltip" data-placement="top"
                                    title="Download PDF"><i class="fa fa-download"></i> Download PDF</a>
                            </td>
                        </tr> -->
                    </tbody>
                </table>
            </div>
            <hr>
            <p class="small">
                If you have an issue with an invoice, please contact our team at invoice@sparav.com.
            </p>
        </div>
    </div>
</div>