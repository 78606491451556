import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CustomerService } from 'src/app/shared/services/customer.service';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit {

  maxDevices: string;

  constructor(private router: Router, private customerService: CustomerService) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) { return; }
      window.scrollTo(0, 0);
    });

    this.customerService.fetchOrdersInfo().subscribe(ordersArray => {
      for (const order of ordersArray) {
        // if customer has unlimited device addon
        if (order.$product_id === '3' && order.$active === '1') {
          this.maxDevices = 'unlimited';
        }
      }
    });
  }

}
