<!--/ News single /-->
<section class="intro-single section-t8 section-b4 background-mural-01">
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="card-box-ico pb-4">
                    <h2 class="mb-4">We are sorry to hear that you would like to cancel your account</h2>
                    <hr>
                    <h5 class="mb-4">Help us get better by telling us why you want to cancel your account</h5>
                    <form class="form-a" action="" method="post" role="form">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <div class="form-group">
                                    <label for="inputEmail"><h5>Please choose *</h5></label>
                                    <br>
                                    <div class="custom-control custom-radio mr-4 mb-2">
                                        <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input" checked>
                                        <label class="custom-control-label" for="customRadio1">I had technical issues that were not solved</label>
                                    </div>
                                    <div class="custom-control custom-radio mr-4 mb-2">
                                        <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input">
                                        <label class="custom-control-label" for="customRadio2">The features I need are not offered</label>
                                    </div>
                                    <div class="custom-control custom-radio mr-4 mb-2">
                                        <input type="radio" id="customRadio3" name="customRadio" class="custom-control-input">
                                        <label class="custom-control-label" for="customRadio3">My renewal fee was more expensive than expected</label>
                                    </div>
                                    <div class="custom-control custom-radio mr-4 mb-2">
                                        <input type="radio" id="customRadio4" name="customRadio" class="custom-control-input">
                                        <label class="custom-control-label" for="customRadio4">I was not aware of the recurring payments</label>
                                    </div>
                                    <div class="custom-control custom-radio mr-4 mb-2">
                                        <input type="radio" id="customRadio5" name="customRadio" class="custom-control-input">
                                        <label class="custom-control-label" for="customRadio5">Other</label>
                                    </div>
                                </div>
                                <hr>
                                <a *ngIf="!loading" href="javascript:void(0)" (click)="cancelSubscription()" class="version-b mt-5">Cancel your account <i class="fa fa-angle-right"></i></a>
                                <span *ngIf="loading">Please wait..</span>
                                <hr>
                                <a href="/" class="small">Leave cancellation</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>


<app-footer></app-footer>
