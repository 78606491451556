<app-header></app-header>

<!--/ Profile /-->
<section class="intro-single section-t8 section-b4 background-mural-01">
    <div class="container-fluid">

        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-between pb-4">
                    <div class="title-box">
                        <h2 class="title-a">Profile</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-8">
                <p class="mt-1">Your profile holds your personal contact and login information. The information is only
                    visible from your account and otherwise encrypted by our system.</p>
            </div>
            <div class="col-md-4">
                <div class="text-md-right text-left">
                    <div class="card-box-ico">
                        <div class="d-inline-flex float-left mr-3"><b>Member since:</b></div>
                        {{ memberSince || '**/**/****' }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card-box-ico">
                    <div class="row">
                        <div class="col-md-6">
                            <h5>Basic information</h5>
                            <hr>
                            <br>
                            <form class="form-a" action="" method="post" role="form" [formGroup]="customerForm">
                                <div class="row">
                                    <div class="col-md-12 mb-3">
                                        <div class="form-group">
                                            <label for="inputFirstName"><b>Full name</b></label>
                                            <input type="text" name="fullname"
                                                class="form-control form-control-lg form-control-a"
                                                placeholder="Enter your full name"
                                                data-msg="Please enter your full name" formControlName="fullname"
                                                [ngClass]="{'errorBorder': fullnameError}"
                                                (focus)="fullnameError = false;">
                                            <div class="validation"></div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <div class="form-group">
                                            <label for="inputEmail"><b>Contact e-mail</b></label>
                                            <input name="email" type="email"
                                                class="form-control form-control-lg form-control-a"
                                                placeholder="Enter your email" data-rule="email"
                                                data-msg="Please enter your email" formControlName="email"
                                                [ngClass]="{'errorBorder': emailError}" (focus)="emailError = false;">
                                            <div class="validation"></div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label for="inputPhone"><b>Phone number</b></label>
                                        <div class="row">
                                            <div class="col-4 col-md-3">
                                                <div class="form-group">
                                                    <input type="tel" name="phoneCode" formControlName="phoneCode"
                                                        class="form-control form-control-lg form-control-a"
                                                        placeholder="47" [ngClass]="{'errorBorder': prefixError}"
                                                        (focus)="prefixError = false;">
                                                    <div class="validation"></div>
                                                </div>
                                            </div>
                                            <div class="col-8 col-md-9">
                                                <div class="form-group">
                                                    <input type="tel" name="phone"
                                                        class="form-control form-control-lg form-control-a"
                                                        placeholder="Enter your phone number" data-rule="minlen:8"
                                                        data-msg="Please enter at least 8 numbers"
                                                        formControlName="phone" [ngClass]="{'errorBorder': phoneError}"
                                                        (focus)="phoneError = false;">
                                                    <div class="validation"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <button *ngIf="!updateInfoLoading" type="submit" class="btn btn-a"
                                            (click)="updateInfo()">
                                            Update
                                        </button>

                                        <button *ngIf="updateInfoLoading" class="btn" type="button" disabled
                                            style="margin: auto;">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Updating...
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-6">
                            <span class="d-block d-sm-none"><br><br></span>
                            <h5>Change password</h5>
                            <hr>
                            <br>
                            <form class="form-a" action="" method="post" role="form" [formGroup]="passwordForm">
                                <div class="row">
                                    <div class="col-md-12 mb-3">
                                        <div class="form-group">
                                            <label for="inputCurrentPassword"><b>Verify current password</b></label>
                                            <input type="password" name="currentpassword"
                                                class="form-control form-control-lg form-control-a"
                                                placeholder="Enter your current password"
                                                data-msg="Please enter your current password"
                                                formControlName="currentPassword"
                                                [ngClass]="{'errorBorder': currentPasswordError}"
                                                (focus)="currentPasswordError = false;">
                                            <div class="validation"></div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-2">
                                        <div class="form-group">
                                            <label for="inputPassword"><b>New passsword</b></label>
                                            <input type="password" name="newpassword"
                                                class="form-control form-control-lg form-control-a" data-rule="minlen:8"
                                                placeholder="Enter your new password"
                                                data-msg="Please enter your new password" formControlName="newPassword"
                                                [ngClass]="{'errorBorder': newPasswordError}"
                                                (focus)="newPasswordError = false;">
                                            <div class="validation"></div>
                                        </div>
                                        <div class="d-flex bd-highlight">
                                            <div class="p-2 bd-highlight">
                                                <ul>
                                                    <li>min. 4 characters</li>
                                                </ul>
                                            </div>
                                            <div class="p-2 bd-highlight">
                                                <ul>
                                                    <li>max. 50 characters</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <div class="form-group">
                                            <label for="inputEmail"><b>Confirm new password</b></label>
                                            <input name="confirmpassword" type="password"
                                                class="form-control form-control-lg form-control-a" data-rule="minlen:8"
                                                placeholder="Re-enter your new password"
                                                data-msg="Please enter your new password again"
                                                formControlName="confirmPassword"
                                                [ngClass]="{'errorBorder': confirmPasswordError}"
                                                (focus)="confirmPasswordError = false;">
                                            <div class="validation"></div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <button *ngIf="!updatePasswordLoading" type="submit" class="btn btn-a"
                                            (click)="updatePassword()">
                                            Update
                                        </button>

                                        <button *ngIf="updatePasswordLoading" class="btn" type="button" disabled
                                            style="margin: auto;">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Updating...
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--/ Profile End /-->

<app-footer></app-footer>