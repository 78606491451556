<app-header></app-header>

<!--/ Devices /-->
<section class="intro-single section-t8 section-b4 background-mural-01">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-between pb-4">
                    <div class="title-box">
                        <h2 class="title-a">Devices</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <p class="mt-1">Protecting all your devices is easy. Simply click on the <span
                        class="color-green">"Download"</span> button below for the device you want to protect and follow
                    the download and installation instructions.</p>
            </div>
            <div class="col-md-4">
                <div class="text-md-right text-left">
                    <div class="card-box-ico">
                        <div class="d-inline-flex float-left mr-3">
                            <b>Licenses:</b>
                        </div>
                        <b>4/{{ maxDevices || '10' }}</b> licenses used
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card-box-ico">
                    <div class="table-responsive">
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th>
                                        <h6 class=""></h6>
                                    </th>
                                    <th>
                                        <h6 class="">DEVICE</h6>
                                    </th>
                                    <th>
                                        <h6 class="">VERSION</h6>
                                    </th>
                                    <th>
                                        <h6 class="">LICENSES</h6>
                                    </th>
                                    <th class="text-right">
                                        <h6 class="">REMOVE</h6>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <img src="assets/img/svg/icon-windows.svg" width="30px">
                                    </td>
                                    <td>
                                        <p class="small">Windows PC</p>
                                    </td>
                                    <td>
                                        <p class="small">SPARAV version V1.1.0</p>
                                    </td>
                                    <td>
                                        <p class="small">1 license</p>
                                    </td>
                                    <td class="text-right">
                                        <a href="" class="small color-f" data-toggle="tooltip" data-placement="top"
                                            title="Remove device"><i class="fa fa-close"></i> Remove</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src="assets/img/svg/icon-mac.svg" width="30px">
                                    </td>
                                    <td>
                                        <p class="small">Apple / Mac OSX</p>
                                    </td>
                                    <td>
                                        <p class="small">SPARAV version V1.1.0</p>
                                    </td>
                                    <td>
                                        <p class="small">1 license</p>
                                    </td>
                                    <td class="text-right">
                                        <a href="" class="small color-f" data-toggle="tooltip" data-placement="top"
                                            title="Remove device"><i class="fa fa-close"></i> Remove</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src="assets/img/svg/icon-android.svg" width="30px">
                                    </td>
                                    <td>
                                        <p class="small">Android</p>
                                    </td>
                                    <td>
                                        <p class="small">SPARAV version V1.1.0</p>
                                    </td>
                                    <td>
                                        <p class="small">1 license</p>
                                    </td>
                                    <td class="text-right">
                                        <a href="" class="small color-f" data-toggle="tooltip" data-placement="top"
                                            title="Remove device"><i class="fa fa-close"></i> Remove</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src="assets/img/svg/icon-ios.svg" width="30px">
                                    </td>
                                    <td>
                                        <p class="small">iOS</p>
                                    </td>
                                    <td>
                                        <p class="small">SPARAV version V1.1.0</p>
                                    </td>
                                    <td>
                                        <p class="small">1 license</p>
                                    </td>
                                    <td class="text-right">
                                        <a href="" class="small color-f" data-toggle="tooltip" data-placement="top"
                                            title="Remove device"><i class="fa fa-close"></i> Remove</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for Windows PC</h5>
                    <br>
                    <img src="assets/img/svg/icon-windows-sparav.svg" width="" class="img-fluid">
                    <br><br>
                    <p>Download the SPARAV Antivirus Protection Application for the Windows PC.</p>
                    <hr>
                    <!-- TODO: SPARAV PC DOWNLOAD -->
                    <div class="disabled color-f" style="text-decoration: line-through;">
                        Coming soon
                        <i class="fa fa-angle-right"></i>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for Mac OSX</h5>
                    <br>
                    <img src="assets/img/svg/icon-mac-sparav.svg" width="" class="img-fluid">
                    <br><br>
                    <p>Download the SPARAV Antivirus Protection Application for Apple / Mac OSX.</p>
                    <hr>
                    <div class="mt-4">
                        <a href="https://sparav.com/mac.html" class="version-b" data-toggle="modal"
                            data-target="#myModalWindowsPopup" target="_blank">
                            Download
                            <i class="fa fa-angle-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for Android</h5>
                    <br>
                    <img src="assets/img/svg/icon-android-sparav.svg" width="" class="img-fluid">
                    <br><br>
                    <p>Download the SPARAV Antivirus Protection App for Android phones.</p>
                    <hr>
                    <a href="https://play.google.com/store/apps/details?id=defsecuretech.sparav.app" target="_blank"
                        class="version-b" data-toggle="modal" data-target="#myModalWindowsPopup">
                        Download
                        <i class="fa fa-angle-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for iOS</h5>
                    <br>
                    <img src="assets/img/svg/icon-ios-sparav.svg" width="" class="img-fluid">
                    <br><br>
                    <p>Download the SPARAV Antivirus Protection App for iPhones.</p>
                    <hr>
                    <div class="disabled color-f" style="text-decoration: line-through;">Coming soon <i
                            class="fa fa-angle-right"></i></div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--/ Devices End /-->

<app-footer></app-footer>