import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public isCollapsed = true;
  public hidePassword = true;

  public emailError;
  public passwordError;
  public loading;

  loginForm = new FormGroup({
    email: new FormControl(null, [
      Validators.required,
    ]),
    password: new FormControl(null, [
      Validators.required,
    ]),
  });

  constructor(
    private router: Router,
    private loginService: LoginService,
    private customerService: CustomerService
  ) { }

  ngOnInit(): void {
    // scroll to top
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) { return; }
      window.scrollTo(0, 0);
    });

    this.validateExistingLogin();

    // reset loading
    this.loginService.currentLoadingScreen.subscribe(loading => {
      this.loading = loading;
    });
  }

  /**
   * Validate token on /login route
   */
  validateExistingLogin(): void {
    if (localStorage.getItem('loginToken')) {
      this.loginService.validateToken(localStorage.getItem('loginToken')).then((res) => {
        switch (res.response_code) {
          case 401:
            console.error('Unauthorized login');
            this.loginService.logout();
            break;
          case 200:

            this.router.navigate(['/dashboard']);

            break;
          default:
            this.loginService.logout();
            break;
        }
      });
    }
  }

  /**
   * Login submit
   */
  onSubmit(): void {
    const username: string = this.loginForm.value.email;
    const password: string = this.loginForm.value.password;

    // console.log('e', this.loginForm.get('email'));
    // console.log('p', this.loginForm.get('password'));

    if (this.loginService.inputfieldIsEmpty(username)) { this.emailError = true; }
    if (this.loginService.inputfieldIsEmpty(password)) { this.passwordError = true; }

    if (
      !this.loginService.inputfieldIsEmpty(username) &&
      !this.loginService.inputfieldIsEmpty(password)
    ) {

      this.loading = true;

      this.loginService.login(username, password).then((res) => {
        // console.log(res);

        switch (res.response_code) {
          case 401:
            console.log('unauthorized, no such account');
            alert('Email does not exist.');
            this.loading = false;
            break;
          case 403:
            console.log('unauthorized, wrong password');
            alert('Wrong password.');
            this.loading = false;
            break;
          case 200:

            localStorage.setItem('loginToken', res.jwtToken);
            localStorage.setItem('username', res.email);

            // fetch all customer data on successful login
            this.customerService.fetchCustomerInfo().subscribe(customerInfo => {
              this.customerService.fetchOrdersInfo().subscribe(ordersInfo => {
                this.router.navigate(['/dashboard']);
              });
            });

            break;
          default:
            alert('Server error. Please try again later');
            console.error('Login error', res);
            this.loading = false;
            break;
        }

      });

    }

  }

}
