<app-header></app-header>

<!--/ News single /-->
<section class="intro-single section-t8 section-b4 background-mural-01">
    <div class="container-fluid">

        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-between pb-4">
                    <div class="title-box">
                        <h2 class="title-a">News</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-7 mt-4">
                <p class="mt-1">
                    <a routerLink="/news">
                        <i class="fa fa-angle-left"></i>
                        Back to News
                    </a>
                </p>
            </div>
            <div class="col-md-5">
                <div class="text-md-right text-left">
                    <div class="card-box-ico">
                        <div class="d-inline-flex float-left mr-3"><b>Navigate:</b></div>
                        <span class="d-sm-none"><br></span>
                        <a (click)="goToArticle(currentArticle.prevArticle)" class="btn-a mr-3" style="cursor: pointer;">
                            <i class="fa fa-angle-left"></i>
                            Previous article
                        </a>
                        <a (click)="goToArticle(currentArticle.nextArticle)" class="btn-a" style="cursor: pointer;">
                            Next article
                            <i class="fa fa-angle-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card-box-ico pb-4">
                    <h2 class="mb-4">
                        {{ currentArticle.title }}
                        <!-- Headline ipsum dolor sit amet consectetur adipi -->
                    </h2>
                    <img src="https://via.placeholder.com/1600x900.png/e5eaef/ffffff?text=SPARAV News"
                        class="img-fluid">
                    <p class="color-f mb-2 mt-3">&#8212; 26-02-2020</p>
                    <h4>
                        {{ currentArticle.headlineOne }}
                        <!-- Sit amet, consectetur adipiscing -->
                    </h4>
                    <p class="">
                        {{ currentArticle.bodyOne }}
                        <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis dui a odio
                        tincidunt tincidunt. Maecenas consectetur tincidunt turpis, sit amet blandit nisi pharetra ac.
                        Curabitur id ornare nisl. Aenean congue facilisis libero. Nulla commodo odio mauris, non
                        venenatis ipsum gravida id. Donec consequat lobortis eros, eget faucibus neque pharetra nec.
                        Mauris massa orci, interdum non dapibus eu, auctor nec nisl. Interdum et malesuada fames ac ante
                        ipsum primis in faucibus. -->
                    </p>
                    <!-- <h4>Phasellus ligula sapien, posuere eu</h4>
                    <p>Nunc tempus, velit eu aliquet ultricies, dolor massa eleifend purus, vitae posuere augue sapien
                        at lorem. Quisque finibus sit amet velit molestie placerat. Nam luctus sapien et sapien
                        tristique luctus. Nunc malesuada feugiat ipsum in sagittis. Donec pharetra tristique lacus ac
                        aliquam. Donec hendrerit eros eget condimentum ultrices. Mauris vel justo faucibus, scelerisque
                        orci et, tincidunt sem. Vivamus condimentum id purus at sodales. Phasellus ligula sapien,
                        posuere eu lobortis non, pretium placerat mauris. In sit amet ornare dolor, sit amet ornare
                        magna.
                    </p>
                    <p>Nulla porttitor sollicitudin diam. Nunc efficitur dolor eu risus lacinia feugiat. Integer
                        pharetra, enim quis bibendum elementum, ante massa elementum leo, a feugiat lacus nibh eget
                        lectus. Proin venenatis felis id nibh porttitor ultricies. Donec turpis felis, tempus ut
                        facilisis quis, sodales ac leo. Nullam nibh dui, elementum et ante feugiat, tempor iaculis ex.
                        Curabitur eleifend libero vel lacus vehicula, non consequat lectus maximus.
                    </p> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!--/ News single End /-->

<app-footer></app-footer>