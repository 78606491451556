import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IpService {

  constructor(private http: HttpClient) { }

  // todo: send locale once localize is implemented
  public getIPAddress(): any {
    return this.http.get('https://sparavuiwebsiteorderapiprod.azurewebsites.net/api/v1/client/info', {
      params: {
        lang: localStorage.getItem('locale')
      }
    });
  }
}
