import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { CustomerAdapter } from 'src/app/shared/models/customer.model';
import { browserRefresh } from 'src/app/app.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  profileIntro: string;
  isCollapsed: boolean;

  constructor(
    private loginService: LoginService,
    private customerService: CustomerService,
    private customerAdapter: CustomerAdapter
  ) { }

  ngOnInit(): void {

    // collapse navbar
    this.isCollapsed = true;

    this.customerService.fetchCustomerInfo().subscribe(customer => {
      if (customer) { this.profileIntro = 'Hi, ' + customer.firstname; }
    });
  }

  logout(): void {
    this.loginService.logout();
  }
}
