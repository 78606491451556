<section class="intro-single background-mural-01" style="height:100vh;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 text-center">
                <img src="assets/img/svg/sparav-logo.svg" width="240px" class="mb-5">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="card-box-ico">
                    <div class="text-center">
                        <a class="small" role="button" routerLink='/'>
                            <i class="fa fa-arrow-left"></i>
                            Go back
                        </a>
                    </div>
                    <form class="form-a" action="" method="post" role="form" [formGroup]="emailForm">

                        <div>
                            <hr>
                            <div class="card-box-small-color">

                                <h2 class="text-center mt-4"><i class="fa fa-unlock"></i> Forgot password</h2>

                                <p class="text-center">Enter your email to receive your password</p>
                                <div class="form-group">
                                    <label for="inputEmail"><b>Email *</b></label>
                                    <input name="email" type="email" class="form-control form-control-lg form-control-a"
                                        placeholder="Email Address" data-msg="Please enter your email"
                                        formControlName="email" [ngClass]="{'errorBorder': emailError}"
                                        (focus)="emailError = false;">
                                    <div class="validation"></div>
                                </div>

                                <div class="row">
                                    <button *ngIf="!loading" type="submit" class="btn btn-a w-80"
                                        (click)="onForgotPassword()" style="margin: auto;">
                                        Send my password
                                    </button>

                                    <button *ngIf="loading" class="btn" type="button" disabled style="margin: auto;">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        Sending email...
                                    </button>
                                </div>

                            </div>
                        </div>

                    </form>
                </div>
                <p class="small text-center mb-5"><i class="fa fa-shield"></i> Secure and safe data processing</p>
            </div>
        </div>
    </div>
</section>