<div class="row">
    <div class="col-md-12">
        <div class="card-box-ico">

            <div class="text-md-right text-left">
                <div class="d-inline-flex float-left mr-3 mb-3">
                    <h5>Subscriptions</h5>
                </div>
            </div>

            <div class="table-responsive">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>
                                <h6 class=""></h6>
                            </th>
                            <th>
                                <h6 class="">NAME</h6>
                            </th>
                            <th>
                                <h6 class="">DATE OF PURCHASE</h6>
                            </th>
                            <th>
                                <h6 class="">NEXT BILLING DATE</h6>
                            </th>
                            <th>
                                <h6 class="">STATUS</h6>
                            </th>
                            <!-- <th class="text-right">
                                <h6 class="">UPGRADE</h6>
                            </th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <!-- subs data -->
                        <ng-container *ngFor="let subscription of subscriptions">
                            <tr>
                                <td>
                                    <img src="assets/img/svg/icon-incvoice.svg" width="30px">
                                </td>
                                <td>
                                    <p class="small">
                                        {{ subscription.name }}
                                        <br>
                                    </p>
                                </td>
                                <td>
                                    <p class="small">{{ subscription.date | date: 'dd/MM/yyyy' }}</p>
                                </td>
                                <td>
                                    <p class="small">
                                        {{ subscription.billingDate | date: 'dd/MM/yyyy' }}
                                    </p>
                                </td>

                                <td>
                                    <ng-container *ngIf="!subscription.isRecurring">
                                            <span class="color-red">
                                                <i class="fa fa-minus-circle"></i>
                                                Inactive
                                            </span>
                                        </ng-container>

                                        <ng-container *ngIf="subscription.isRecurring">
                                            <span class="color-green">
                                                <i class="fa fa-check-circle"></i>
                                                Active
                                            </span>
                                        </ng-container>
                                </td>

                                <!-- <td class="text-right">
                                    <a class="btn-a small color-f"
                                        data-toggle="tooltip" data-placement="top" title="Download PDF">
                                        <i class="fa fa-download"></i>
                                        Download PDF
                                    </a>
                                </td> -->

                            </tr>
                        </ng-container>

                    </tbody>
                </table>
            </div>
            <hr>
            <p class="small">
                If you have an issue with a subscription, please contact our team at help@sparav.com.
            </p>
        </div>
    </div>
</div>