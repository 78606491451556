<section class="intro-single section-t8 section-b4 background-mural-01">
    <div class="container">

        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-between pb-4 mb-2">
                    <div class="title-box">
                        <img src="assets/img/svg/sparav-logo.svg" width="300px">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <h3 class="title-a ml-3 mt-3">
                    Order Receipt
                </h3>
                <!-- <p class="mt-1">
                    name
                </p>
                <p class="mt-1">
                    address
                </p>
                <p class="mt-1">
                    phone
                </p> -->
            </div>
            <div class="col-md-4">
                <div class="text-md-right text-left">
                    <div class="card-box-ico">
                        <div class="d-inline-flex float-left mr-3">
                            <b>
                                {{ customer.firstname }} {{ customer.lastname }}
                            </b>
                        </div>
                        {{ customer.address }}
                        <br>
                        {{ customer.zipcode }} {{ customer.country }}
                        <br>
                        +{{ customer.prefix }} {{ customer.phone }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card-box-ico">
                    <div class="table-responsive">
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th>
                                        <h6 class="">DATE</h6>
                                    </th>
                                    <th>
                                        <h6 class="">ORDER</h6>
                                    </th>
                                    <th>
                                        <h6 class="">DESCRIPTION</h6>
                                    </th>
                                    <th>
                                        <h6 class="">QUANTITY</h6>
                                    </th>
                                    <th>
                                        <h6 class="">STATUS</h6>
                                    </th>
                                    <th class="text-right">
                                        <h6 class="">PRICE</h6>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td>
                                        {{ order.$date | date: 'dd/MM/yyyy' }}
                                    </td>
                                    <td>
                                        <p class="small">
                                            {{ order.$order_id }}
                                            <br>
                                        </p>
                                    </td>
                                    <td>
                                        <p class="small">
                                            {{ order.$product_name }}
                                        </p>
                                    </td>
                                    <td>
                                        <p class="small">
                                            1
                                        </p>
                                    </td>
                                    <td>

                                        <!-- unpaid order -->
                                        <ng-container *ngIf="order.$order_status !== '2'">
                                            <span class="color-red">
                                                <i class="fa fa-minus-circle"></i>
                                                Not paid
                                            </span>
                                        </ng-container>

                                        <!-- paid order -->
                                        <ng-container *ngIf="order.$order_status === '2'">
                                            <span class="color-green">
                                                <i class="fa fa-check-circle"></i>
                                                Paid
                                            </span>
                                        </ng-container>

                                    </td>
                                    <td class="text-right">
                                        <!-- <a href="" class="small color-f" data-toggle="tooltip" data-placement="top"
                                            title="Download PDF"><i class="fa fa-download"></i> Download PDF</a> -->
                                        €{{ order.$total_price }} (EUR)
                                        <br>
                                        <span class="color-f">(VAT {{ order.$vat_percent }}% €{{ order.$vat }})</span>
                                    </td>
                                </tr>

                                <!-- <tr>
                                    <td>
                                        <img src="assets/img/svg/icon-incvoice.svg" width="30px">
                                    </td>
                                    <td>
                                        <p class="small">
                                            Invoice-8594215
                                            <br>
                                            <span class="color-green"><i class="fa fa-check-circle"></i> Paid</span>
                                        </p>
                                    </td>
                                    <td>
                                        <p class="small">02/02/2020</p>
                                    </td>
                                    <td>
                                        <p class="small">
                                            €9.99 (EUR)
                                            <br>
                                            <span class="color-f">(VAT 25% €2)</span>
                                        </p>
                                    </td>
                                    <td>
                                        <img src="assets/img/svg/icon-incvoice.svg" width="30px">
                                    </td>
                                    <td class="text-right">
                                        <a href="" class="small color-f" data-toggle="tooltip" data-placement="top"
                                            title="Download PDF"><i class="fa fa-download"></i> Download PDF</a>
                                    </td>
                                </tr> -->

                            </tbody>
                        </table>
                    </div>
                    <hr>
                    <p class="text-right mr-2">
                        Total
                    </p>
                    <hr>
                    <p class="text-right mr-2">
                        €{{ order.$total_price }} (EUR)
                    </p>
                </div>
            </div>
        </div>

        <!-- footer -->
        <div class="row">
            <div class="col-md-12 mt-5">
                <div class="text-md-right text-left">
                    <div class="card-box-ico">
                        <div class="row mt-3">
                            <div class="col-sm-12 col-md-4 text-center">
                                <div class="widget-a mb-5">
                                    <div class="w-header-a">
                                        <h3 class="w-title-a text-brand mb-5">
                                            <img src="assets/img/svg/icon-sparav.svg" width="25px" class="mr-2">
                                            SPARAV
                                        </h3>
                                    </div>
                                    <div class="w-footer-a">
                                        <ul class="list-unstyled">
                                            <li class="color-c">
                                                Defsecure Technologies ApS
                                                <br>
                                                Company org. no.: 40765840
                                                <br>
                                                Vesterbrogade 41 B, 1.,
                                                <br>
                                                DK-1620 København V
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-4 text-center">
                                <div class="widget-a mb-5">
                                    <div class="w-header-a">
                                        <h3 i18n="@@support title" class="w-title-a text-brand mb-5">
                                            Contact
                                        </h3>
                                    </div>
                                    <div class="w-footer-a">
                                        <ul class="list-unstyled">
                                            <li class="color-a">
                                                <span class="color-c">
                                                    <i class="fa fa-envelope" aria-hidden="true"> </i>
                                                    <ng-container i18n="@@email title">
                                                        Email:
                                                    </ng-container>
                                                </span>: <a href="mailto:help@sparav.com">help@sparav.com</a>
                                                <br>
                                                <span class="color-c">
                                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                                    <ng-container i18n="@@phone us title">
                                                        Phone (US):
                                                    </ng-container>
                                                </span>: <a href="tel:+18594450368">+1 (859) 445 0368</a>
                                                <br>
                                                <span class="color-c">
                                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                                    <ng-container i18n="@@phone eu title">
                                                        Phone (EU):
                                                    </ng-container>
                                                </span>
                                                <a href="tel:+441174562945‬">
                                                    <ng-container i18n="@@phone eu number">
                                                        +44 117 456 2945‬
                                                    </ng-container>
                                                </a>
                                                <br>
                                                <span class="color-c">
                                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                                    <ng-container i18n="@@phone no title">
                                                        Phone (NO):
                                                    </ng-container>
                                                </span>
                                                <a href="tel:+4756999330‬‬">
                                                    <ng-container i18n="@@phone no number">
                                                        +47 5699 9330‬
                                                    </ng-container>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-4 text-center">
                                <div class="widget-a mb-5">
                                    <div class="w-header-a">
                                        <h3 i18n="@@legal title" class="w-title-a text-brand mb-5">
                                            Legal
                                        </h3>
                                    </div>
                                    <div class="w-body-a">
                                        <ul class="list-unstyled">
                                            <li class="item-list-a">
                                                <i class="fa fa-angle-right"></i>
                                                <a routerLink="/terms-conditions" i18n="@@terms conditions title">
                                                    Terms & Conditions
                                                </a>
                                            </li>
                                            <li class="item-list-a">
                                                <i class="fa fa-angle-right"></i>
                                                <a routerLink="/terms-conditions" i18n="@@subscription terms title">
                                                    Subscription terms
                                                </a>
                                            </li>
                                            <li class="item-list-a">
                                                <i class="fa fa-angle-right"></i>
                                                <a routerLink="/privacy-policy" i18n="@@privacy policy title">
                                                    Privacy Policy
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>