<app-header></app-header>

<!--/ News single /-->
<section class="intro-single section-t8 section-b4 background-mural-01">
    <div class="container">

        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-between pb-4">
                    <div class="title-box">
                        <h2 class="title-a">Privacy</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 mt-4">
                <p class="mt-1">
                    <a href="http://sparav.com/"><i class="fa fa-angle-left"></i> Back to front page</a>
                </p>
            </div>
            <div class="col-md-4">
                <div class="text-md-right text-left">
                    <div class="card-box-ico">
                        <div class="d-inline-flex float-left mr-3"><b>Other:</b></div>
                        <a routerLink="/terms-conditions" class="">Terms <i class="fa fa-angle-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card-box-ico pb-4">
                    <h2 class="mb-4">Privacy Policy</h2>
                    <!-- <h4>PLEASE READ THE FOLLOWING CAREFULLY</h4>
                    <p class="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis dui a odio
                        tincidunt tincidunt. Maecenas consectetur tincidunt turpis, sit amet blandit nisi pharetra ac.
                        Curabitur id ornare nisl. Aenean congue facilisis libero. Nulla commodo odio mauris, non
                        venenatis ipsum gravida id. Donec consequat lobortis eros, eget faucibus neque pharetra nec.
                        Mauris massa orci, interdum non dapibus eu, auctor nec nisl. Interdum et malesuada fames ac ante
                        ipsum primis in faucibus.</p>
                    <h4>Phasellus ligula sapien, posuere eu</h4>
                    <p>Nunc tempus, velit eu aliquet ultricies, dolor massa eleifend purus, vitae posuere augue sapien
                        at lorem. Quisque finibus sit amet velit molestie placerat. Nam luctus sapien et sapien
                        tristique luctus. Nunc malesuada feugiat ipsum in sagittis. Donec pharetra tristique lacus ac
                        aliquam. Donec hendrerit eros eget condimentum ultrices. Mauris vel justo faucibus, scelerisque
                        orci et, tincidunt sem. Vivamus condimentum id purus at sodales. Phasellus ligula sapien,
                        posuere eu lobortis non, pretium placerat mauris. In sit amet ornare dolor, sit amet ornare
                        magna.</p>
                    <p>Nulla porttitor sollicitudin diam. Nunc efficitur dolor eu risus lacinia feugiat. Integer
                        pharetra, enim quis bibendum elementum, ante massa elementum leo, a feugiat lacus nibh eget
                        lectus. Proin venenatis felis id nibh porttitor ultricies. Donec turpis felis, tempus ut
                        facilisis quis, sodales ac leo. Nullam nibh dui, elementum et ante feugiat, tempor iaculis ex.
                        Curabitur eleifend libero vel lacus vehicula, non consequat lectus maximus.</p> -->
                    <p>Effective Date: 3 day of october 2019</p>



                    <p><strong>PLEASE READ THE FOLLOWING CAREFULLY</strong></p>



                    <p><strong>THIS STATEMENT PROVIDES GENERAL INFORMATION
                            ABOUT THE PRIVACY STATEMENT OF THIS WEBSITE AND ITS RELATED SHIELDAPPS (MOBILE
                            OR OTHERWISE). IF YOU ARE UNDER 18 YEARS OF AGE, PLEASE BE SURE TO READ THIS
                            PRIVACY STATEMENT WITH YOUR PARENTS OR GUARDIAN AND ASK THEM QUESTIONS ABOUT
                            WHAT YOU DO NOT UNDERSTAND.</strong></p>



                    <p><strong>YOUR USE OF THIS SERVICE CONSTITUTES
                            ACCEPTANCE BY YOU OF THIS PRIVACY STATEMENT.</strong></p>



                    <p><strong>SPARAV APS </strong>trading under<strong> DEFSECURE TECHNOLOGIES APS</strong><strong>
                        </strong>has created this privacy statement (“Statement”) in
                        order to demonstrate its firm commitment to the privacy of the details that you
                        provide to us when using <a href="http://www.sparav.com">www.sparav.com</a> <strong>(“the
                            website or
                            site”)</strong>&nbsp;&nbsp;
                        , as the data controller for the purposes of the GDPR (General Data
                        Protection Regulations EU 2016/679) and related Denmark Legislation.</p>



                    <p>At <strong>SPARAV</strong>, we are committed to maintaining
                        the trust and confidence of all visitors to our web site. In particular, we
                        want you to know that <strong>SPARAV</strong> is not in the business of selling, renting
                        or trading email lists with other companies and businesses for marketing
                        purposes.</p>



                    <p>We believe your business is no one else’s. Your Privacy is important to
                        you and to us. So, we’ll protect the information you share with us. To protect
                        your privacy, <strong>SPARAV</strong><strong> </strong>follows different principles in
                        accordance with
                        worldwide practices for customer privacy and data protection.</p>



                    <p>–&nbsp;
                        We will not sell or give away your name, mail address, phone number, email address or
                        any other information to anyone.</p>



                    <p>–&nbsp;
                        We will use state – of – the – art security measures to protect your information
                        from unauthorized users.</p>



                    <p>Therefore, to provide you with our services we need (and sometimes are
                        obliged by the law) to collect your personal data. This Privacy Policy (the
                        “Policy”) informs Users <strong>(a “User”, “You” or “Your”)</strong>
                        of our policies regarding the processing of Personal Information we receive
                        from Users of <a href="http://www.sparav.com">www.sparav.com</a> . </p>



                    <p>In this Privacy
                        Policy, we’ve provided detailed information on when and why we collect personal
                        information, how we use it, the limited conditions under which we may disclose
                        it to others, and how we keep it secure.</p>



                    <p>We take your
                        privacy seriously and take measures to provide all visitors and users of our
                        website with a safe and secure environment.</p>



                    <p>The Personal Information on the <a href="http://www.sparav.com">www.sparav.com</a>&nbsp; , is
                        collected, controlled and processed by the following entities:</p>



                    <p><strong>DEFSECURE TECHNOLOGIES APS [SPARAV]</strong></p>



                    <p><strong>VESTERBROGADE 41B, 1 </strong></p>



                    <p><strong>1620 COPENHAGEN, DENMARK</strong></p>



                    <p>Phone<strong>: </strong>71995599</p>



                    <p>E-mail: <a href="mailto:info@sparav.com">info@sparav.com</a> </p>



                    <p><strong>THIS POLICY</strong>&nbsp;&nbsp;&nbsp;&nbsp; </p>



                    <p>This Policy explains our processing of your personal data and your
                        rights according to EU General Data Protection Regulation and Denmark data
                        protection legislation. <strong>SPARAV</strong> reserves the right to modify this Statement at
                        any
                        time without notice by posting the changes on this webpage<strong>.</strong></p>



                    <p><strong>DEFINITIONS</strong></p>



                    <p>“<strong>Personal Data” </strong>means any
                        information which relates to a living, identifiable person. It can include
                        names, addresses, telephone numbers, email addresses etc but it is wider than
                        that and includes any other information relating to that person or a
                        combination of information which, if put together, means that the person can be
                        identified.</p>



                    <p>“<strong>Special Category data” </strong>means
                        personal date about a person’s race, ethnic origin, politics, religion, trade
                        union membership, genetics, biometrics (where used for ID purposes), health,
                        sex life or sexual orientation.</p>



                    <p>“<strong>Processing” </strong>&nbsp;&nbsp;covers all activities relating to the use of
                        personal date by an organization, from its collection through to its storage
                        and disposal and everything in between.</p>



                    <p><strong>&nbsp;“Data
                            subject”</strong> means the person whose personal
                        data is being processed.</p>



                    <p>“<strong>Consent”</strong>&nbsp; any freely given, specific,
                        informed and unambiguous indication of the data subject’s wishes by which he or
                        she, by a statement or by a clear affirmative action, signifies agreement to
                        the processing of personal data relating to him or her;</p>



                    <p>“<strong>Controller” </strong>&nbsp;the natural or legal person, public authority,
                        agency or other body which, alone or jointly with others, determines the
                        purposes and means of the processing of personal data; where the purposes and
                        means of such processing are determined by Union or Member State law, the
                        controller or the specific criteria for its nomination may be provided for by
                        Union or Member State law;</p>



                    <p>“<strong>Data handling” </strong>any set of operations or
                        operations performed in an automated or not automated manner on personal data
                        or files, thus collection, capture, systematization, distribution, storage,
                        transformation or alteration, query, introspection, use, communication,
                        forwarding, distribution or by any other means of making data available,
                        coordination or interconnection, restriction, deletion or destruction;</p>



                    <p>“<strong>Data controller” </strong>means a natural or
                        legal person, public authority, agency or other body which processes personal
                        data on behalf of the controller;</p>



                    <p>“<strong>Addressee”</strong> means a natural or legal
                        person, public authority, agency or another body, to which the personal data
                        are disclosed, whether a third party or not;</p>



                    <p>“<strong>Third party”</strong> means a natural or legal
                        person, public authority, agency or body other than the data subject,
                        controller, processor and persons who, under the direct authority of the controller
                        or processor, are authorised to process personal data;</p>



                    <p>“<strong>Profiling”</strong>&nbsp; any form of automated processing of personal
                        data consisting of the use of personal data to evaluate certain personal
                        aspects relating to a natural person, in particular to analyse or predict
                        aspects concerning that natural person’s performance at work, economic
                        situation, health, personal preferences, interests, reliability, behaviour,
                        location or movements and;</p>



                    <p>“<strong>Privacy data breach”</strong>&nbsp; means a breach of security leading to the
                        accidental or unlawful destruction, loss, alteration, unauthorised disclosure
                        of, or access to, personal data transmitted, stored or otherwise processed.</p>



                    <p><strong>RULES OF DATA HANDLING</strong></p>



                    <p>The Data
                        Controller recognizes the contents of this Data Management Guide as binding on
                        itself, and states, that all data management related to his/her own activity is
                        consistent and complies with the legal provisions as stated in the normative
                        European Union and domestic data protection laws and with the applicable
                        domestic sectoral laws, so especially:</p>



                    <ul>
                        <li>on the protection of natural persons with regard to the processing of
                            personal data and on the free movement of such data as well as about the
                            Regulation No. 2016/679 adopted by the European Parliament and the Council on
                            27 April 2016 about the repeal of 95/46/EK directive (hereinafter referred to as
                            “<strong>GDPR</strong>”
                            or “<strong>Regulation</strong>”)</li>
                        <li>act CXII of 2011 on the Right to Self-Determination of Information and
                            Freedom of Information;</li>
                        <li>Act C of 2000 on
                            Accounting; </li>
                        <li>Act CL of 2017 on
                            the Order of Taxation;</li>
                        <li>Act V of 2013 on the
                            Civil Code.</li>
                    </ul>



                    <p>Act XLVIII of
                        2008 on the Essential Conditions and Certain Limitations of Economic
                        Advertising Activities (hereinafter referred to as “<strong>Grt</strong>.”).</p>



                    <p>The Data
                        Controller defines his / her data management in such a way that it with the
                        <strong>principles</strong>
                        set out in the GDPR –
                        legality, fairness and transparency, purpose limitation, data saving and
                        accuracy, limited storage, integrity and confidentiality, as well as
                        accountability – complies fully.</p>



                    <p>Accordingly,
                        the Data Controller handles the collected, stored (managed) personal data
                        solely for the clear and legitimate purposes defined and described below in
                        subsequent paragraphs and only for the time stated. Thus, the subjects can read
                        in detail about each of the data management activities in the mentioned points.</p>



                    <p>The <strong>legal basis</strong> for data management of the
                        Data Controller is typically the consent of the data subject, the fullfillment
                        of the contract concluded with the data subject, its preparation and the
                        fulfillment of the legal obligation.</p>



                    <p>The consent-based
                        data management will only take place if the data subject has given a clear,
                        concrete, informed and unambiguous consent to the processing of personal data
                        concerning the natural person by a clear affirmative action, such as a written
                        declaration, including doing it in an electronic way. The consent of the data
                        subject can be specified by ticking the checkbox on the Website. Giving consent
                        also means that the data subject has read and understood this Privacy Policy
                        and contributes to the Data Controller’s data management based on the
                        information provided herein. The data subject is entitled to withdraw his
                        consent at any time. Withdrawal of consent does not affect the legality of the
                        consent-based data management prior to revocation.</p>



                    <p>The legal
                        basis for data management will be the fullfillment of the contract if the data
                        subject orders the service from the Company, for example through the Website,
                        i.e. a contractual relationship between the concerned (user) and the Company.</p>



                    <p>We’re talking
                        about statutory data management when a law obliges Data Controller to include
                        some data as well as the duration of data storage.</p>



                    <p>Data
                        Controller ensures data security and proper management. The Data Controller
                        ensures that the data is stored in a form that allows identification of data
                        subjects only for the time necessary to achieve the purposes for which personal
                        data are processed. He/she also takes the technical and organizational steps to
                        ensure that the data processed is adequately protected. Within this framework,
                        he/she will take reasonable measures to prevent unauthorized access, alteration
                        of use, transmission, disclosure, deletion or destruction, as well as
                        unavailability of accidental destruction and damage resulting from changes in
                        the technique used.</p>



                    <p>Therefore the
                        Data Controller’s staff shall ensure that unauthorized persons do not access
                        personal data and that the storage and placement of personal data is designed
                        in such a way that it is not accessible, accessible, alterable, destroyed, or
                        destroyed by an unauthorized person.</p>



                    <p>The Data
                        Controller undertakes that the personal data of the data subjects will only be
                        forwarded to the addressee (third party or data processor) who also handles the
                        personal data provided or transmitted to them in accordance with these
                        principles. The Company does not sell the data of the affected persons.</p>



                    <p>The users of
                        the Website are also responsible for the security of their data. For protecting
                        Your username and password, please be careful about the security of these data
                        and not give it to third parties.</p>



                    <p><strong>INFORMATION WE
                            COLLECT</strong></p>



                    <p>As part of the process of using <a href="http://www.sparav.com">www.sparav.com</a>&nbsp; and
                        <strong>SHIELDAPPS</strong>,
                        we require you to give us certain Personal Information which we need to provide
                        our products and Services to you. Without this information we may not be able
                        to fulfil our obligations with you. This information includes:</p>



                    <p>While providing our services we may collect the “Personal Information”
                        which is defined as any information that identifies or can be used to identify,
                        contact, or locate the person to whom such information pertains. Namely, we may
                        collect:</p>



                    <ul>
                        <li><strong>update
                                information in your control panel,</strong></li>
                        <li><strong>communicate
                                with us by telephone or email, order or</strong></li>
                        <li><strong>download
                                new Services,</strong></li>
                        <li><strong>register
                                a licence or change your device and</strong></li>
                        <li><strong>complete
                                forms on our website.</strong></li>
                    </ul>



                    <p>We also store information about the Services you purchase, such as the
                        activation code, date of purchase, and information relating to any support
                        issue.</p>



                    <p><strong>Automatic Information</strong>.&nbsp;We
                        may collect information about the device you access the Services from, such as
                        the IP address or other unique device identifiers. If you access your account
                        from a mobile device, that mobile device may also provide us with details of
                        your location. Most mobile devices allow you to disable this functionality.</p>



                    <p><strong>Contact information</strong>: email address, mailing address, phone number;</p>



                    <p><strong>Security information:</strong> alias, passwords;</p>



                    <p><strong>Financial and billing information</strong>: number of bank accounts and names of banking
                        institutions, identifiers in payment systems or other means of payment,
                        information on your balance accounts, your trade history, incoming and outgoing
                        wallet addresses;</p>



                    <p><strong>Cookies:</strong> to know more about cookies please acquaint yourself with Our
                        <strong>Cookie
                            Policy</strong> described below</p>



                    <p>You can correct
                        or remove this information by accessing your account settings.</p>



                    <p><strong>How We Use
                            Information</strong></p>



                    <p>We, our authorized partners and our representatives store and use Your
                        Personal Information only for providing and improving the website. In
                        particular, we use Users’ Personal Information for the following purposes:</p>



                    <p><strong>To improve our services to you and to provide
                            you with the Services requested:</strong> We run
                        the following processes to fulfil our obligations with you:</p>



                    <ul>
                        <li>Enabling you to
                            access the Site and create an account</li>
                        <li>Enabling you to
                            communicate with other users, and receive updates from <strong>SPARAV</strong><strong>
                            </strong>about your
                            account</li>
                        <li>Enabling payments</li>
                        <li>Providing support
                            to you when you use <strong>SPARAV</strong></li>
                    </ul>



                    <p><strong>To comply with our legal obligations:</strong> In many jurisdictions we are obliged to
                        collect
                        certain information about our Users to be authorized to act;</p>



                    <p>a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        It
                        is necessary for the performance of a contract to which you are a party, or to
                        take steps prior to entering into a contract with you, for us to provide you
                        with our products and services. Where we require information from you for these
                        purposes, we have set out above which information it is necessary for us to
                        process, without which we will be unable to provide you with our services.</p>



                    <p>b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        It is
                        necessary for the purposes of our legitimate interests, such as fraud
                        prevention, child protection, maintaining quality of service, error detection
                        and cyber security, except where our interests are overridden by the interests,
                        rights or freedoms of affected individuals (such as you). To determine this, we
                        shall consider a number of factors, such as what you were told at the time you
                        provided your data, what your expectations are about the processing of the
                        data, the nature of the data, and the impact of the processing on you. In order
                        to protect children from harassment and abuse, we video record and store
                        tutoring sessions.</p>



                    <p>c.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Where
                        we need to comply with a legal obligation; or in rare circumstances:</p>



                    <p>d.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Where
                        we need to protect your interests (or someone else’s interests); and/or</p>



                    <p>e&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Where
                        it is needed in the public interest or for official purposes</p>



                    <p><strong>To improve customer service</strong>: information provided by Users helps us respond to
                        customer service requests and support needs more efficiently;</p>



                    <p><strong>To support our legitimate interests in
                            developing the Site and the Services</strong>: These processes include:</p>



                    <ul>
                        <li>To aid the
                            technical administration of the Site;</li>
                        <li>To better
                            understand how the Site is functioning for Users to inform our product
                            development;</li>
                        <li>To draw
                            conclusions upon demographic information and;</li>
                        <li>Preventing fraud,
                            spam and abusive or inappropriate behavior and;</li>
                        <li>Marketing
                            activities to promote our services to registered users. These communications
                            may be personalized to you and your interactions with the Site and can be
                            delivered by email, social media advertising and other digital marketing
                            channels</li>
                        <li>Notifying you
                            about upcoming promotions, Referral Discount Codes, services or customer
                            surveys</li>
                        <li>Undertaking
                            impact reporting to understand the efficacy of our Services</li>
                        <li>Undertaking
                            research to better understand our customers interests</li>
                        <li>Analysing your
                            interactions to improve the efficiency of our marketing activity</li>
                    </ul>



                    <p>&nbsp;<strong>To personalize User experience</strong>: We may use information in a general
                        manner to understand how Our Users as a group use the services and resources
                        provided on our website;</p>



                    <p><strong>&nbsp;To send
                            periodic emails:</strong> We may use the email address
                        to send information and updates pertaining to your use of the transaction
                        request;</p>



                    <p><strong>To resolve disputes and enforce our agreements</strong> to the extent necessary and
                        sufficient
                        for protecting
                        your interests or interests of other Users.</p>



                    <p><strong>To deliver the products &amp; services</strong>: We may use your
                        information to contact you about <strong>SHIELDAPPS
                        </strong>updates, to provide informational &amp; service-related communications,
                        including important security updates.</p>



                    <p><strong>For Payments</strong>. We accept payments via <strong>PayPal, Visa and
                            Mastercard</strong>. When
                        processing payments, some of your data will be passed to this parties,
                        including information required to process or support the payment, such as the
                        purchase total and billing information.</p>



                    <p>Please see <strong>PayPal,
                            Visa and Mastercard</strong> Privacy policy for more details.</p>



                    <p>When you subscribe
                        to any of the <strong>SHIELDAPPS</strong> products, we will ask you to provide
                        information including your name, email address, phone number, credit
                        card/payment details and optional account information like username and password.</p>



                    <ul>
                        <li>We will use this information for
                            purposes, such as, to:</li>
                        <li>Send you information about your
                            account </li>
                        <li>Respond to your requests, and
                            complaints</li>
                        <li>Process payments and prevent fraud</li>
                        <li>Set up your account for our store</li>
                        <li>Comply with any legal obligations we
                            have, such as calculating taxes</li>
                        <li>Improve our store offerings</li>
                        <li>Send you marketing messages, if you
                            choose to receive them</li>
                    </ul>



                    <p><strong>HOW LONG WE RETAIN YOUR DATA</strong></p>



                    <p>If you leave a
                        comment, the comment and its metadata are retained indefinitely. This is so we
                        can recognize and approve any follow-up comments automatically instead of
                        holding them in a moderation queue.</p>



                    <p>For users that
                        register on our website, we also store the personal information they provide in
                        their user profile. All users can see, edit, or delete their personal
                        information at any time (except they cannot change their username). Website
                        administrators can also see and edit that information.</p>



                    <p>We will retain your information for as long as your account is active,
                        your information is needed to provide you services, or as required to fulfill
                        our legal obligations.</p>



                    <p>By contacting us at <a href="mailto:info@sparav.com">info@sparav.com</a>&nbsp; you can at
                        any time obtain the confirmation whether or not the personal data concerning
                        you is being processed.</p>



                    <p>We may retain records where necessary to fulfil our regulatory or
                        statutory duties.</p>



                    <p><strong>WHAT ARE YOUR RIGHTS TO YOUR DATA </strong></p>



                    <p>All Your Personal
                        Information we collect will always belong to you. However, we are a collector
                        and a processor of Your Personal Information. That implies on us obligations to
                        respect your rights to Personal Information and facilitate the exercise of your
                        rights thereto. In order to use any of your rights at any time please contact
                        us and we will facilitate the exercise of your rights free of charge. We will
                        inform you on the actions taken by us under your request as soon as practically
                        possible, but in any case, not later than in 30 (thirty) calendar days.</p>



                    <p>In accordance
                        with effective regulations you have a significant number of rights related to
                        your Personal Information, such as e.g.: </p>



                    <p><strong>Right to access.</strong> You may obtain from us the confirmation
                        as to whether or not personal data concerning you is being processed and get an
                        access to such personal data. You are entitled to view, amend, or delete the
                        personal information that we hold. Email your request to our data protection
                        office at <a href="mailto:info@sparav.com"><strong>info</strong>@sparav.com</a> and
                        we will work with you to remove any of your personal data we may have.</p>



                    <p><strong>Right to rectify</strong> your inaccurate Personal Information and
                        to have incomplete personal data completed, including by means of providing a
                        supplementary statement</p>



                    <p><strong>Right to erase</strong> your Personal Information. Please note
                        that a request to erase your Personal Information will also terminate your
                        account on the Site. We will automatically and without undue delay erase your
                        Personal Information when it is no longer necessary in relation to the purposes
                        for which it was collected or otherwise processed; </p>



                    <p><strong>Right to restrict</strong> processing of your Personal Information;
                    </p>



                    <p><strong>Right to data portability</strong>. You may obtain from us the
                        personal data concerning you and which you have provided to us and transmit it
                        to another Personal Information Controller;</p>



                    <p><strong>Right to object</strong> to processing of Your Personal
                        Information, </p>



                    <p><strong>Right to withdraw</strong> your consent to the usage of your
                        Personal Information at any time</p>



                    <p><strong>Right to lodge a complaint</strong>. We take privacy
                        concerns seriously. If you believe that we have not complied with this Privacy
                        Policy with respect to your Personal Information, you may contact our
                        respective Data Protection Office. We will investigate your complaint promptly
                        and will reply you within 30 (thirty) calendar days. If you do not receive
                        acknowledgment of your complaint or your complaint is not satisfactorily
                        addressed, you have the right to lodge a complaint with the respective
                        supervisory authority:</p>



                    <p>Office of the
                        Privacy Commissioner for Personal Data</p>



                    <p>https://www.pcpd.org.hk/english/complaints/how_complaint/complaint/complaint.html
                        or (for EU) Information Commissioner’s Office</p>



                    <p><a href="https://ico.org.uk/make-a-complaint/">https://ico.org.uk/make-a-complaint/</a> .</p>



                    <p><strong>COOKIES </strong></p>



                    <p><a href="http://www.sparav.com">www.sparav.com</a>&nbsp;&nbsp; may set cookies on your computer.
                        &nbsp;Cookies are used to provide our system with the basic information to provide
                        the services you are requesting. &nbsp;Cookies can be cleared at any time from
                        your internet browser settings.</p>



                    <p>If you leave a
                        comment on our site you may opt-in to save your name, email address and website
                        in cookies. These are for your convenience so that you do not have to fill in
                        your details again when you leave another comment. These cookies will last for
                        one year.</p>



                    <p>If you have an
                        account and you log in to this site, we will set a temporary cookie to
                        determine if your browser accepts cookies. This cookie contains no personal
                        data and is discarded when you close your browser.</p>



                    <p>When you log in,
                        we will also set up several cookies to save your login information and your
                        screen display choices. Login cookies last for two days, and screen options
                        cookies last for a year. If you select “Remember Me”, your login will persist
                        for two weeks. If you log out of your account, the login cookies will be
                        removed.</p>



                    <p>If you edit or
                        publish an article, an additional cookie will be saved in your browser. This
                        cookie includes no personal data and simply indicates the post ID of the
                        article you just edited. It expires after 1 day.</p>



                    <p><strong>GOOGLE ANALYTICS</strong></p>



                    <p>When someone
                        visits our website we use a third-party service, Google Analytics, to collect
                        standard internet log information and details of visitor behaviour patterns. We
                        do this to track things such as the number of visitors to the various parts of
                        the site and interactions with the site. This information is processed in a way
                        which does not identify anyone. We do not make and do not allow Google to make,
                        any attempt to find out the identities of visitors to our website.</p>



                    <p><strong>SECURING PRIVACY</strong></p>



                    <p>To transfer data between our websites, our applications and backends,
                        communication is encrypted using the SSL (Secure Socket Layer) encryption. We protect
                        the systems and processing by a series of technical and organizational
                        measures. These include data encryption, pseudonymization and anonymization,
                        logical and physical access restriction and control, firewalls and recovery
                        systems, and integrity testing. Our employees are regularly trained in the
                        sensitive handling of personal data and are obliged to observe data secrecy in
                        accordance with legal requirements.</p>



                    <p><strong>MINORS</strong></p>



                    <p>We do not knowingly gather or otherwise process personal data of minors
                        under the age of 16. If we notice that one of our users/visitors is a minor
                        we’ll immediately take steps to remove their information. If you believe we
                        have processed or still hold information on minors, please send us an email
                        at&nbsp;<a href="mailto:info@sparav.com">info@sparav.com</a> &nbsp;and we’ll remove it
                        A.S.A.P.&nbsp;
                    </p>



                    <p><strong>CHANGES IN THE PRIVACY
                            STATEMENT</strong><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </strong></p>



                    <p>The effective
                        date at the bottom of this page indicates when this Privacy Statement was last
                        revised. We will notify you before any material change takes effect so that you
                        have time to review the changes. Any change is effective when we post the
                        revised Privacy Statement. Your use of the Services following these changes
                        means that you accept the revised Privacy Statement.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--/ News single End /-->

<app-footer></app-footer>