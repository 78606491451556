import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login.service';
import { CookieService } from 'ngx-cookie-service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {

  public newPasswordError: boolean;
  public confirmPasswordError: boolean;
  public loading: boolean;

  public passwordForm = new FormGroup({
    newPassword: new FormControl(null),
    confirmPassword: new FormControl(null),
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    // scroll to top
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) { return; }
      window.scrollTo(0, 0);
    });

    this.setParams();
  }

  /**
   * set reset password token
   */
  setParams(): void {
    this.route.queryParams.subscribe(params => {

      // if no token found, lock this route
      if (!params.token) {
        this.router.navigate(['']);
      }

      this.cookieService.set('resetToken', params.token, 3600, '/');
      this.cookieService.set('resetEmail', params.email, 3600, '/');
    });
  }

  resetPassword(): void {
    const newPassword = this.passwordForm.value.newPassword;
    const confirmPassword = this.passwordForm.value.confirmPassword;

    if (this.loginService.inputfieldIsEmpty(newPassword)) { this.newPasswordError = true; }
    if (this.loginService.inputfieldIsEmpty(confirmPassword)) { this.confirmPasswordError = true; }

    if (
      !this.loginService.inputfieldIsEmpty(newPassword) &&
      !this.loginService.inputfieldIsEmpty(confirmPassword)
    ) {

      this.loading = true;

      if (newPassword !== confirmPassword) {
        this.newPasswordError = true;
        this.confirmPasswordError = true;
        this.loading = false;
        alert('Passwords do no match.');
        return;
      }

      this.loginService.resetPassword(this.cookieService.get('resetToken'), newPassword).then(res => {

        switch (res.response_code) {
          case 400:
            console.log('token not exist');
            alert('Could not change password. Try refresh your browser.');
            this.router.navigate(['']);
            break;
          case 200:
            alert('Your password has been changed');
            this.router.navigate(['']);
            break;
          default:
            console.error('Password did not change');
            alert('ERROR. Contact customer service.');
            this.router.navigate(['']);
            break;
        }

      });

    }

  }

}
