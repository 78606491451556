<app-header></app-header>

<!--/ Videos /-->
<section class="intro-single section-t8 section-b4 background-mural-01">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-between pb-4">
                    <div class="title-box">
                        <h2 class="title-a">How to videos</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <p class="mt-1">
                    Helping you understand the SPARAV Antivirus Protection Applications are a top priority
                    for us. The following videos are created to help you start, understand and use our services
                    correctly.
                </p>
            </div>
            <div class="col-md-4">
                <div class="text-md-right text-left">
                    <div class="card-box-ico">
                        <div class="d-inline-flex float-left mr-3"><b>Videos:</b></div> <b>16</b> in total
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <hr>
                <h4 class="mb-4 mt-4">Windows PC</h4>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for Windows PC</h5>
                    <br>
                    <img src="assets/img/svg/icon-windows-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>
                        In this video, we run through the Smart Scan-function from the SPARAV Antivirus Protection
                        Application for the Windows PC.
                    </p>
                    <hr>
                    <div class="mt-4">
                        <a href="" class="version-b" data-toggle="modal" data-target="#myModalVideoPopup">
                            Watch
                            <i class="fa fa-angle-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for Windows PC</h5>
                    <br>
                    <img src="assets/img/svg/icon-windows-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In this video, we run through the Web Security-function from the SPARAV Antivirus Protection
                        Application for the Windows PC.</p>
                    <hr>
                    <div class="disabled color-f" style="text-decoration: line-through;">
                        Coming soon
                        <i class="fa fa-angle-right"></i>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for Windows PC</h5>
                    <br>
                    <img src="assets/img/svg/icon-windows-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In this video, we run through the System Boost-function from the SPARAV Antivirus Protection
                        Application for the Windows PC.</p>
                    <hr>
                    <div class="disabled color-f" style="text-decoration: line-through;">
                        Coming soon
                        <i class="fa fa-angle-right"></i>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for Windows PC</h5>
                    <br>
                    <img src="assets/img/svg/icon-windows-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In this video, we run through the Disk Cleaner-function from the SPARAV Antivirus Protection
                        Application for the Windows PC.</p>
                    <hr>
                    <div class="disabled color-f" style="text-decoration: line-through;">
                        Coming soon
                        <i class="fa fa-angle-right"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <hr>
                <h4 class="mb-4 mt-4">Apple / Mac OSX</h4>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for Mac OSX</h5>
                    <br>
                    <img src="assets/img/svg/icon-mac-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In this video, we run through the SPARAV Antivirus Protection Application for Apple / Mac OSX.
                    </p>
                    <hr>
                    <div class="disabled color-f" style="text-decoration: line-through;">
                        Coming soon
                        <i class="fa fa-angle-right"></i>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for Mac OSX</h5>
                    <br>
                    <img src="assets/img/svg/icon-mac-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In this video, we run through the SPARAV Antivirus Protection Application for Apple / Mac OSX.
                    </p>
                    <hr>
                    <div class="disabled color-f" style="text-decoration: line-through;">
                        Coming soon
                        <i class="fa fa-angle-right"></i>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for Mac OSX</h5>
                    <br>
                    <img src="assets/img/svg/icon-mac-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In this video, we run through the SPARAV Antivirus Protection Application for Apple / Mac OSX.
                    </p>
                    <hr>
                    <div class="disabled color-f" style="text-decoration: line-through;">
                        Coming soon
                        <i class="fa fa-angle-right"></i>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for Mac OSX</h5>
                    <br>
                    <img src="assets/img/svg/icon-mac-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In this video, we run through the SPARAV Antivirus Protection Application for Apple / Mac OSX.
                    </p>
                    <hr>
                    <div class="disabled color-f" style="text-decoration: line-through;">
                        Coming soon
                        <i class="fa fa-angle-right"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <hr>
                <h4 class="mb-4 mt-4">Android</h4>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for Android</h5>
                    <br>
                    <img src="assets/img/svg/icon-android-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In this video, we run through the SPARAV Antivirus Protection App for Android phones.</p>
                    <hr>
                    <div class="mt-4">
                        <a href="" class="version-b">Watch <i class="fa fa-angle-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for Android</h5>
                    <br>
                    <img src="assets/img/svg/icon-android-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In this video, we run through the SPARAV Antivirus Protection App for Android phones.</p>
                    <hr>
                    <div class="disabled color-f" style="text-decoration: line-through;">Coming soon <i
                            class="fa fa-angle-right"></i></div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for Android</h5>
                    <br>
                    <img src="assets/img/svg/icon-android-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In this video, we run through the SPARAV Antivirus Protection App for Android phones.</p>
                    <hr>
                    <div class="disabled color-f" style="text-decoration: line-through;">Coming soon <i
                            class="fa fa-angle-right"></i></div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for Android</h5>
                    <br>
                    <img src="assets/img/svg/icon-android-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In this video, we run through the SPARAV Antivirus Protection App for Android phones.</p>
                    <hr>
                    <div class="disabled color-f" style="text-decoration: line-through;">Coming soon <i
                            class="fa fa-angle-right"></i></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <hr>
                <h4 class="mb-4 mt-4">iOS / iPhone</h4>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for iOS</h5>
                    <br>
                    <img src="assets/img/svg/icon-ios-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In this video, we run through the SPARAV Antivirus Protection App for iPhones.</p>
                    <hr>
                    <div class="disabled color-f" style="text-decoration: line-through;">Coming soon <i
                            class="fa fa-angle-right"></i></div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for iOS</h5>
                    <br>
                    <img src="assets/img/svg/icon-ios-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In this video, we run through the SPARAV Antivirus Protection App for iPhones.</p>
                    <hr>
                    <div class="disabled color-f" style="text-decoration: line-through;">Coming soon <i
                            class="fa fa-angle-right"></i></div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for iOS</h5>
                    <br>
                    <img src="assets/img/svg/icon-ios-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In this video, we run through the SPARAV Antivirus Protection App for iPhones.</p>
                    <hr>
                    <div class="disabled color-f" style="text-decoration: line-through;">Coming soon <i
                            class="fa fa-angle-right"></i></div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <h5><span class="color-a">SPARAV</span> for iOS</h5>
                    <br>
                    <img src="assets/img/svg/icon-ios-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In this video, we run through the SPARAV Antivirus Protection App for iPhones.</p>
                    <hr>
                    <div class="disabled color-f" style="text-decoration: line-through;">Coming soon <i
                            class="fa fa-angle-right"></i></div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--/ Videos End /-->

<app-footer></app-footer>