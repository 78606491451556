import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { DatePipe } from '@angular/common';

export class CustomerModel {

    // tslint:disable:variable-name
    private _firstname: string;
    private _lastname: string;
    private _email: string;
    private _prefix: string;
    private _phone: string;

    private _address: string;
    private _zipcode: string;
    private _country: string;
    private _created_at: string;

    constructor(
        firstName: string,
        lastName: string,
        email: string,
        prefix: string,
        phone: string,

        address: string,
        zipcode: string,
        country: string,
        created_at: string
    ) {
        this._firstname = firstName;
        this._lastname = lastName;
        this._email = email;
        this._prefix = prefix;
        this._phone = phone;

        this._address = address;
        this._zipcode = zipcode;
        this._country = country;
        this._created_at = created_at;
    }

    public get firstname(): string { return this._firstname; }
    public get lastname(): string { return this._lastname; }
    public get email(): string { return this._email; }
    public get prefix(): string { return this._prefix; }
    public get phone(): string { return this._phone; }

    public get address(): string { return this._address; }
    public get zipcode(): string { return this._zipcode; }
    public get country(): string { return this._country; }
    public get joined(): string { return this._created_at; }
}

// Converts: json to model || stringify json from storage to model
@Injectable({
    providedIn: 'root'
})
export class CustomerAdapter implements Adapter<CustomerModel> {
    constructor(private datePipe: DatePipe) { }
    adapt(response: any): CustomerModel {
        return new CustomerModel(
            response.firstname || response._firstname,
            response.lastname || response._lastname,
            response.email || response._email,
            response.prefix || response._prefix,
            response.phone || response._phone,
            response.address || response._address,
            response.zipcode || response._zipcode,
            response.country || response._country,
            this.datePipe.transform(response.created_at, 'dd/MM/yyyy') || response._created_at,
        );
    }
}
