import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private loadingScreen: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentLoadingScreen = this.loadingScreen.asObservable();

  private loginEndpoint = 'https://sparavuiwebsitecustomerapi.azurewebsites.net/api/v1/login';
  private tokenEndpoint = 'https://sparavuiwebsitecustomerapi.azurewebsites.net/api/v1/login/token';
  private sendPasswordEndpoint = 'https://sparavuiwebsitecustomerapi.azurewebsites.net/api/v1/login/password/sendresetpasswordlink';
  private resetPasswordEndpoint = 'https://sparavuiwebsitecustomerapi.azurewebsites.net/api/v1/login/password/reset';
  private resetTokenEndpoint = 'https://sparavuiwebsitecustomerapi.azurewebsites.net/api/v1/login/password/sendresettoken';

  // private loginEndpoint = 'http://localhost:8000/api/v1/login';
  // private tokenEndpoint = 'http://localhost:8000/api/v1/login/token';
  // private sendPasswordEndpoint = 'http://localhost:8000/api/v1/login/password/sendresetpasswordlink';
  // private resetPasswordEndpoint = 'http://localhost:8000/api/v1/login/password/reset';
  // private resetTokenEndpoint = 'http://localhost:8000/api/v1/login/password/sendresettoken';

  constructor(private http: HttpClient, private router: Router) { }

  async login(username: string, password: string): Promise<any> {
    const data = {
      username,
      password
    };

    return await this.http.post<any>(this.loginEndpoint, data)
      .toPromise()
      .catch((err) => {
        console.log(err);
      });
  }

  inputfieldIsEmpty(input: string): boolean {
    return (!input || input.length === 0);
  }

  async validateToken(token: string): Promise<any> {
    const data = { token };

    return await this.http.post<any>(this.tokenEndpoint, data)
      .toPromise()
      .catch((err) => {
        console.log(err);
      });
  }

  logout(): void {
    sessionStorage.removeItem('activeSession');
    sessionStorage.removeItem('info');
    sessionStorage.removeItem('ordersInfo');

    localStorage.removeItem('phone');
    localStorage.removeItem('loginToken');
    localStorage.removeItem('username');

    // hide loading screen
    this.loadingScreen.next(false);

    this.router.navigate(['/']);
  }

  /**
   * get reset token for updating password
   * @param username customer email
   */
  async getResetToken(username: string): Promise<any> {
    const data = { username };

    return await this.http.post<any>(this.resetTokenEndpoint, data)
      .toPromise()
      .catch((err) => {
        console.log(err);
      });
  }

  /**
   * send reset password link to customer email
   * @param username email
   */
  async sendPassword(username: string): Promise<any> {
    const data = { username };

    return await this.http.post<any>(this.sendPasswordEndpoint, data)
      .toPromise()
      .catch((err) => {
        console.log(err);
      });
  }

  /**
   * reset customer password if resetToken is valid
   * @param token reset token
   * @param newPassword new password
   */
  async resetPassword(token: string, newPassword: string): Promise<any> {
    const data = {
      token,
      new_password: newPassword
    };

    return await this.http.post<any>(this.resetPasswordEndpoint, data)
      .toPromise()
      .catch((err) => {
        console.log(err);
      });
  }
}
