<app-header></app-header>

<!--/ News single /-->
<section class="intro-single section-t8 section-b4 background-mural-01">
    <div class="container">

        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-between pb-4">
                    <div class="title-box">
                        <h2 class="title-a">Terms</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 mt-4">
                <p class="mt-1">
                    <a href="http://sparav.com/"><i class="fa fa-angle-left"></i> Back to front page</a>
                </p>
            </div>
            <div class="col-md-4">
                <div class="text-md-right text-left">
                    <div class="card-box-ico">
                        <div class="d-inline-flex float-left mr-3"><b>Other:</b></div>
                        <a routerLink="/privacy-policy" class="">Privacy <i class="fa fa-angle-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card-box-ico pb-4">
                    <h2 class="mb-4">Terms & Conditions</h2>

                    <p>
                        These Terms and Conditions (the "Agreement") are an agreement between SPARAV APS trading as
                        DEFSECURE TECHNOLOGIES APS (collectively, "SPARAV", "we" or "our") and you ("you," "yourself,"
                        "User" or "Customer"). This Agreement sets forth the terms and conditions of your use of all
                        Defsecure Technologies products and services (collectively, "SPARAV" or the "Services"). Within
                        the
                        scope of such Services, this Agreement refers to both (i) your service plan; and (ii) any
                        additional
                        services you order to supplement your service plan, as further described at Our Services &
                        Prices.
                        If you do not accept and agree to these Terms and Conditions and our Policies then you must not
                        access or use the Services.
                        <br><br>
                        PLEASE ENDEAVOUR TO READ THIS TERMS AND CONDITIONS AND PRIVACY POLICY ON THE BOTTOM PAGE OF <a
                            href="sparav.com">www.sparav.com</a> ("the website or this website or Site").
                        <br><br>
                        BY ACCESSING, DOWNLOADING, INSTALLING AND/OR USING "SPARAV", YOU ACKNOWLEDGE THAT YOU HAVE READ
                        THESE TERMS AND AGREE TO BE BOUND BY THE TERMS AND CONDITIONS.
                        <br><br>
                        By accepting these Terms, you affirm that you are: (i) lawfully entitled to use the Software
                        and/or
                        Website in the country in which you are located or reside, and (ii) of legal age to form a
                        binding
                        agreement with us. If you are a minor according to the law of the country you reside in, you are
                        not
                        permitted to use "SPARAV". ALL PERSONS UNDER THE AGE OF 18 ARE DENIED ACCESS TO THIS WEBSITE. IF
                        YOU
                        ARE UNDER 18 YEARS OF AGE, IT IS UNLAWFUL FOR YOU TO VISIT, READ, OR INTERACT WITH THIS WEBSITE
                        OR
                        ITS CONTENTS IN ANY MANNER. THIS WEBSITE SPECIFICALLY DENIES ACCESS TO ANY INDIVIDUAL THAT IS
                        COVERED BY THE CHILD ONLINE PRIVACY ACT (COPA) OF 1998. If you do not accept these Terms in its
                        entirety, then you may not access, download, install or use SPARAV (as applicable).
                        <br><br>
                        We reserve the right, at our sole discretion and without prior notice, to modify, change and/or
                        update these Terms at any time, and/or change, modify, or discontinue providing the services
                        including without limitation any SPARAV feature. If we update these Terms, we will post the
                        updated
                        Terms on the Website, and if the update is material, we will ask for your consent to it, or
                        provide
                        you with a notice via email. We will also update the "Last updated on" date above. Please check
                        these Terms periodically for changes. Your continued use of the services following any changes
                        to
                        these Terms or SPARAV, constitutes your complete and irrevocable acceptance of any and all such
                        changes. If any modification to SPARAV and/or these Terms is not acceptable to you, your only
                        recourse is to cease using the application and uninstall the Software.
                    </p>

                    <hr>

                    <p>
                        <strong>1. USAGE</strong>
                        <br><br>
                        You will use this site in a manner consistent with any, and all, applicable laws, legislation,
                        rules
                        and regulations. If you violate any restrictions in these terms, you agree to indemnify SPARAV
                        for
                        any losses, costs or damages, including reasonable legal fees, incurred by SPARAV in relation
                        to, or
                        arising out of, such a breach.
                    </p>

                    <hr>

                    <p>
                        <strong>2. ABOUT SPARAV</strong>
                        <br><br>
                        2.1 Defsecure Technologies is a Danish software technology Corporation established to focus on
                        delivering quality and affordable technology globally. Defsecure Technologies has developed
                        "SPARAV
                        products" which will provide you with completely hands-free protection continuously and
                        flawlessly
                        on a daily basis.
                        <br><br>
                        2.2 The SPARAV family of products includes but is not limited to:
                        <br><br>
                        2.2.1 Personal Computer Products: Antivirus, Anti Malware, Ransomware Defender, Cyber Privacy
                        Suite,
                        Internet Security Suite, PC Privacy Shield, PC Cleaning Utility, PC Registry Shield, Identity
                        Theft
                        Preventer, Password Shield, Hard Disk Shield, Webcam Blocker, Password Recovery Shield, VPN
                        <br><br>
                        2.2.2 Mobile Device Products: Camera & Microphone Blocker, Identity Theft Preventer, Ransomware
                        Defender, Microphone Blocker, Anti Malware, Camera Blocker, Mobile Cleaning Utility, Microphone
                        &
                        Camera Blocker, Privacy Shield, VPN
                    </p>

                    <hr>

                    <p>
                        <strong>3. APPLICABILITY</strong>
                        <br><br>
                        "These general terms and conditions (the "Conditions") apply to:
                        <br><br>
                        (a) The use of any information, pictures, documents and/or other services offered by SPARAV via
                        <a href="sparav.com">www.sparav.com</a> (our "Website");
                        <br><br>
                        (b) The use, pricing/purchase and downloads of "SPARAV" from <a
                            href="sparav.com">www.sparav.com</a>.
                    </p>

                    <hr>

                    <p>
                        <strong>4. ELIGIBILITY, REGISTRATION AND ACCOUNT SECURITY</strong>
                        <br><br>
                        4.1 The Services are intended solely for users who are eighteen (18) years of age or older (or
                        over
                        the age of majority in your country if it is higher than 18). If you are under eighteen (18)
                        years
                        of age (or, if higher than 18, the age of majority in your country) and register to use the
                        Services
                        or access the Services, we do not agree to provide the Services to you and you must cancel your
                        account and stop using the Services. By registering for or using the Services, you represent and
                        warrant that you are at least eighteen (18) years of age and are over the age of majority in
                        your
                        country of residence.
                        <br><br>
                        4.2 If you are registering for and using the Services on behalf of another party, entity or
                        organization, you warrant and represent that you are authorized to bind such party, entity or
                        organization to this Agreement and to act on behalf of such party, entity or organization with
                        respect to any actions you take in connection with the Services.
                        <br><br>
                        4.3 You agree to provide accurate and complete information when you register for the Services
                        and
                        you agree to keep such information accurate and complete during the entire time that you use the
                        Services. You are solely responsible for maintaining the confidentiality of your username and
                        password.
                    </p>

                    <hr>

                    <p>
                        <strong>5. LICENSE</strong>
                        <br><br>
                        Subject to your compliance with the terms and condition of these Terms, you are hereby granted a
                        limited, personal, non-exclusive, non-transferable, non-sublicensable, non-assignable, license
                        to
                        use the SPARAV website available at <a href="sparav.com">www.sparav.com</a> (the "Website"), and
                        to
                        download and install one copy of SPARAV on a personal computer, or other compatible device. You
                        may
                        transfer the license to the Software granted to you hereunder to a different device if you
                        permanently uninstall and remove the Software from the original device you installed it on prior
                        to
                        installing it on another device. For avoidance of any doubt, you must have a license to the
                        Software
                        on each device on which you operate the Software. All rights not expressly granted to you
                        hereunder
                        are retained by us.
                    </p>

                    <hr>

                    <p>
                        <strong>6. LICENSE RESTRICTIONS</strong>
                        <br><br>
                        Except as expressly provided herein, you may not, and may not permit any third party to: (i) use
                        and/or disclose to any third party for any reason any license number, or other authorization
                        code or
                        number supplied by us in connection with SPARAV on or for more than the number of devices
                        specified
                        by us; (ii) copy, change, modify, translate, reverse engineer, decompile, disassemble,
                        reconstruct,
                        transform, create derivative works based on SPARAV, extract any part of or otherwise alter
                        SPARAV
                        (iv) share, transfer, publish, resell, broadcast, transmit, communicate, pledge, sublicense the
                        Software, or permit other individuals/entities to use the Software, rent, lease, distribute or
                        transfer the Software or your rights to use it to any other individual or entity; (v) make the
                        functionality of the Software available to any individual or entity through any means, including
                        but
                        not limited to uploading the Software to a network or file-sharing service,
                        software-as-a-service
                        (SaaS), or any other type of services; (vi) extract or harvest any information from SPARAV or
                        any
                        part thereof; (vii) delete or modify any attributions, trademarks, copyright, legal notices or
                        other
                        proprietary designations or markings which are part of SPARAV; (ix ) use SPARAV in any unlawful
                        manner, for any unlawful purpose, or in any manner inconsistent with the terms and conditions of
                        these Terms; or (x) gain or attempt to gain unauthorized access to SPARAV or to networks
                        connected
                        to it by any means, including without limitation by hacking, spoofing or circumventing SPARAV or
                        the
                        security features therein; (xi) use or attempt to use the Software by itself, or in conjunction
                        with
                        any other products, or upload, store or transmit any data, information or materials which
                        infringe
                        upon any third party’s rights, including without limitation third party’s intellectual property
                        rights or any unlawful, harmful, threatening, abusive, defamatory or otherwise objectionable or
                        to
                        invade users’ privacy in any way, or to track, store, transmit or record personal information
                        about
                        any other user of the Software; or otherwise in any way damage, disable, impair, interfere,
                        disrupt
                        or harm us or SPARAV in any way.(xii) use SPARAV to provide or build a product or service that
                        competes with the Software; (xiii) test or benchmark, or disclose or publish testing or
                        benchmark
                        results, for the Software without our prior written consent.
                    </p>

                    <hr>

                    <p>
                        <strong>7. SOFTWARE UPDATES</strong>
                        <br><br>
                        We may update the Software from time to time, at our sole discretion. The update may be in the
                        form
                        of adding new features, bug fixing and new versions of the Software. In order to provide you
                        with
                        the most current version of the Software, you agree that new updates and versions of the
                        Software
                        may download and install automatically as they are made available by us, in our sole discretion.
                        You
                        agree to receive and permit us to deliver such new updates and versions to you. If you are using
                        the
                        Free Version not all of these updates may not be available to you. If you are using the Paid
                        Version
                        you have the right to receive all new features to and versions of the Software as we, in our
                        sole
                        discretion, makes available during your Subscription Period (as defined below). These updates
                        and
                        new features may include additional terms that you will have to agree to. Certain features and
                        components of the Software are updated from time to time, these include without limitation, the
                        following: software and products that are identified by the Software as threats including
                        without
                        limitation, malware (collectively, "Protection Updates"). You shall have the right to receive
                        Protection Updates for the Software while you are using the Software whether as a Free Version
                        user
                        or a Paid Version user.
                    </p>

                    <hr>

                    <p>
                        <strong>8. SUBSCRIPTION, TERM AND AUTOMATIC RENEWAL</strong>
                        <br><br>
                        8.1 Term.
                        <br><br>
                        8.1.1 Free Use Period; If you have obtained a license to use the Free Version of the Software,
                        then
                        these Terms and the license granted hereunder are valid as of the date in which you installed
                        the
                        Software and until you uninstall the Software from your device.
                        <br><br>
                        8.1.2 Paid Subscription Period. If you have purchased a license to use the Pro Version of the
                        Software, then these Terms and the license granted hereunder are valid as of the Activation Date
                        and
                        until the expiration of the Subscription Period. For purposes hereof, the term, "Activation
                        Date"
                        means the date in which you insert the license key into the Software (the license key will be
                        provided to you once your payment is made by email), and the term "Subscription Period" means
                        the
                        period as of the Activation Date through and until the expiration of the then current
                        Subscription
                        (which may be part of a onetime payment, ongoing yearly separate payments or recurring payment).
                        Upon the expiration of the Subscription Period, you will no longer be able to use the Paid
                        Version
                        of the Software.
                        <br><br>
                        8.2 Monthly Subscriptions. All monthly subscriptions will renew automatically each month until
                        you
                        cancel your account.
                        <br><br>
                        8.3 Yearly Subscriptions. All yearly subscriptions will renew automatically each year until you
                        cancel your account.
                        <br><br>
                        Discount Promotions. If you receive a discount promotion on your Initial Term or first month’s
                        subscription, your Services will automatically renew at the then current non-discounted price
                        listed
                        on our pricing page. You acknowledge, agree, and authorize SPARAV to automatically charge the
                        applicable fee to your credit card in accordance with our pricing terms indicated at the top
                        right
                        corner on <a href="sparav.com">www.sparav.com</a>.
                        8.4 Exceptions to Automatic Renewal. If you are in dispute with SPARAV or have previously
                        requested
                        a chargeback that we have successfully challenged, your Services will not automatically renew at
                        the
                        end of your existing service term.
                    </p>

                    <hr>

                    <p>
                        <strong>9. PAYMENT AND PRICING</strong>
                        <br><br>
                        9.1 Pricing. We offer great service plans and a range of additional services to meet everyone’s
                        needs. Details of our current service plans, and additional devices, are listed on Our Pricing
                        page.
                        <br><br>
                        9.2 Payments. You can purchase a license to receive the Paid Version of the Software by using
                        the
                        third-party payment service providers offered by us through our Website. We provide the
                        following
                        subscription models: Monthly, semi-annually or annually. In addition, we provide a family
                        sharing
                        plan whereas one (1) license key can be purchased for use on three (3) devices.
                        <br><br>
                        You are responsible for ensuring that your billing information with SPARAV is accurate.
                        <br><br>
                        9.3 Currency. SPARAV offers the ability to pay for Services in a number of different currencies.
                        If
                        your local currency is not supported by your account, the price payable for Services will be
                        converted, using the applicable exchange rate, at the time of purchase. In the event of any
                        refund
                        of fees being due to you in accordance with the terms of this Agreement, such refund will be
                        converted using the applicable exchange rate at the time of refund. SPARAV is not liable for any
                        discrepancies or shortfalls in these amounts as a result of a change in the exchange rate.
                        <br><br>
                        9.4 Billing Issues and Support. You must notify us about any billing problems or discrepancies
                        as
                        soon as you become aware of them. We will make every effort to assist you with any billing
                        queries,
                        please contact us by clicking on the "contact us" icon at the bottom of our webpage.
                    </p>

                    <hr>

                    <p>
                        <strong>10. TERMINATION, CANCELLATION & REFUND</strong>
                        <br><br>
                        10.1 Termination by you. You may terminate these Terms and the license granted to you hereunder
                        at
                        any time by uninstalling and removing the Software from your device, and by ceasing to use the
                        Website. You may choose to terminate immediately or choose to not renew at the end of your Term.
                        You can terminate and cancel the Services for any reason within thirty (30) days even if you
                        have
                        started to download the SPARAV Software.
                        If you do choose to cancel your account within thirty (30) days of registering or renewing your
                        Services and additional services, you will receive a full refund of the payment made. The
                        money-back-guarantee refund shall only accrue and be due to you upon your compliance with, and
                        subject in all respects to, the terms and conditions of this section unless such refund is
                        mandated
                        by local law.
                        <br><br>
                        To request a refund, please contact our billing team at <a
                            href="mailto:help@sparav.com">help@sparav.com</a>.
                        <br><br>
                        10.2 Termination by us. Without prejudice to any other rights we may have, these Terms and the
                        license granted to you hereunder automatically terminate without notice, if you fail to comply
                        with
                        or breach any provision of these Terms. In no event will we be liable for the suspension,
                        removal of
                        or disabling of your access to SPARAV or to any feature available therein. If you are using the
                        Free
                        Version of the Software, we may terminate these Terms at any time with or without prior notice.
                        Without limiting the foregoing, if you using the Paid Version of the Software and fail to pay
                        the
                        applicable Subscription, your license to use the Paid Version of the Software will end
                        automatically. You acknowledge that upon expiration or termination of your license, the license
                        key
                        may automatically de-activate.
                        <br><br>
                        10.3 Effects of Termination. Nothing herein entitles you to a refund of any payment made
                        hereunder.
                    </p>

                    <hr>

                    <p>
                        <strong>11. EXCLUSION OF LIABILITY FOR EXTERNAL LINKS</strong>
                        <br><br>
                        The Website may provide links to external Internet sites. SPARAV hereby declares explicitly that
                        it
                        has no influence on the layout or content of the linked pages and dissociates itself expressly
                        from
                        all contents of all linked pages of third parties. SPARAV shall not be liable for the use or
                        content
                        of Internet sites that link to this site or which are linked from it. Our privacy and cookie
                        notice
                        do not apply to any collection and processing of your personal data on or through such external
                        sites.
                    </p>

                    <hr>

                    <p>
                        <strong>12. OWNERSHIP; INTELLECTUAL PROPERTY RIGHTS</strong>
                        <br><br>
                        All right, title and interest in and to SPARAV, any related features and/or services and any
                        derivatives thereof improvements and modifications thereto, including associated intellectual
                        property rights, evidenced by or embodied in and/or attached/connected/related to SPARAV or any
                        related features and/or services, are and will remain owned solely by us or our licensors. These
                        Terms do not convey to you an interest in or to SPARAV, but only a limited right of use in
                        accordance with the terms herein. Nothing in these Terms constitutes a waiver of our
                        intellectual
                        property rights under any law. The license granted to you herein is neither contingent on the
                        delivery of any future functionality or features nor dependent on any oral or written public
                        comments made by us regarding future functionality or features. You acknowledge and agree that
                        the
                        technology manifested in the operation of the Software constitutes our and our suppliers’
                        valuable
                        trade secrets and know-how and to the extent you discover any such trade secrets, you will not
                        disclose them to any third party. Any disclosure or unauthorized use thereof will cause us
                        irreparable harm and loss.
                    </p>

                    <hr>

                    <p>
                        <strong>13. YOUR REPRESENTATIONS AND WARRANTIES</strong>
                        <br><br>
                        You hereby represent and warrant that: (i) you will only use SPARAV as permitted under these
                        Terms;
                        (ii) you agree to comply with all applicable laws, rules and regulations, and industry best
                        practices while using SPARAV; (iii) you will not use SPARAV for any fraudulent or inappropriate
                        purpose; (iv) you shall not prevent others from using SPARAV.
                    </p>

                    <hr>

                    <p>
                        <strong>14. PRIVACY</strong>
                        <br><br>
                        SPARAV’s Privacy Policy available here at <a
                            href="sparav.com/privacy-policy">www.sparav.com/privacy-policy</a> sets forth the data and
                        information that may be accessed, collected, used and/or shared by us from data and information
                        that
                        is generated by you in connection with your use of SPARAV. If you believe that your privacy
                        right
                        has been violated while using SPARAV, please contact us: <a
                            href="mailto:support@sparav.com">support@sparav.com</a>.
                    </p>

                    <hr>

                    <p>
                        <strong>15. DISCLAIMER OF WARRANTIES</strong>
                        <br><br>
                        SPARAV IS PROVIDED TO YOU "AS IS", WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED
                        BY
                        APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED
                        WARRANTIES OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT AND ANY WARRANTIES AND
                        CONDITIONS ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. WE DO NOT REPRESENT OR WARRANT
                        THAT:
                        (I) SPARAV WILL MEET YOUR REQUIREMENTS, WILL BE ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED;
                        (II) THE OPERATION OF SPARAV WILL BE UNINTERRUPTED; OR (III) SPARAV IS OR WILL BE AVAILABLE
                        WHERE
                        YOU RESIDE OR IN ANY OTHER PARTICULAR LOCATION. YOUR ONLY RIGHT OR REMEDY WITH RESPECT TO ANY
                        PROBLEMS OR DISSATISFACTION WITH SPARAV IS TO UNINSTALL AND CEASE USE OF ALL SPARAV PRODUCTS.
                        Further and except as expressly provided herein, we are not obligated to maintain or support
                        SPARAV,
                        or to provide you with any updates, fix errors or any other features available therein. You
                        acknowledge and agree that you are solely responsible for (and that we have no responsibility to
                        you
                        or to any third party) and assume all the responsibility and risk for your use of SPARAV and
                        your
                        breach of any of your representations and warranties herein contained, and for any loss or
                        damage
                        which we may suffer as a result of any such breach.
                    </p>

                    <hr>

                    <p>
                        <strong>16. LIMITATION OF LIABILITY</strong>
                        <br><br>
                        TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, UNDER NO CIRCUMSTANCES SHALL WE, OUR OFFICERS,
                        DIRECTORS, EMPLOYEES, PARENTS, AFFILIATES, SUCCESSORS, ASSIGNS, OR LICENSORS BE LIABLE TO YOU OR
                        ANY
                        OTHER THIRD PARTY FOR ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL, EXEMPLARY OR PUNITIVE
                        DAMAGES OF ANY TYPE INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF GOODWILL, SERVICE
                        INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, LOSS OF BUSINESS PROFITS, LOSS OF DATA OR
                        BUSINESS
                        INFORMATION, LOSS OF ADDITIONAL SOFTWARE OR COMPUTER CONFIGURATIONS OR COSTS OF PROCUREMENT OF
                        SUBSTITUTE GOODS OR SERVICES, DAMAGES ARISING IN CONNECTION WITH ANY USE OF SPARAV OR ANY AND
                        ALL
                        OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR THE
                        INSTALLATION, UNINSTALLATION, USE OF OR INABILITY TO USE SPARAV UNDER ANY THEORY OF LIABILITY,
                        INCLUDING BUT NOT LIMITED TO CONTRACT OR TORT (INCLUDING PRODUCTS LIABILITY, STRICT LIABILITY
                        AND
                        NEGLIGENCE), AND WHETHER OR NOT WE WERE OR SHOULD HAVE BEEN AWARE OR ADVISED OF THE POSSIBILITY
                        OF
                        SUCH DAMAGE AND NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY STATED
                        HEREIN. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL OUR TOTAL
                        LIABILITY (INCLUDING OUR OFFICERS, DIRECTORS, EMPLOYEES, PARENTS, AND AFFILIATES) FOR ANY CLAIM
                        ARISING OUT OF OR RELATED TO THESE TERMS, TO THE FULLEST EXTENT POSSIBLE UNDER APPLICABLE LAW,
                        EXCEED THE AMOUNT PAID IF ANY, BY YOU FOR THE USE OF SPARAV.
                    </p>

                    <hr>

                    <p>
                        <strong>17. INDEMNITY</strong>
                        <br><br>
                        You agree to defend, indemnify and hold us, our parent corporation, officers, directors,
                        employees
                        and agents, harmless from and against any and all claims, damages, obligations, losses,
                        liabilities,
                        costs and expenses (including but not limited to attorney’s fees) arising from: (i) your access
                        to
                        or use of SPARAV; (ii) your violation of these Terms; or (iii) your violation of any third party
                        right, including without limitation, any intellectual property right, or privacy right.
                    </p>

                    <hr>

                    <p>
                        <strong>18. GOVERNING LAW AND DISPUTES</strong>
                        <br><br>
                        This Agreement will be governed by, construed and enforced in accordance with the laws of
                        Copenhagen, without regard to its conflicts of law principles or provisions. The parties
                        specifically exclude from application to this Agreement the United Nations Convention on
                        Contracts
                        for the International Sale of Goods and the Uniform Computer Information Transactions Act. Any
                        disputes arising out of or in connection with this Agreement shall be exclusively settled under
                        the
                        Rules of Arbitration of the International Chamber of Commerce (the "ICC Rules") by one
                        arbitrator
                        appointed in accordance with the ICC Rules (the "Arbitrator"). The arbitration shall take place
                        in
                        Copenhagen and shall be conducted in the English Language. The arbitration shall be conducted on
                        a
                        confidential basis. The award passed by the Arbitrator shall be final and binding on both
                        parties.
                        Nothing contained herein shall prevent either party from applying to any court of law in order
                        to
                        obtain injunctions, equitable relief or any equivalent remedy, against the other Party, in order
                        to
                        restrain the breach of any restrictive covenants pursuant to this Agreement. The arbitration
                        award
                        shall be enforceable in any court of competent jurisdiction. Any motion to enforce or vacate an
                        arbitration award under this agreement shall be kept confidential to the maximum extent
                        possible.
                        Accordingly, for any claim that you have with us, you agree, prior to filing any suit or
                        proceeding,
                        to first contact us and attempt to resolve the claim informally by sending us a written notice
                        of
                        your claim ("Notice"). If you and we cannot reach an agreement to resolve the claim within 30
                        days
                        after the Notice is received, then either party may file a claim in court. Notwithstanding the
                        foregoing, we may seek injunctive relief in any court of competent jurisdiction. YOU AGREE THAT
                        ANY
                        CAUSE OF ACTION THAT YOU MAY HAVE ARISING OUT OF OR RELATED TO SPARAV OR THESE TERMS MUST
                        COMMENCE
                        WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS
                        PERMANENTLY BARRED.
                    </p>

                    <hr>

                    <p>
                        <strong>19. GENERAL</strong>
                        <br><br>
                        These Terms constitutes the entire understanding between the parties with respect to the matters
                        referred to herein. The Section headings in these Terms are provided for convenience purpose
                        only
                        and have no legal or contractual significance. If any provision of these Terms is held to be
                        unenforceable by a court of competent jurisdiction, such provision shall be enforced to the
                        maximum
                        extent permissible so as to affect the intent of the parties, and the remainder of these Terms
                        shall
                        continue in full force and effect. Our failure to enforce any rights or to take action against
                        you
                        in the event of any breach hereunder shall not be deemed a waiver of such rights or of
                        subsequent
                        actions in the event of future breaches. These Terms and any right granted herein may not be
                        assigned by you without our prior written consent. The controlling language of these Terms is
                        English. In the event of inconsistency or discrepancy between the English version and any other
                        language version, the English language version shall prevail. Nothing in these Terms will be
                        construed as creating a joint venture, partnership, employment or agency relationship between
                        you
                        and us, and you do not have any authority to create any obligation or make any representation on
                        our
                        behalf.
                    </p>

                    <hr>

                    <p>
                        <strong>20. CONTACT US</strong>
                        <br><br>
                        If you have any questions or comments concerning these Terms, you are most welcomed to contact
                        us at
                        <a href="mailto:help@sparav.com">help@sparav.com</a> and we will make an effort to reply within
                        a
                        reasonable time-frame.
                    </p>

                </div>
            </div>
        </div>
    </div>
</section>
<!--/ News single End /-->

<app-footer></app-footer>