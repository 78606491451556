<!--/ Footer /-->
<footer>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <div class="copyright-footer">
                    <p class="copyright small">
                        Copyright &copy;
                        <script>document.write(new Date().getFullYear());</script> &#8212;
                        <a href="https://sparav.com" class="color-a">SPARAV</a> - Protecting your devices
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="copyright-footer">
                    <p class="copyright small">
                        <a routerLink="/privacy-policy">Privacy</a> &#8212; <a routerLink="/terms-conditions">Terms</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--/ Footer End /-->