import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { CustomerAdapter } from 'src/app/shared/models/customer.model';
import { browserRefresh } from '../../app.component';
import { DevicesComponent } from '../devices/devices.component';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {

  // interpolations
  isActivePlan: boolean;
  firstname: string;
  maxDevices: string;

  constructor(
    private router: Router,
    private customerService: CustomerService,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) { return; }
      window.scrollTo(0, 0);
    });

    this.customerService.fetchCustomerInfo().subscribe(customer => {
      // todo: undefined customer, create new one first time update
      if (customer) {
        this.firstname = customer.firstname;
      }
    });

    this.setMaxDevices();

    this.subscriptionService.activeSubscriptions(localStorage.getItem('loginToken')).subscribe(subs => {
      if (subs.length === 0) {
        this.isActivePlan = false;
        return;
      }
      this.isActivePlan = true;
    });
  }

  setMaxDevices(): void {
    this.customerService.fetchOrdersInfo().subscribe(ordersArray => {
      for (const order of ordersArray) {
        // if customer has unlimited device addon
        if (order.$product_id === '3' && order.$active === '1') {
          this.maxDevices = 'unlimited';
        }
      }
    });
  }

}
