import { Component, OnInit } from '@angular/core';
import {SubscriptionService} from "../../../../shared/services/subscription.service";

@Component({
  selector: 'app-cancelreason',
  templateUrl: './cancelreason.component.html',
  styleUrls: ['./cancelreason.component.css']
})
export class CancelreasonComponent implements OnInit {

  public loading = false;

  constructor(private subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
  }

  public cancelSubscription() {
    let subscription_ids = JSON.parse(localStorage.getItem("subscriptionProductsToCancel"));
    this.loading = true;
    this.subscriptionService.cancelSubscription(subscription_ids).then(res => {
      console.log(res);
      alert('Your chosen subscriptions has been canceled.');
      window.location.href = '/login';
    }).catch(error => {
      alert('Something went wrong. Please contact help@sparav.com.')
    });
  }

}
