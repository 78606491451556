<!--/ Login /-->
<section class="intro-single background-mural-01" style="height:100vh;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 text-center">
                <!-- TODO: go back to front page? -->
                <!-- <a href="http://sparav.com/"> -->
                <img src="assets/img/svg/sparav-logo.svg" width="240px" class="mb-5">
                <!-- </a> -->
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="card-box-ico">
                    <div class="text-center">
                        <h2><i class="fa fa-lock"></i> Login</h2>
                        <p>Use your email to login below</p>
                    </div>
                    <hr>
                    <form class="form-a" action="" method="post" role="form" [formGroup]="loginForm">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <div class="form-group">
                                    <label for="inputEmail"><b>Email *</b></label>
                                    <input name="email" type="email" class="form-control form-control-lg form-control-a"
                                        placeholder="Enter your email" data-msg="Please enter your email"
                                        formControlName="email" [ngClass]="{'errorBorder': emailError}"
                                        (focus)="emailError = false;">
                                    <div class="validation"></div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="form-group">
                                    <label for="inputPassword"><b>Password *</b></label>
                                    <input id="password-field" [type]="hidePassword ? 'password' : 'text'"
                                        name="password" class="form-control form-control-lg form-control-a"
                                        placeholder="Enter your password" data-msg="Please enter your password"
                                        formControlName="password" [ngClass]="{'errorBorder': passwordError}"
                                        (focus)="passwordError = false;">
                                    <span toggle="#password-field" class="fa fa-fw field-icon toggle-password"
                                        [ngClass]="{'fa-eye-slash': hidePassword, 'fa-eye': !hidePassword}"
                                        (click)="hidePassword = !hidePassword">
                                    </span>
                                    <div class="validation"></div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <button type="submit" class="btn btn-a w-100" (click)="onSubmit()"
                                            *ngIf="!loading">
                                            Login
                                        </button>

                                        <button *ngIf="loading" class="btn loading" type="button" disabled>
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Logging in...
                                        </button>
                                    </div>
                                    <div class="col-md-6 mb-3 mt-3 text-md-right" style="text-align: center;">
                                        <!-- href="#collapseForgotPassword" -->
                                        <a class="small" data-toggle="collapse" role="button" aria-expanded="false"
                                            aria-controls="collapseForgotPassword" [attr.aria-expanded]="!isCollapsed"
                                            (click)="isCollapsed = !isCollapsed" routerLink='/forgot-password'>
                                            Forgot password
                                            <!-- <i class="fa fa-angle-down"></i> -->
                                        </a>
                                    </div>
                                </div>
                                <div class="collapse" id="collapseForgotPassword" [ngbCollapse]="isCollapsed">
                                    <hr>
                                    <div class="card-box-small-color">
                                        <h2 class="text-center mt-4"><i class="fa fa-unlock"></i> Forgot password</h2>
                                        <p class="text-center">Enter your email to receive your password</p>
                                        <div class="form-group">
                                            <label for="inputEmail"><b>Email *</b></label>
                                            <input name="email" type="email"
                                                class="form-control form-control-lg form-control-a"
                                                placeholder="Enter your email" data-msg="Please enter your email">
                                            <div class="validation"></div>
                                        </div>
                                        <button type="submit" class="btn btn-a w-100">
                                            Send my password
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
                <p class="small text-center mb-5"><i class="fa fa-shield"></i> Secure and safe data processing</p>
            </div>
        </div>
    </div>
</section>
<!--/ Login End /-->