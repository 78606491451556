import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomerModel, CustomerAdapter } from '../models/customer.model';
import { OrderModel, OrderAdapter } from '../models/order.model';
import { PaymentModel } from '../models/payment.model';
import { LoginService } from './login.service';
import { Observable, EMPTY, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Cacheable } from 'ngx-cacheable';

const cacheBuster$ = new Subject();

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private baseEndpoint = 'https://sparavuiwebsitecustomerapi.azurewebsites.net';
  private getInfoEndpoint = this.baseEndpoint + '/api/v1/customer/';
  private updateBasicInfoEndpoint = this.baseEndpoint + '/api/v1/customer/basicinfo';
  private updateResidenceInfoEndpoint = this.baseEndpoint + '/api/v1/customer/residenceinfo';
  private createInfoEndpoint = this.baseEndpoint + '/api/v1/customer/info';
  private updateCardEndpoint = this.baseEndpoint + '/api/v1/customer/card';
  private getOrdersEndpoint = this.baseEndpoint + '/api/v1/customer/orders';

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private customerAdapter: CustomerAdapter,
    private orderAdapter: OrderAdapter
  ) {
    cacheBuster$.subscribe(() => {
      console.log('bust cache...');
    });
  }

  // clear cache
  bustCache(): void {
    cacheBuster$.next();
  }

  /**
   * GET
   */

  @Cacheable({
    cacheBusterObserver: cacheBuster$.asObservable(),
  })
  getInfo(loginToken: string): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + loginToken });
    return this.http.get<any>(this.getInfoEndpoint, { headers });
  }

  @Cacheable({
    cacheBusterObserver: cacheBuster$.asObservable()
  })
  getOrders(loginToken: string): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + loginToken });
    return this.http.get<any>(this.getOrdersEndpoint, { headers });
  }

  /**
   * UPDATE
   */

  async updateCard(loginToken: string, payment: PaymentModel): Promise<any> {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + loginToken });

    return await this.http.post<any>(this.updateCardEndpoint, payment, { headers })
      .toPromise()
      .catch((err) => {
        console.log(err);
      });
  }

  async updateBasicInfo(loginToken: string, customer: CustomerModel): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + loginToken
    });

    const data = {
      firstname: customer.firstname,
      lastname: customer.lastname,
      email: customer.email,
      prefix: customer.prefix,
      phone: customer.phone
    };

    return await this.http.put<any>(this.updateBasicInfoEndpoint, data, { headers })
      .toPromise()
      .catch((err) => {
        console.log(err);
      });
  }

  async updateResidenceInfo(loginToken: string, customer: CustomerModel): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + loginToken
    });

    const data = {
      address: customer.address,
      zipcode: customer.zipcode,
      country: customer.country
    };

    return await this.http.put<any>(this.updateResidenceInfoEndpoint, data, { headers })
      .toPromise()
      .catch((err) => {
        console.log(err);
      });
  }

  /**
   * TODO: only temp. used to populate customer information
   */
  async createInfo(loginToken: string, customer: CustomerModel): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + loginToken
    });

    const data = {
      firstname: customer.firstname,
      lastname: customer.lastname,
      email: customer.email,
      prefix: customer.prefix,
      phone: customer.phone,
      address: customer.address,
      zipcode: customer.zipcode,
      country: customer.country
    };

    return await this.http.post<any>(this.createInfoEndpoint, data, { headers })
      .toPromise()
      .catch((err) => {
        console.log(err);
      });
  }

  inputfieldIsEmpty(input: string): boolean {
    return (!input || input.length === 0);
  }

  /**
   * validates and returns first and last name
   * @param fullname customer name
   */
  getFirstAndLastName(fullname: any): any {
    if (fullname) {
      const names: string = fullname.trim().split(' ');
      const firstname = names[0];
      let lastname = names[1];

      if (names.length > 2) {
        if (names[names.length - 1] === '') { lastname = names[names.length - 2]; }
        else { lastname = names[names.length - 1]; }
      }
      if (!lastname) { return null; }

      const customerName = { first: firstname, last: lastname };
      return customerName;
    }
    return null;
  }

  /**
   * fetch order info
   */
  fetchOrdersInfo(): Observable<OrderModel[]> {
    const orderInfo$ = this.getOrders(localStorage.getItem('loginToken'));

    const orderArray: OrderModel[] = [];

    return orderInfo$.pipe(
      map(orders => {
        for (const order of orders) {
          orderArray.push(this.orderAdapter.adapt(order));
        }
        return orderArray;
      }),
      catchError(err => {
        console.log(err);

        if (err.status === 401) {
          console.error('Your login session has expired.');
          this.loginService.logout();
          return EMPTY;
        }

        console.error('System error, please contact customer service.');
        this.loginService.logout();
        return EMPTY;
      })
    );

  }

  /**
   * fetch customer info or logout on error
   */
  fetchCustomerInfo(): Observable<CustomerModel> {
    const customerInfo$ = this.getInfo(localStorage.getItem('loginToken'));
    return customerInfo$.pipe(
      map(res => {

        if (JSON.stringify({}) === JSON.stringify(res)) {
          console.log('NO DATA FOUND');
          return;
        }

        return this.customerAdapter.adapt(res);
      }),
      catchError(err => {
        console.log(err);
        if (err.status === 401) {
          console.error('Your login session has expired.');
          this.loginService.logout();
          return;
        }

        console.error('System error, please contact customer service.');
        this.loginService.logout();
        return EMPTY;
      })
    );
  }

}
