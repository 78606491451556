import { Component, OnInit, Input } from '@angular/core';
import { OrderModel } from 'src/app/shared/models/order.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  @Input() orders: OrderModel[];

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToReceipt(orderId: string): void {
    // todo: open on same tab, but possible to return?
    // this.router.navigate(['/billing', orderId]);

    const url = this.router.createUrlTree(['/billing', orderId]);
    window.open(url.toString(), '_blank');
  }

}
