import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {SubscriptionService} from "../../../../shared/services/subscription.service";
import {LoginService} from "../../../../shared/services/login.service";

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.css']
})
export class CancelComponent implements OnInit {

  private token: string;

  public customersubscriptions = [];

  public username: string;
  public password: string;

  public loaded = false;

  public cancelLoading = false;

  public subscriptionstoCancel = [];

  constructor(private route: ActivatedRoute, private subscriptionService: SubscriptionService, private loginService: LoginService, private router: Router) { }

  ngOnInit(): void {

    localStorage.removeItem("subscriptionProductsToCancel");

    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.subscriptions();
    });

  }

  private async subscriptions() {
    this.subscriptionService.customersSubscription(this.token).then(res => {
      this.loaded = true;
      this.customersubscriptions = res;
    }).catch(error => {
      console.error(error);
    });
  }

  public cancel() {
    this.cancelLoading = true;
    this.loginService.login(this.username, this.password).then((res) => {
      switch (res.response_code) {
        case 200:
          localStorage.setItem('loginToken', this.token);
          this.router.navigate(['/subscription/termination/reason']);
          break;
        default:
          alert('Your login details is wrong');
          break;
      }

      this.cancelLoading = false;
    });

  }

  public changed(evt) {
    if (evt.target.checked) {
      this.subscriptionstoCancel.push(evt.target.value);
    } else {
      const index = this.subscriptionstoCancel.indexOf(evt.target.value);
      if (index > -1) {
        this.subscriptionstoCancel.splice(index, 1);
      }
    }

    localStorage.setItem('subscriptionProductsToCancel', JSON.stringify(this.subscriptionstoCancel));

  }

}
