<!--/ Videos /-->
<section class="section-news section-t4 section-b4">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-between pb-4">
                    <div class="title-box">
                        <h2 class="title-a">How to videos</h2>
                    </div>
                    <div class="title-link">
                        <a routerLink="/how-to-videos">More
                            <i class="fa fa-angle-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <br>
                    <h5><span class="color-a">SPARAV</span> for Windows PC</h5>
                    <br>
                    <img src="assets/img/svg/icon-windows-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In these videos, we run through the SPARAV Antivirus Protection Application for the Windows PC.
                    </p>
                    <hr>
                    <a routerLink="/how-to-videos">Watch the videos <i class="fa fa-angle-right"></i></a>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <br>
                    <h5><span class="color-a">SPARAV</span> for Mac OSX</h5>
                    <br>
                    <img src="assets/img/svg/icon-mac-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In these videos, we run through the SPARAV Antivirus Protection Application for Mac OSX.</p>
                    <hr>
                    <div class="disabled color-f" style="text-decoration: line-through;">
                        Coming soon
                        <i class="fa fa-angle-right"></i>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <br>
                    <h5><span class="color-a">SPARAV</span> for Android</h5>
                    <br>
                    <img src="assets/img/svg/icon-android-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In these videos, we run through the SPARAV Antivirus Protection App for Android phones.</p>
                    <hr>
                    <a routerLink="/how-to-videos">Watch the videos <i class="fa fa-angle-right"></i></a>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-box-ico text-center">
                    <br>
                    <h5><span class="color-a">SPARAV</span> for iOS</h5>
                    <br>
                    <img src="assets/img/svg/icon-ios-red-video.svg" width="" class="img-fluid">
                    <br><br>
                    <p>In these videos, we run through the SPARAV Antivirus Protection App for iPhones.</p>
                    <hr>
                    <div class="disabled color-f" style="text-decoration: line-through;">
                        Coming soon
                        <i class="fa fa-angle-right"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--/ Videos End /-->