import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CustomerAdapter, CustomerModel } from 'src/app/shared/models/customer.model';
import { OrderAdapter, OrderModel } from 'src/app/shared/models/order.model';
import { CustomerService } from 'src/app/shared/services/customer.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit, OnDestroy {

  customer: CustomerModel;
  order: OrderModel;
  private subscription: Subscription;

  constructor(
    private customerAdapter: CustomerAdapter,
    private orderAdapter: OrderAdapter,
    private route: ActivatedRoute,
    private customerService: CustomerService
  ) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    // empty
    this.subscription = new Subscription();
    this.order = new OrderModel('', '', '', '', '', '', '', '', '', '', '', '');

    /**
     * Load customer info
     */
    this.customerService.fetchCustomerInfo().subscribe(customer => {
      if (!customer) {
        alert('Please update your profile and billing information first.');
        window.close();
        return;
      }
      this.customer = customer;
    });

    /**
     * Load order info
     */
    this.subscription = this.route.paramMap
      .subscribe(params => {

        this.customerService.fetchOrdersInfo().subscribe(ordersArray => {

          for (const order of ordersArray) {
            if (order.$order_id === params.get('orderId')) {
              this.order = this.orderAdapter.adapt(order);

              setTimeout(() => {
                window.print();
              }, 2000);
              return;
            }
          }

          alert('No order found, try again.');
          window.close();
        });

      });

  }

}
