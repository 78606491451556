<!--/ Nav /-->
<nav class="navbar navbar-default navbar-trans navbar-expand-lg fixed-top">
    <div class="container-fluid">
        <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarDefault"
            aria-controls="navbarDefault" aria-expanded="false" aria-label="Toggle navigation"
            (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed">
            <span></span>
            <span></span>
            <span></span>
        </button>
        <a class="navbar-brand logo-brand" href="https://sparav.com/">
            <img src="assets/img/svg/sparav-logo.svg" width="110px">
        </a>
        <div class="navbar-collapse collapse" id="navbarDefault" [ngbCollapse]="isCollapsed">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/dashboard" routerLinkActive="active">
                        <i class="fa fa-shield"></i>
                        Dashboard
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/devices" routerLinkActive="active">
                        <i class="fa fa-desktop"></i>
                        Devices
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/support" routerLinkActive="active">
                        <i class="fa fa-support"></i>
                        Support
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/how-to-videos" routerLinkActive="active">
                        <i class="fa fa-youtube-play"></i>
                        How to videos
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/news" routerLinkActive="active">
                        <i class="fa fa-newspaper-o"></i>
                        News
                    </a>
                </li>
                <li class="nav-item nav-link-border d-none d-sm-block">
                </li>
                <li class="nav-item dropdown d-none d-sm-block">
                    <a class="nav-link dropdown-toggle" data-toggle="dropdown"
                        [ngClass]="{'active': billing.isActive || profile.isActive }" routerLinkActive='active'>
                        {{ profileIntro  || 'My account' }}
                    </a>
                    <div class="dropdown-menu">
                        <a routerLink="/profile" class="dropdown-item" routerLinkActive="active"
                            #profile="routerLinkActive">
                            <i class="fa fa-user"></i>
                            Profile
                        </a>
                        <!-- <a routerLink="/profile" class="dropdown-item" routerLinkActive="active">
                            <i class="fa fa-lock"></i>
                            Change password
                        </a>
                        <a routerLink="/profile" class="dropdown-item" routerLinkActive="active">
                            <i class="fa fa-envelope"></i>
                            Change email
                        </a> -->
                        <a routerLink="/billing" class="dropdown-item" routerLinkActive="active"
                            #billing="routerLinkActive">
                            <i class="fa fa-sticky-note"></i>
                            Billing
                        </a>
                        <div class="dropdown-divider"></div>
                        <a (click)="logout()" class="btn-a dropdown-item">
                            <i class="fa fa-unlock"></i>
                            Logout
                        </a>
                    </div>
                </li>
                <li class="nav-item d-sm-none">
                    <a class="nav-link" routerLink="/profile" routerLinkActive="active" #profile="routerLinkActive">
                        <i class="fa fa-user"></i>
                        Profile
                    </a>
                </li>
                <!-- <li class="nav-item d-sm-none">
                    <a class="nav-link" routerLink="/profile"><i class="fa fa-lock"></i> Change password</a>
                </li>
                <li class="nav-item d-sm-none">
                    <a class="nav-link" routerLink="/profile"><i class="fa fa-envelope"></i> Change email</a>
                </li> -->
                <li class="nav-item d-sm-none">
                    <a class="nav-link" routerLink="/billing" routerLinkActive="active" #billing="routerLinkActive">
                        <i class="fa fa-sticky-note"></i>
                        Billing
                    </a>
                </li>
                <li class="nav-item d-sm-none">
                    <a class="nav-link" (click)="logout()">
                        <i class="fa fa-unlock"></i>
                        Logout
                    </a>
                </li>
                <li class="nav-item mt-2 nav-download">
                    <a href="https://payment.sparav.com/m/download" target="_blank" class="version-b">
                        <i class="fa fa-download"></i>
                        Download
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
<!--/ Nav End /-->