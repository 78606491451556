import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  q1: boolean;
  q2: boolean;
  q3: boolean;
  q4: boolean;
  q5: boolean;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) { return; }
      window.scrollTo(0, 0);
    });

    // hide questions true
    this.q1 = true;
    this.q2 = true;
    this.q3 = true;
    this.q4 = true;
    this.q5 = true;

  }

}
