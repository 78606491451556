import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-dashboard-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  @Input() isActivePlan: boolean;

  devices: any;

  deviceForm = new FormGroup({
    device: new FormControl(null, [
      Validators.required,
    ]),
  });

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    this.devices = [];
  }

  openAddDevice(content: any): void {
    this.modalService.open(content, { centered: true });
  }

  addDevice(): void {

    // add to devices list
    const device = {
      type: 'android',
      name: this.deviceForm.value.device
    };
    this.devices.push(device);

    // close form
    this.modalService.dismissAll();
  }

  removeDevice(deviceName: string): void {
    const index = this.devices.indexOf(deviceName);
    this.devices.splice(index, 1);
  }
}
