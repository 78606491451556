import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BillingComponent } from './pages/billing/billing.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DevicesComponent } from './pages/devices/devices.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { NewPasswordComponent } from './pages/new-password/new-password.component';
import { HowToComponent } from './pages/how-to/how-to.component';
import { LoginComponent } from './pages/login/login.component';
import { NewsComponent } from './pages/news/news.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SupportComponent } from './pages/support/support.component';
import { TermsComponent } from './pages/terms/terms.component';
import { NewsSingleComponent } from './pages/news-single/news-single.component';

import { AuthGuardService } from './shared/services/auth-guard.service';
import {CancelComponent} from "./pages/subscription/termination/cancel/cancel.component";
import {CancelreasonComponent} from "./pages/subscription/termination/cancelreason/cancelreason.component";
import { ReceiptComponent } from './pages/receipt/receipt.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  // { path: 'terms', component: TermsConditionsComponent },
  // { path: 'privacy', component: PrivacyPolicyComponent },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'forgot-password', children: [
      {
        path: 'new-password',
        component: NewPasswordComponent
      },
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: 'subscription',
    children: [
      {
        path: 'termination',
        component: CancelComponent
      },
      {
        path: 'termination/reason',
        component: CancelreasonComponent
      }
    ]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'devices',
    component: DevicesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'support',
    component: SupportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'how-to-videos',
    component: HowToComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'news',
    component: NewsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'news', children: [
      {
        path: ':newsName',
        component: NewsSingleComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'billing',
    component: BillingComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'billing', children: [
      {
        path: ':orderId',
        component: ReceiptComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },
    ]
  },
  { path: 'privacy-policy', component: PrivacyComponent },
  { path: 'terms-conditions', component: TermsComponent },
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false }), // <-- debugging purposes only
  ], exports: [RouterModule]
})
export class AppRoutingModule { }
