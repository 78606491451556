<!--/ Login /-->
<section class="intro-single background-mural-01" style="height:100vh;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 text-center">
                <img src="assets/img/svg/sparav-logo.svg" width="240px" class="mb-5">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="card-box-ico">
                    <div class="text-center">
                        <h2>
                            <i class="fa fa-lock"></i>
                            New password
                        </h2>
                        <p>Enter your new password</p>
                    </div>
                    <hr>
                    <form class="form-a" action="" method="post" role="form" [formGroup]="passwordForm">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <div class="form-group">
                                    <label for="inputEmail"><b>New password *</b></label>
                                    <input name="newPassword" type="password"
                                        class="form-control form-control-lg form-control-a"
                                        placeholder="Your new password" data-msg="Please enter your new password"
                                        formControlName="newPassword" [ngClass]="{'errorBorder': newPasswordError}"
                                        (focus)="newPasswordError = false;">
                                    <div class="validation"></div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="form-group">
                                    <label for="inputPassword"><b>Confirm new password *</b></label>
                                    <input id="password-field" name="confirmPassword" type="password"
                                        class="form-control form-control-lg form-control-a"
                                        placeholder="Confirm your password" data-msg="Please confirm your password"
                                        formControlName="confirmPassword" [ngClass]="{'errorBorder': confirmPasswordError}"
                                        (focus)="confirmPasswordError = false;">
                                    <span toggle="#password-field" class="fa fa-fw field-icon toggle-password">
                                    </span>
                                    <div class="validation"></div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-6 mb-3" class="loadingText">
                                        <button *ngIf="!loading" type="submit" class="btn btn-a w-100" (click)="resetPassword()">
                                            Reset Password
                                        </button>

                                        <button *ngIf="loading" class="btn" type="button" disabled>
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Resetting password...
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <p class="small text-center mb-5"><i class="fa fa-shield"></i> Secure and safe data processing</p>
            </div>
        </div>
    </div>
</section>
<!--/ Login End /-->