import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FooterComponent } from './core/common/footer/footer.component';
import { HeaderComponent } from './core/common/header/header.component';
import { ContentComponent } from './core/dashboard/content/content.component';
import { VideosComponent } from './core/dashboard/videos/videos.component';
import { BillingComponent } from './pages/billing/billing.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DevicesComponent } from './pages/devices/devices.component';
import { HowToComponent } from './pages/how-to/how-to.component';
import { LoginComponent } from './pages/login/login.component';
import { NewsComponent } from './pages/news/news.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SupportComponent } from './pages/support/support.component';
import { TermsComponent } from './pages/terms/terms.component';

import { CardValidationService } from './shared/services/card-validation.service';
import { LoginService } from './shared/services/login.service';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { NewPasswordComponent } from './pages/new-password/new-password.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { DatePipe } from '@angular/common';
import { InvoiceComponent } from './core/billing/invoice/invoice.component';
import { NewsSingleComponent } from './pages/news-single/news-single.component';
import { CancelComponent } from './pages/subscription/termination/cancel/cancel.component';
import { SubscriptionService } from './shared/services/subscription.service';
import { CancelreasonComponent } from './pages/subscription/termination/cancelreason/cancelreason.component';
import { ReceiptComponent } from './pages/receipt/receipt.component';
import { SubscriptionComponent } from './core/billing/subscription/subscription.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    VideosComponent,
    ContentComponent,
    DevicesComponent,
    SupportComponent,
    HowToComponent,
    NewsComponent,
    ProfileComponent,
    BillingComponent,
    TermsComponent,
    PrivacyComponent,
    NewPasswordComponent,
    ForgotPasswordComponent,
    InvoiceComponent,
    NewsSingleComponent,
    CancelComponent,
    CancelreasonComponent,
    ReceiptComponent,
    SubscriptionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    DatePipe,
    CardValidationService,
    CookieService,
    LoginService,
    AuthGuardService,
    SubscriptionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
