import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { CustomerModel } from 'src/app/shared/models/customer.model';
import { OrderAdapter, OrderModel } from 'src/app/shared/models/order.model';
import { PaymentModel } from 'src/app/shared/models/payment.model';
import { SubscriptionModel } from 'src/app/shared/models/subscription.model';
import { CardValidationService } from 'src/app/shared/services/card-validation.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';

declare function checkCardNumber(): any;

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {

  orders: OrderModel[];
  subscriptions: SubscriptionModel[];

  // todo: used to create new info if info not exist
  private missingInfo: boolean;

  updateLoading: boolean;
  private loginToken: string;

  // error borders
  addressError: boolean;
  zipcodeError: boolean;
  cardNumberError: boolean;
  expiryError: boolean;
  cvvError: boolean;

  isFocused: boolean;
  activateExpiryBorder: boolean;

  // amex vs visa/master
  cvvLength: string;
  cardLength: string;
  cardPlaceholder = '1234 5678 9012 3456';
  expiryPlaceholder = '12/34';

  // interpolations
  nextBilling: string;
  isActivePlan: boolean;

  // forms
  public paymentForm = new FormGroup({
    address: new FormControl(null),
    zipcode: new FormControl(null),
    country: new FormControl(null),
    cardNumber: new FormControl(null),
    cardExpiry: new FormControl(null),
    cardCvv: new FormControl(null),
  });

  constructor(
    private router: Router,
    private customerService: CustomerService,
    private orderAdapter: OrderAdapter,
    private loginService: LoginService,
    private cardValidationService: CardValidationService,
    private subscriptionService: SubscriptionService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) { return; }
      window.scrollTo(0, 0);
    });

    checkCardNumber();
    this.cvvLength = '3';
    this.cardLength = '19'; // digits = 16 + 3 empty space between
    this.loginToken = localStorage.getItem('loginToken');

    // set customer
    this.customerService.fetchCustomerInfo().subscribe(customerModel => {

      // todo: undefined customer, create new one first time update
      if (!customerModel) {
        this.missingInfo = true;
        this.paymentForm.patchValue({ country: 'NO' }); // todo: set as NO when missing info
        return;
      }

      this.missingInfo = false;
      this.setCustomer(customerModel);
    });

    // set orders
    this.customerService.fetchOrdersInfo().subscribe(ordersArray => {
      this.setOrders(ordersArray);
    });

    // set billing plan
    this.subscriptionService.activeSubscriptions(localStorage.getItem('loginToken')).subscribe(subs => {
      if (subs.length === 0) {
        this.isActivePlan = false;
        return;
      }
      this.isActivePlan = true;
    });

    // set subscriptions
    this.subscriptionService.subscriptionsInfo(localStorage.getItem('loginToken')).subscribe(subs => {
      if (subs.length === 0) {
        console.error('No subscriptions');
        console.log(subs);
        return;
      }
      this.setSubscriptions(subs);
      this.setNextBilling(subs);
    });
  }

  /**
   * Update payment info
   */
  updatePaymentInfo(): void {
    // if nothing is changed
    if (this.paymentForm.pristine) {
      alert('You did not change any payment information.');
      return;
    }

    // if residence and card changed
    if (
      (!this.paymentForm.controls.address.pristine ||
        !this.paymentForm.controls.zipcode.pristine ||
        !this.paymentForm.controls.country.pristine)
      &&
      (!this.paymentForm.controls.cardNumber.pristine ||
        !this.paymentForm.controls.cardExpiry.pristine ||
        !this.paymentForm.controls.cardCvv.pristine)
    ) {
      this.updateResidenceInfo(false);
      this.updateCard();
      return;
    }

    // if only residence info changed
    if (
      !this.paymentForm.controls.address.pristine ||
      !this.paymentForm.controls.zipcode.pristine ||
      !this.paymentForm.controls.country.pristine
    ) {
      this.updateResidenceInfo(true);
      return;
    }

    // if card info changed
    if (
      !this.paymentForm.controls.cardNumber.pristine ||
      !this.paymentForm.controls.cardExpiry.pristine ||
      !this.paymentForm.controls.cardCvv.pristine
    ) {
      this.updateCard();
    }

  }

  /**
   * Update residence info
   */
  updateResidenceInfo(onlyResidenceInfo: boolean): void {
    const address = this.paymentForm.value.address;
    const zipcode = this.paymentForm.value.zipcode;
    const country = this.paymentForm.value.country;

    // set errors on empty
    if (this.loginService.inputfieldIsEmpty(address)) { this.addressError = true; }
    if (this.loginService.inputfieldIsEmpty(zipcode)) { this.zipcodeError = true; }
    if (this.loginService.inputfieldIsEmpty(country)) {
      alert('Please enter a country.');
      return;
    }

    if (
      !this.loginService.inputfieldIsEmpty(address) &&
      !this.loginService.inputfieldIsEmpty(zipcode) &&
      !this.loginService.inputfieldIsEmpty(country)
    ) {

      if (onlyResidenceInfo) { this.updateLoading = true; }

      // todo: create data for old customers when they update first time
      if (this.missingInfo) {
        this.createpaymentInfo();
        return;
      }

      const customer = new CustomerModel(
        '',
        '',
        '',
        '',
        '',
        address,
        zipcode,
        country,
        ''
      );

      this.customerService.updateResidenceInfo(this.loginToken, customer).then(res => {
        if (!res) {
          // todo: unauthorized -> logout?
          alert('Your login session has expired.');
          this.loginService.logout();
          return;
        }

        if (onlyResidenceInfo) { this.updateLoading = false; }

        console.log('Address updated...');

        if (onlyResidenceInfo) {
          alert('Your address has been updated.');
        }

        // refresh page with updated customer info
        this.customerService.bustCache();
      });
    }
  }

  /**
   * Update card info
   */
  updateCard(): void {
    // console.log(this.paymentForm.value);

    // todo: cannot update without residence info
    const address = this.paymentForm.value.address;
    const zipcode = this.paymentForm.value.zipcode;
    const country = this.paymentForm.value.country;
    if (
      this.loginService.inputfieldIsEmpty(address) &&
      this.loginService.inputfieldIsEmpty(zipcode) &&
      this.loginService.inputfieldIsEmpty(country)) {
      alert('Add your address before updating your card');
      return;
    }

    // if all input has input, check for errors
    const cardNumber = this.paymentForm.value.cardNumber;
    if (this.cardValidationService.setCardNumberBorder(cardNumber)) { this.cardNumberError = true; } // todo: set return on error card?
    const expiry = this.paymentForm.value.cardExpiry;
    if (this.cardValidationService.setExpiryBorder(expiry)) { this.expiryError = true; return; }
    let cvv = this.paymentForm.value.cardCvv;
    if (!this.cardValidationService.setCvvErrorBorder(cvv)) { cvv = null; }

    // if all input is empty, create error borders respectively
    if (this.customerService.inputfieldIsEmpty(cardNumber)) { this.cardNumberError = true; }
    if (this.customerService.inputfieldIsEmpty(expiry)) { this.expiryError = true; }
    if (this.customerService.inputfieldIsEmpty(cvv)) { this.cvvError = true; }

    // if all input is correct
    if (
      !this.customerService.inputfieldIsEmpty(cardNumber) &&
      !this.customerService.inputfieldIsEmpty(expiry) &&
      !this.customerService.inputfieldIsEmpty(cvv)
    ) {

      this.updateLoading = true;

      const payment = new PaymentModel(
        localStorage.getItem('ipaddress'),
        this.paymentForm.value.address,
        this.paymentForm.value.zipcode,
        'City',
        'City',
        this.paymentForm.value.country,
        localStorage.getItem('phone') || 'Phone',
        localStorage.getItem('username'),
        this.paymentForm.value.cardNumber,
        this.paymentForm.value.cardExpiry.replace('/', ''),
        this.paymentForm.value.cardCvv
      );

      this.customerService.updateCard(this.loginToken, payment).then(res => {

        // todo: 911 means update failed, waiting for limelight to fix to 100
        if (res.response_code === '100' || res.response_code === '911') {
          alert('Payment information has been updated.');
          this.updateLoading = false;

          console.log('Card updated...');

          this.customerService.bustCache();
          return;
        }

        // error on other res codes
        alert('Update failed due to invalid card.');
        console.error(res);
        this.updateLoading = false;
      });
    }
  }

  /**
   * TODO: delete method once all customers created before 19th aug 2020 have info created
   */
  createpaymentInfo(): void {
    const customer = new CustomerModel(
      '?',
      '?',
      '?',
      '47', // todo: set country code by ip address
      '?',
      this.paymentForm.value.address,
      this.paymentForm.value.zipcode,
      this.paymentForm.value.country,
      ''
    );

    this.customerService.createInfo(this.loginToken, customer).then(res => {
      if (!res) {
        alert('Your login session has expired.');
        this.loginService.logout();
        return;
      }
      window.location.reload(); // only for first time create info
    });
  }

  /**
   * Getters & setters
   */

  private setCustomer(customer: CustomerModel): void {
    localStorage.setItem('phone', customer.prefix + customer.phone);
    this.paymentForm.patchValue({
      address: customer.address,
      zipcode: customer.zipcode,
      country: customer.country.toUpperCase()
    });
  }

  private setOrders(orders: OrderModel[]): void {
    this.orders = orders;

    const order = this.orderAdapter.adapt(orders[0]);
    this.cardPlaceholder = order.$cc_number;
    this.expiryPlaceholder = order.$cc_expires;
  }

  private setSubscriptions(subs: any): void {
    const subscriptions: SubscriptionModel[] = [];

    // todo: adapt?
    // create models
    let id;
    for (const sub of subs) {
      // do not add subscription with same id
      if (id !== sub.id) {
        id = sub.id;
        const status = sub.status === 'active' ? true : false;
        const isRecurring = sub.is_recurring === 1 ? true : false;
        subscriptions.push(
          new SubscriptionModel(
            sub.name,
            sub.created_at,
            sub.recur_at,
            isRecurring,
            status
          )
        );
      }
    }

    this.subscriptions = subscriptions;
  }

  /**
   * Calculate next recurring billing date
   * @param subs
   */
  private setNextBilling(subs: any[]): void {
    let nextPayment = subs[0].recur_at;
    for (const sub of subs) {
      // if less than current nextPayment
      if ((new Date(sub.recur_at).getTime() < new Date(nextPayment).getTime())) {
        nextPayment = sub.recur_at;
      }
    }
    this.nextBilling = this.datePipe.transform(nextPayment, 'dd/MM/yyyy');
  }

  setCardNumber(value: string): void {
    value = value.replace(/ /g, '');
    this.paymentForm.patchValue({ cardNumber: value });
  }
  setCardExpiry(value: string): void { this.paymentForm.patchValue({ cardExpiry: value }); }
  setCardCvv(value: string): void { this.paymentForm.patchValue({ cardCvv: value }); }

  getCardLogoFileName(): string { return this.cardValidationService.cardLogoFileName; }
  getValidCardLength(): boolean { return this.cardValidationService.validCardLength; }
  getInvalidExpiryMonth(): boolean { return this.cardValidationService.invalidExpiryMonth; }
  getValidExpiryDate(): boolean { return this.cardValidationService.validExpiryDate; }

  /**
   * todo: move to creditcard form component
   */
  onFocusCardNumber(cardNumber: string): void {
    this.isFocused = true;
    this.cardValidationService.validateCardType(cardNumber);
  }

  onBlurCardNumber(cardNumber: string): void {
    if (cardNumber.length < 8) { this.cardNumberError = true; } // todo: error border if blur before card validation

    this.isFocused = false;
    this.cardValidationService.validateCardType(cardNumber);
  }

  onKeyupCardNumber(cardNumber: string): void {
    this.cardValidationService.validateCardType(cardNumber);
    if (this.cardValidationService.cardLogoFileName === 'american-express.svg') { this.cardLength = '18'; }
    else { this.cardLength = '19'; }
    this.setCardNumber(cardNumber);
  }

  onFocusCardExpDate(expiryDate: string): void {
    this.activateExpiryBorder = true;
    this.cardValidationService.validateExpDate(expiryDate);
  }

  onBlurCardExpDate(expiryDate: string): void {
    this.cardValidationService.validateExpDate(expiryDate);
    this.activateExpiryBorder = false;

    // check if user inputs on valid months
    if (this.cardValidationService.invalidExpiryMonth === false) {
      if (expiryDate.length > 0 && expiryDate.length <= 4) { this.cardValidationService.invalidExpiryMonth = true; }
    }
  }

  // todo: on blur and on focus
  onKeyupCardExpDate(expiryDate: string): void {
    this.cardValidationService.validateExpDate(expiryDate);
    this.setCardExpiry(expiryDate);
  }

  onKeyupCvv(): void {
    if (this.cardValidationService.cardLogoFileName === 'american-express.svg') { this.cvvLength = '4'; }
    else { this.cvvLength = '3'; }
  }
}
