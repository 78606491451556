import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-news-single',
  templateUrl: './news-single.component.html',
  styleUrls: ['./news-single.component.css']
})
export class NewsSingleComponent implements OnInit, OnDestroy {

  // prevArticle: string;
  // nextArticle: string;

  currentArticle: any;
  private subscription: Subscription;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) { return; }
      window.scrollTo(0, 0);
    });

    this.currentArticle = {
      imgUrl: '',
      date: '',
      title: '',
      headlineOne: '',
      bodyOne: '',
      headlineTwo: '',
      bodyTwo: '',
      prevArticle: '',
      nextArticle: '',
    };

    this.setArticle();
  }

  goToArticle(articleName: string): void {
    this.router.navigate(['/news', articleName]);
  }


  private setArticle(): void {

    this.subscription = this.route.paramMap
      .subscribe(params => {

        // console.log('param: ', params.get('newsName'));

        // todo: dynamically create article models and create list
        switch (params.get('newsName')) {
          case 'article-one':
            this.currentArticle.date = '17-03-2020';
            this.currentArticle.title = 'More than two thirds of all Android antivirus apps are ineffective and many don\'t even do anything at all';
            this.currentArticle.headlineOne = 'Facepalm: In a survey of 250 Android so-called antivirus apps, only 80 were found to block more than 30 % of malware samples.Some of the apps tested didn\'t even have any antivirus functionality at all.';
            this.currentArticle.bodyOne = 'AV-Comparatives is an industry research group that rates antivirus programs on their effectiveness. They recently conducted a study of Android antivirus products from the Google Play Store and the results were rather surprising. They tested each app with 2,000 of the most common Android malware threats and recorded which were caught and which were let through. The tests were automated, but were done on real phones rather than emulators.        The test was designed to be easy and as a result, most of the real antivirus apps detected 100% of the samples. Overall, there were about 50 apps that scored above 90%. As a control, they also tested clean apps to see if the antivirus apps actually scanned the phone. What they found is that many of the supposed antivirus apps simply marked every other app on the user\'s phone as suspicious unless it was on a hard-coded list of allowed apps.';
            this.currentArticle.prevArticle = '';
            this.currentArticle.nextArticle = 'article-two';
            break;
          case 'article-two':
            this.currentArticle.date = '10-04-2019';
            this.currentArticle.title = 'A fourth of all PCs are unprotected from virus and malware threats';
            this.currentArticle.headlineOne = 'Nearly a quarter of all PCs in the world are unprotected from virus, malware and other malicious threats according to Microsoft’s latest Security Intelligence Report. In it, Redmond said computers without any protection are 5.5 times more likely to become infected than systems running some sort of up to date anti - virus software.';
            this.currentArticle.bodyOne = 'The company’s research revealed that malicious documents like PDFs and Word documents are on the rise. During the fourth quarter of 2012, Microsoft said they detected and removed malicious documents from nearly three million computers. Reasons for infection are aplenty. Some users let the trial period on their anti-virus expire or the software is simply out of date while some malware is capable of disabling anti-virus protection. In most of these cases, users aren’t even aware that they are no longer protected from online perils. Still others simply aren’t aware of how important it is to run protection in the first place.';
            this.currentArticle.prevArticle = 'article-one';
            this.currentArticle.nextArticle = 'article-three';
            break;
          case 'article-three':
            this.currentArticle.date = '17-03-2020';
            this.currentArticle.title = 'Is Antivirus Software Necessary in 2020?';
            this.currentArticle.headlineOne = 'Windows, Android, iOS, and Mac operating systems all have decent security protections, so is an antivirus still necessary in 2020? Considering the fact that users on every operating system get hacked every day, the answer is a resounding YES!';
            this.currentArticle.bodyOne = 'Antivirus scanners use a variety of methods to identify malware before it can make any changes onto your computer. Malware tries to hide itself in seemingly innocent files, like movies, mp3s, program files, and images. It can be impossible to tell from looking at a file that it contains hidden malware — which is why a high-quality antivirus is so important.';
            this.currentArticle.prevArticle = 'article-two';
            this.currentArticle.nextArticle = '';
            break;
          default:
            this.router.navigate(['news']);
            break;
        }

      });
  }


}
