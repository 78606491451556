import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CustomerModel, CustomerAdapter } from 'src/app/shared/models/customer.model';
import { browserRefresh } from 'src/app/app.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  // interpolations
  memberSince: string;

  // loaders
  updatePasswordLoading: boolean;
  updateInfoLoading: boolean;

  // error borders
  currentPasswordError: boolean;
  newPasswordError: boolean;
  confirmPasswordError: boolean;

  fullnameError: boolean;
  emailError: boolean;
  prefixError: boolean;
  phoneError: boolean;

  // forms
  public passwordForm = new FormGroup({
    currentPassword: new FormControl(null),
    newPassword: new FormControl(null),
    confirmPassword: new FormControl(null),
  });

  public customerForm = new FormGroup({
    fullname: new FormControl(null),
    email: new FormControl(null),
    phoneCode: new FormControl(null),
    phone: new FormControl(null),
  });

  private loginToken: string;
  private missingInfo: boolean; // used to determine customer has info yet

  constructor(
    private router: Router,
    private loginService: LoginService,
    private customerService: CustomerService,
    private customerAdapter: CustomerAdapter,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) { return; }
      window.scrollTo(0, 0);
    });

    // set login token
    this.loginToken = localStorage.getItem('loginToken');

    // load customer info if exist
    this.customerService.fetchCustomerInfo().subscribe(customer => {
      // todo: undefined customer, create new one first time update
      if (!customer) {
        this.missingInfo = true;
        return;
      }

      this.missingInfo = false;
      this.setCustomer(customer);
    });

  }

  updatePassword(): void {
    const currentPassword = this.passwordForm.value.currentPassword;
    const newPassword = this.passwordForm.value.newPassword;
    const confirmPassword = this.passwordForm.value.confirmPassword;

    // set errors on empty
    if (this.loginService.inputfieldIsEmpty(currentPassword)) { this.currentPasswordError = true; }
    if (this.loginService.inputfieldIsEmpty(newPassword)) { this.newPasswordError = true; }
    if (this.loginService.inputfieldIsEmpty(confirmPassword)) { this.confirmPasswordError = true; }

    if (
      !this.loginService.inputfieldIsEmpty(currentPassword) &&
      !this.loginService.inputfieldIsEmpty(newPassword) &&
      !this.loginService.inputfieldIsEmpty(confirmPassword)
    ) {

      if (newPassword.length < 4 || confirmPassword.length < 4) {
        alert('You must enter min. 4 characters');
        return;
      }

      this.updatePasswordLoading = true;

      if (newPassword !== confirmPassword) {
        this.updatePasswordLoading = false;
        this.newPasswordError = true;
        this.confirmPasswordError = true;
        alert('Passwords do no match.');
        return;
      }

      if (!localStorage.getItem('username')) {
        alert('Something went wrong. Try log in again.');
        this.loginService.logout();
        return;
      }

      this.loginService.getResetToken(localStorage.getItem('username')).then(res => {
        switch (res.response_code) {
          case 200:

            this.loginService.resetPassword(res.reset_token, newPassword).then(response => {
              switch (response.response_code) {
                case 200:
                  alert('Your password has been changed');

                  this.passwordForm.reset();
                  this.updatePasswordLoading = false;

                  break;
                default:
                  console.error('Password did not change');
                  alert('ERROR. Contact customer service');
                  this.loginService.logout();
                  break;
              }
            });

            break;
          default:
            alert('Something went wrong. Try log in again.');
            this.loginService.logout();
            break;
        }
      });

    }
  }

  // todo: verify that fields has been changed compared to the current localstorage data before updating
  updateInfo(): void {
    // check if user made any changes
    if (this.customerForm.pristine) {
      alert('You did not change your information.');
      return;
    }

    const fullname = this.customerForm.value.fullname;
    const email = this.customerForm.value.email;
    const prefix = this.customerForm.value.phoneCode;
    const phone = this.customerForm.value.phone;

    // set errors on empty
    if (this.loginService.inputfieldIsEmpty(fullname)) { this.fullnameError = true; }
    if (this.loginService.inputfieldIsEmpty(email)) { this.emailError = true; }
    if (this.loginService.inputfieldIsEmpty(prefix)) { this.prefixError = true; }
    if (this.loginService.inputfieldIsEmpty(phone)) { this.phoneError = true; }

    // else update
    if (
      !this.loginService.inputfieldIsEmpty(fullname) &&
      !this.loginService.inputfieldIsEmpty(email) &&
      !this.loginService.inputfieldIsEmpty(prefix) &&
      !this.loginService.inputfieldIsEmpty(phone)
    ) {

      const names = this.customerService.getFirstAndLastName(fullname);
      if (!names) {
        alert('Please enter your full name.');
        return;
      }

      this.updateInfoLoading = true;

      // todo: create data for old customers when they update first time
      if (this.missingInfo) {
        this.createInfo();
        return;
      }

      const customer = new CustomerModel(
        names.first,
        names.last,
        this.customerForm.value.email,
        this.customerForm.value.phoneCode,
        this.customerForm.value.phone,
        '',
        '',
        '',
        ''
      );

      this.customerService.updateBasicInfo(this.loginToken, customer).then(res => {
        if (!res) {
          // todo: unauthorized -> logout?
          alert('Your login session has expired.');
          this.loginService.logout();
          return;
        }
        alert('Your information has been changed.');
        this.updateInfoLoading = false;

        // refresh page with updated customer info
        this.customerService.bustCache();
      });
    }
  }

  /**
   * TODO: delete method once all customers created before 19th aug 2020 have info created
   */
  createInfo(): void {

    const names = this.customerService.getFirstAndLastName(this.customerForm.value.fullname);
    if (!names) {
      alert('Please enter your full name.');
      return;
    }

    const customer = new CustomerModel(
      names.first,
      names.last,
      this.customerForm.value.email,
      this.customerForm.value.phoneCode,
      this.customerForm.value.phone,
      '?',
      '?',
      'NO', // todo: get ip address country code
      ''
    );

    this.customerService.createInfo(this.loginToken, customer).then(res => {
      if (!res) {
        alert('Your login session has expired.');
        this.loginService.logout();
        return;
      }

      window.location.reload(); // todo: repopulate fields with newly created customer info
    });
  }


  /**
   * Setters
   */
  setCustomer(customer: CustomerModel): void {
    this.customerForm.patchValue({ fullname: customer.firstname + ' ' + customer.lastname });
    this.customerForm.patchValue({ email: customer.email });
    this.customerForm.patchValue({ phoneCode: + customer.prefix });
    this.customerForm.patchValue({ phone: customer.phone });
    this.memberSince = customer.joined;
  }
}
