import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable } from 'ngx-cacheable';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';

@Injectable()
export class SubscriptionService {

  public loading = false;

  constructor(private http: HttpClient) { }

  /**
   * 
   * @param bearerToken 
   */
  @Cacheable()
  activeSubscriptions(bearerToken: string): Observable<any> {

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + bearerToken
    });

    return this.http.get<any>(environment.customer_api_host + 'v1/subscription/customer/subscriptions', { headers });
  }

  @Cacheable()
  subscriptionsInfo(bearerToken): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + bearerToken
    });

    return this.http.get<any>(environment.customer_api_host + 'v1/subscription/customer/subscriptionsinfo', { headers });
  }

  /**
   *
   * @param bearerToken
   */
  public async customersSubscription(bearerToken: string): Promise<any> {

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + bearerToken
    });

    return await this.http.get<any>(environment.customer_api_host + 'v1/subscription/customer/subscriptions', { headers })
      .toPromise()
      .catch((err) => {
        console.log(err);
      });

  }

  /**
   * Will cancel the given subscription ids.
   * @param subscription_ids
   */
  public async cancelSubscription(subscription_ids: []): Promise<any> {

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('loginToken')
    });

    let data = {
      'subscription_ids': subscription_ids
    };

    return await this.http.post<any>(environment.customer_api_host + 'v1/subscription/cancel', data, { headers })
      .toPromise();


  }

}
