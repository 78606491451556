export class SubscriptionModel {

    // tslint:disable:variable-name
    private name: string;
    private date: string;
    private billingDate: string;
    private isRecurring: boolean;
    private status: boolean;

    constructor($name: string, $date: string, $billingDate: string, $isRecurring: boolean, $status: boolean) {
        this.name = $name;
        this.date = $date;
        this.billingDate = $billingDate;
        this.isRecurring = $isRecurring;
        this.status = $status;
    }

    /**
     * Getter $name
     * @return {string}
     */
    public get $name(): string {
        return this.name;
    }

    /**
     * Getter $date
     * @return {string}
     */
    public get $date(): string {
        return this.date;
    }

    /**
     * Getter $billingDate
     * @return {string}
     */
    public get $billingDate(): string {
        return this.billingDate;
    }

    /**
     * Getter $isRecurring
     * @return {boolean}
     */
    public get $isRecurring(): boolean {
        return this.isRecurring;
    }

    /**
     * Getter $status
     * @return {boolean}
     */
    public get $status(): boolean {
        return this.status;
    }

}
