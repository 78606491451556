import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CardValidationService {

  private visaRegex: RegExp;
  private masterRegex: RegExp;
  private amexRegex: RegExp;
  cardLogoFileName: string;
  validCardLength: boolean;

  private yearOfToday: number;
  private monthOfToday: number;
  private expiryRegex: RegExp;
  invalidExpiryMonth: boolean; // defines if month is between 1-12
  validExpiryDate: boolean;

  constructor() {
    this.visaRegex = new RegExp('^4[0-9]{6,}$');
    this.masterRegex = new RegExp('^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$');
    this.amexRegex = new RegExp('^3[47][0-9]{5,}$');
    this.cardLogoFileName = null;
    this.validCardLength = false;

    const today = new Date();
    this.yearOfToday = +formatDate(today, 'yy', 'en');
    this.monthOfToday = +formatDate(today, 'MM', 'en');
    this.expiryRegex = new RegExp('^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$');
  }

  validateCardType(cardNumber: string): void {
    cardNumber = cardNumber.replace(/ /g, '');

    if (cardNumber.match(this.visaRegex)) {
      this.cardLogoFileName = 'visa.svg';
      this.validCardLength = cardNumber.length > 0 && cardNumber.length < 16 ? true : false;
    } else if (cardNumber.match(this.masterRegex)) {
      this.cardLogoFileName = 'mastercard.svg';
      this.validCardLength = cardNumber.length > 0 && cardNumber.length < 16 ? true : false;
    } else if (cardNumber.match(this.amexRegex)) {
      this.cardLogoFileName = 'american-express.svg';
      this.validCardLength = cardNumber.length > 0 && cardNumber.length < 15 ? true : false;
    } else {
      this.cardLogoFileName = null;

      // check length during key inputs
      this.validCardLength = cardNumber.length > 6 && cardNumber.length <= 16 ? true : false;
      // todo: 1133 2543 5534 4313 appears red until 10th char
      // 2132 1321 1111 1111 red forever
    }
  }

  validateExpDate(expiryDate: string): void {
    const cardExpYear = +expiryDate.substring(expiryDate.length - 2);
    const cardExpMonth = +expiryDate.substring(0, 2);

    this.validExpiryDate = false;
    this.invalidExpiryMonth = cardExpMonth > 12 ? true : false;

    if (expiryDate.match(this.expiryRegex)) {
      if (cardExpYear === this.yearOfToday && cardExpMonth >= this.monthOfToday) {
        this.validExpiryDate = true;
      } else if (cardExpYear > this.yearOfToday) {
        this.validExpiryDate = true;
      } else {
        // set error border if above conditions do not apply
        this.invalidExpiryMonth = true;
      }
    } else {
      // console.log('not matching');
    }
  }

  setCvvErrorBorder(cvv: string): boolean {
    if (cvv) {
      if (this.cardLogoFileName === 'american-express.svg') {
        if (cvv.length < 4) { return false; }
      } else {
        if (cvv.length < 3) { return false; }
      }
    }
    return true;
  }

  setExpiryBorder(expiry: string): boolean {
    if (expiry) {
      if (!this.validExpiryDate) {
        return true;
      }
    }
    return false;
  }

  setCardNumberBorder(cardNumber: string): boolean {
    if (cardNumber) {
      if (!this.cardLogoFileName) {
        return true;
      }
    }
    return false;
  }
}
