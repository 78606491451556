<app-header></app-header>

<!--/ Support /-->
<section class="intro-single section-t8 section-b4 background-mural-01">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-between pb-4">
                    <div class="title-box">
                        <h2 class="title-a">Support</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <p class="mt-1">Our experts can help you answer any SPARAV related questions you may have. If you are
                    asking for technical assistance, be sure to include all your system information related to the
                    problem.
                </p>
            </div>
            <div class="col-md-4">
                <div class="text-md-right text-left">
                    <div class="card-box-ico">
                        <div class="d-inline-flex float-left mr-3"><b>Ready to help you:</b></div>
                        <a href="mailto:help@sparav.com" class="">
                            Email support
                            <i class="fa fa-angle-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card-box-ico">
                    <div class="panel-group" id="accordion">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h5 class="panel-title mb-3" (click)="q1 = !q1">
                                    <a data-toggle="collapse" data-parent="#accordion" [attr.aria-expanded]="!q1"
                                        class="btn-a" aria-controls="collapse1">
                                        <span class="circle-support">Q:</span>
                                        What is cybercrime?
                                        <div class="d-inline-flex float-right">
                                            <i class="fa fa-angle-down"></i>
                                        </div>
                                    </a>
                                </h5>
                            </div>
                            <div id="collapse1" class="panel-collapse collapse in" [ngbCollapse]='q1'>
                                <div class="panel-body card-box-small-color">
                                    <p>Cybercrime has never been as big a threat as it is today. There are more people
                                        than ever connected to the Internet via computers, smartphones and tablets -
                                        most are unaware of the dangers of not updating their operating systems, using
                                        public Wi-Fi, and downloading software and files without verifying the source.
                                        <br><br>
                                        Hackers were the blanket used to describe virus developers in the early part of
                                        the century. But now, coupled with untraceable crypto currencies, organized
                                        cybercrime is big business - whether through aggressive affiliate advertising,
                                        harvesting and selling personal user data, stealing and / or selling user data,
                                        committing identity theft or holding personal files ransom - cybercrime around
                                        the world is very active and here to stay.
                                        <br><br>
                                        The popularity and increasing value of cryptocurrencies led to a major change in
                                        the threat landscape. These currencies change hands almost anonymously, so
                                        tracing the path of Bitcoins or any other cryptocurrency is virtually impossible
                                        - perfect for money acquired through poorly earned profits. Ransomware has
                                        become one of the most malicious cyber crime and type of malware to strike in
                                        the last few years. Computer files are literally held for ransom until a ransom
                                        for cryptocurrency is paid.</p>
                                </div>
                            </div>
                        </div>
                        <hr class="mt-4 mb-4">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h5 class="panel-title mb-3" (click)="q2 = !q2">
                                    <a data-toggle="collapse" data-parent="#accordion" [attr.aria-expanded]="!q2"
                                        class="btn-a" aria-controls="collapse2">
                                        <span class="circle-support">Q:</span> Renewal of subscription?
                                        <div class="d-inline-flex float-right"><i class="fa fa-angle-down"></i></div>
                                    </a>
                                </h5>
                            </div>
                            <div id="collapse2" class="panel-collapse collapse" [ngbCollapse]='q2'>
                                <div class="panel-body card-box-small-color">
                                    <p>To avoid interruption of your services, we will automatically attempt to process
                                        the renewal payment of your SPARAV antivirus protection on the date shown in
                                        your account under the billing section. If this is not the case for you, we will
                                        turn it off, simply send us an email mail.
                                        <br><br>
                                        Want to know how much your next payment should be? Easy! Everything you need to
                                        know about the renewal of your service is available in your billing section,
                                        here you can also find out the billing setup you signed up for.
                                        <br><br>
                                        Your renewal price will differ from your initial price. This is because when you
                                        signed up for Total AV, you received an introductory discount rate, which on its
                                        anniversary is renewed at the normal rate.
                                        <br><br>
                                        If you think you have been charged too much then please contact us at
                                        invoice@sparav.com and we will correct the situation.
                                        <br><br>
                                        If your account has been renewed, please consider the following points before
                                        contacting us:
                                        <br><br>
                                        Any rebate received on your first payment is only valid for the first term,
                                        renewal rates are without initial discounts.
                                        <br><br>
                                        By default, accounts are set to auto-renew to prevent disruption to your
                                        service. This can be turned off at your request.</p>
                                </div>
                            </div>
                        </div>
                        <hr class="mt-4 mb-4">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h5 class="panel-title mb-3" (click)="q3 = !q3">
                                    <a data-toggle="collapse" data-parent="#accordion" [attr.aria-expanded]="!q3"
                                        class="btn-a" aria-controls="collapse3">
                                        <span class="circle-support">Q:</span> I have a paid account, but I'm still told
                                        to upgrade, why?
                                        <div class="d-inline-flex float-right"><i class="fa fa-angle-down"></i></div>
                                    </a>
                                </h5>
                            </div>
                            <div id="collapse3" class="panel-collapse collapse" [ngbCollapse]='q3'>
                                <div class="panel-body card-box-small-color">
                                    <p>In some cases, orders for further verification are held back. If your order is
                                        placed, you will not be given full access to the program until your order is
                                        fully completed. To expedite the process, you can email invoice@sparav.com. It
                                        can take up to 6 hours for an order to complete and 24 hours for the order to be
                                        paid with PayPal. Your patience is much appreciated.</p>
                                </div>
                            </div>
                        </div>
                        <hr class="mt-4 mb-4">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h5 class="panel-title mb-3" (click)="q4= !q4">
                                    <a data-toggle="collapse" data-parent="#accordion" [attr.aria-expanded]="!q4"
                                        class="btn-a" aria-controls="collapse4">
                                        <span class="circle-support">Q:</span> Can I get a refund?
                                        <div class="d-inline-flex float-right"><i class="fa fa-angle-down"></i></div>
                                    </a>
                                </h5>
                            </div>
                            <div id="collapse4" class="panel-collapse collapse" [ngbCollapse]='q4'>
                                <div class="panel-body card-box-small-color">
                                    <p>If you have any questions about a charge or would like to request a refund,
                                        please contact our billing team.
                                        <br><br>
                                        We are eager to help resolve any issues you may have and to find out what the
                                        problem has been exactly with either your account or our products.
                                        <br><br>
                                        There are two ways to contact us to receive a refund:
                                        <br><br>
                                        Email: invoice@sparav.com</p>
                                </div>
                            </div>
                        </div>
                        <hr class="mt-4 mb-4">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h5 class="panel-title mb-3" (click)="q5 = !q5">
                                    <a data-toggle="collapse" data-parent="#accordion" [attr.aria-expanded]="!q5"
                                        class="btn-a" aria-controls="collapse5">
                                        <span class="circle-support">Q:</span> What to do if you have become a victim?
                                        <div class="d-inline-flex float-right"><i class="fa fa-angle-down"></i></div>
                                    </a>
                                </h5>
                            </div>
                            <div id="collapse5" class="panel-collapse collapse" [ngbCollapse]='q5'>
                                <div class="panel-body card-box-small-color">
                                    <p>If you believe you have become a victim of cybercrime then contact the local
                                        police. Your report can help authorities in their investigation or help prevent
                                        criminals from taking advantage of other people in the future.
                                        <br><br>
                                        If you believe your identity has been stolen:
                                        <br><br>
                                        – Contact companies, especially all financial institutions where you know fraud
                                        has occurred.
                                        <br>
                                        – Report identity theft to the local police.
                                        <br>
                                        – Follow your credit reports closely.
                                        <br>
                                        – Change password in your email account and any other logins where you know the
                                        scam has occurred.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--/ Support End /-->

<app-footer></app-footer>