export class PaymentModel {

    // tslint:disable:variable-name
    private ipAddress: string;
    private billingAddress1: string;
    private billingZip: string;
    private billingCity: string;
    private billingState: string;
    private billingCountry: string;
    private phone: string;
    private email: string;
    private creditCardNumber: string;
    private expirationDate: string;
    private CVV: string;

    constructor(
        $ipAddress: string,
        $billingAddress1: string,
        $billingZip: string,
        $billingCity: string,
        $billingState: string,
        $billingCountry: string,
        $phone: string,
        $email: string,
        $creditCardNumber: string,
        $expirationDate: string,
        $CVV: string
    ) {
        this.ipAddress = $ipAddress;
        this.billingAddress1 = $billingAddress1;
        this.billingZip = $billingZip;
        this.billingCity = $billingCity;
        this.billingState = $billingState;
        this.billingCountry = $billingCountry;
        this.phone = $phone;
        this.email = $email;
        this.creditCardNumber = $creditCardNumber;
        this.expirationDate = $expirationDate;
        this.CVV = $CVV;
    }


    /**
     * Getter $ipAddress
     * @return {string}
     */
    public get $ipAddress(): string {
        return this.ipAddress;
    }

    /**
     * Getter $billingAddress1
     * @return {string}
     */
    public get $billingAddress1(): string {
        return this.billingAddress1;
    }

    /**
     * Getter $billingZip
     * @return {string}
     */
    public get $billingZip(): string {
        return this.billingZip;
    }

    /**
     * Getter $billingCity
     * @return {string}
     */
    public get $billingCity(): string {
        return this.billingCity;
    }

    /**
     * Getter $billingState
     * @return {string}
     */
    public get $billingState(): string {
        return this.billingState;
    }

    /**
     * Getter $billingCountry
     * @return {string}
     */
    public get $billingCountry(): string {
        return this.billingCountry;
    }

    /**
     * Getter $phone
     * @return {string}
     */
    public get $phone(): string {
        return this.phone;
    }

    /**
     * Getter $email
     * @return {string}
     */
    public get $email(): string {
        return this.email;
    }

    /**
     * Getter $creditCardNumber
     * @return {string}
     */
    public get $creditCardNumber(): string {
        return this.creditCardNumber;
    }

    /**
     * Getter $expirationDate
     * @return {string}
     */
    public get $expirationDate(): string {
        return this.expirationDate;
    }

    /**
     * Getter $CVV
     * @return {string}
     */
    public get $CVV(): string {
        return this.CVV;
    }

}
