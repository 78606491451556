import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { interval } from 'rxjs';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  private minutes: number;

  constructor(private router: Router, private loginService: LoginService) {
    this.minutes = 10;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (localStorage.getItem('loginToken')) {

      // check token every 10 min
      const checker = interval(this.minutes * 60 * 1000)
        .subscribe(data => {
          this.loginService.validateToken(localStorage.getItem('loginToken')).then(res => {
            switch (res.response_code) {
              case 200:
                // console.log('still logged in');
                break;
              case 401:
                this.loginService.logout();
                checker.unsubscribe();
                break;
              default:
                this.loginService.logout();
                checker.unsubscribe();
                break;
            }
            return;
          });

          // console.log(data);
        });

      return true;
    }

    this.router.navigate(['']);
    return false;
  }

}
