import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { IpService } from './shared/services/ip.service';
import { CustomerService } from './shared/services/customer.service';
import { LoginService } from './shared/services/login.service';
import { SubscriptionService } from './shared/services/subscription.service';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'sparav-website-ui-customer';
  private subscription: Subscription;
  loading: boolean;

  constructor(
    private router: Router,
    private ipService: IpService,
    private customerService: CustomerService,
    private loginService: LoginService,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit(): void {
    // listen to browser refresh
    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !this.router.navigated;
      }
    });

    this.loading = true;

    this.loadIpAddress();

    this.loadCustomerOnRefresh();
  }

  /**
   * Load customer data on browser refresh
   */
  loadCustomerOnRefresh(): void {
    if (localStorage.getItem('loginToken')) {
      this.customerService.fetchOrdersInfo().subscribe(ordersArray => {
        // cache orders

        this.customerService.fetchCustomerInfo().subscribe(customerModel => {
          // cache customer

        });

      }, err => {
        console.error('handling error...');
      }, () => {
        // on completed fallback
        this.loginService.currentLoadingScreen.subscribe(loading => {
          this.loading = loading;
        });
      });
    } else {

      // reset loading screen when auth guard navigates back to login
      this.loginService.currentLoadingScreen.subscribe(loading => {
        this.loading = loading;
      });

    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * get client info on page start up
   */
  private loadIpAddress(): void {
    this.ipService.getIPAddress().subscribe((res: any) => {
      // console.log(res);
      localStorage.setItem('phoneCode', res.country.prefix);
      localStorage.setItem('ipaddress', res.ip);
      localStorage.setItem('countryName', res.country.country);
      localStorage.setItem('countryCode', res.country.country_code);
    });
  }

}
